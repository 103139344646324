export const checkRole = (
  role: string,
  currRole: string,
  isRoster: boolean,
  isCurrRoster: boolean,
) => {
  let path = "";
  let rosterPath = "";
  if (role === "Owner") {
    path = "team/member/assign/owner";
  } else if (role === "Captain") {
    path = "team/member/assign/captain";
  } else if (role == "Coach") {
    path = "team/member/assign/coach";
  } else if (role == "Member") {
    if (currRole === "Captain") {
      path = "team/member/revoke/captain";
    } else if (currRole == "Coach") {
      path = "team/member/revoke/coach";
    }
  } else if (isRoster && !isCurrRoster) {
    rosterPath = "team/member/roster/set";
  } else if (!isRoster && isCurrRoster) {
    rosterPath = "team/member/roster/take-out";
  }

  return path?.length ? path : rosterPath;
};
