import { useEffect, useState } from "react";
import News from "../../../../components/News";
import { getMyNews } from "../../../../services/user";
import styles from "./style.module.scss";
import Loader from "../../../../components/Loader";

const MyNews = () => {
  const [news, setNews] = useState([]);
  const [topNews, setTopNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getMyNews()
      .then((res: any) => {
        setTopNews(res?.data?.news?.splice(0, 2));
        setNews(res?.data?.news);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <div className={styles.loader}>
      <Loader text="Retrieving the most recent news articles for your games..." />
    </div>
  ) : topNews?.length || news?.length ? (
    <div className={styles.news_page_wrapper}>
      <div className={styles.top_news_wrapper}>
        {!!topNews?.length &&
          topNews?.map((e: any, i: number) => (
            <News key={`${e?.url}${i}`} news={e} isTopNews={true} />
          ))}
      </div>

      <div className={styles.news_wrapper}>
        {!!news?.length &&
          news?.map((e: any, i: number) => (
            <News key={`${e?.url}${i}`} news={e} />
          ))}
      </div>
    </div>
  ) : (
    <p className={styles.no_news}>There are no news yet</p>
  );
};

export default MyNews;
