import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LastMatches from "../../components/LastMatches";
import LOLStatsCell from "../../components/LOLStatsCell";
import { useGetUserStatsMutation } from "../../../../../../services/userStats";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { useImage } from "../../../../../../services/getImages";

const LOLStatistics = () => {
  const getStats = useGetUserStatsMutation();
  const { id, matchId, userId } = useParams();
  const [match, setMatch] = useState<any>(null);
  const [stats, setStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastMatch, setLastMatch] = useState<number>(0);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [overallStats, setOverallStats] = useState<any>(null);
  const otherUser = useSelector((state: any) => state.otherUser);
  const [players, setPlayers] = useState({ reds: [], greens: [] });
  const { data: imageST } = useImage(
    `${overallStats?.tier.toLowerCase()}_${overallStats?.rank.toLowerCase()}`,
  );

  useEffect(() => {
    if (id && userId) {
      getStats.mutate(
        {
          game_id: id,
          user_id: userId,
          type: "last-matches",
        },
        {
          onSuccess: (res: any) => {
            setIsLoading(false);
            setOverallStats(
              res.data?.data?.splice(res.data?.data?.length - 1, 1)[0][0],
            );
            setStats(res.data);
            setLastMatch(res.data?.data[0]?.info?.gameEndTimestamp);

            res.data?.data[0]?.info?.participants?.forEach((e: any) => {
              if (e?.puuid === res.data?.data[0]?.user_puuid) {
                setCurrentUser(e);
              }
            });
          },
          onError: () => {
            setIsLoading(false);
          },
        },
      );
    }
  }, [id, userId]);

  useEffect(() => {
    if (stats && matchId) {
      setMatch(
        matchId === "1"
          ? stats?.data[0]
          : stats?.data?.find(
              (data: any) => matchId === data?.metadata?.matchId,
            ),
      );
      setPlayers({ reds: [], greens: [] });
    }
  }, [matchId, stats]);

  useEffect(() => {
    if (match) {
      match?.info?.participants?.forEach((player: any) => {
        if (player?.teamId === 100) {
          setPlayers((prev: any) => ({
            ...prev,
            reds: [...prev.reds, player],
          }));
        }
        if (player?.teamId === 200) {
          setPlayers((prev: any) => ({
            ...prev,
            greens: [...prev.greens, player],
          }));
        }
      });
    }
  }, [match]);

  const dateFormat = (unix_timestamp: number) => {
    const date = new Date(unix_timestamp);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}${
      day === 1 || day === 21
        ? "st"
        : day === 2 || day === 22
          ? "nd"
          : day === 3 || day === 23
            ? "rd"
            : "th"
    }, ${year}`;
  };

  return isLoading ? (
    <p className={styles.loading}>Loading...</p>
  ) : !stats ? (
    <p className={styles.loading}>Cannot retrieve statistics.</p>
  ) : !lastMatch ? (
    <p className={styles.loading}>
      <span style={{ color: "#4655f4" }}>{otherUser.username}</span> does not
      have enough data logged in League of Legends to display their statistics.
    </p>
  ) : stats ? (
    <div className={styles.lol_stats_wrapper}>
      <div>
        <div className={styles.match_details_wrapper}>
          <div className={styles.match_details_header}>
            <h3 className={styles.stats_page_title}>Overall Stats</h3>
          </div>

          <div className={styles.overall_stats_body}>
            <div className={`${styles.stats_container} ${styles.account_info}`}>
              <div className={styles.user_info_wrapper}>
                <p
                  className={styles.hero_level}
                  style={{
                    padding: `${
                      currentUser?.summonerLevel < 10
                        ? "2px 6px"
                        : currentUser?.summonerLevel < 100
                          ? "4px"
                          : currentUser?.summonerLevel < 1000
                            ? "8px 4px"
                            : "12px 4px"
                    }`,
                  }}
                >
                  {currentUser?.summonerLevel || 0}
                </p>
                <div className={styles.account_info_wrapper}>
                  <p className={styles.username}>{currentUser?.summonerName}</p>

                  <p className={styles.user_lp}>
                    LP: {overallStats?.leaguePoints}
                  </p>
                </div>
              </div>

              <div className={styles.image_wrapper}>
                {overallStats?.rank && (
                  <img loading="lazy" className={styles.image} src={imageST} />
                )}
              </div>
            </div>

            <div
              className={`${styles.stats_container} ${styles.wins_loses_wrapper}`}
            >
              <div className={styles.wins_loses_content}>
                <p className={styles.overall_stats_secondary_text}>Wins</p>
                <p className={styles.wins_count}>{overallStats?.wins || 0}</p>
              </div>

              <div className={styles.wins_loses_content}>
                <p className={styles.overall_stats_secondary_text}>Losses</p>
                <p className={styles.loses_count}>
                  {overallStats?.losses || 0}
                </p>
              </div>
            </div>

            <div
              className={`${styles.stats_container} ${styles.matches_time_wraper}`}
            >
              <p className={styles.overall_stats_secondary_text}>Last Match</p>
              <p className={styles.matches_time}>
                {lastMatch ? dateFormat(lastMatch) : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.dota_stats_body}>
        <div className={styles.body_center}>
          <div className={styles.match_details_wrapper}>
            <div className={styles.match_details_header}>
              <h3 className={styles.stats_page_title}>Match Details</h3>
            </div>

            <div className={styles.match_details_body}>
              <div className={styles.match_details_timeline}>
                <div className={styles.duration_wrapper}>
                  <p className={styles.secondary_text}>Duration</p>
                  <hr className={styles.vertical_line}></hr>
                  <p className={styles.primary_text}>
                    {match?.info?.gameDuration
                      ? `${(match?.info?.gameDuration / 60).toFixed(
                          2,
                        )}`.replaceAll(".", ":")
                      : "-"}
                  </p>
                </div>

                <div className={styles.duration_wrapper}>
                  <p className={styles.secondary_text}>Game mode</p>
                  <hr className={styles.vertical_line}></hr>
                  <p className={styles.primary_text}>{match?.info?.gameMode}</p>
                </div>

                <div className={styles.duration_wrapper}>
                  <p className={styles.secondary_text}>Map</p>
                  <hr className={styles.vertical_line}></hr>
                  <p className={styles.primary_text}>
                    {match?.info?.mapId === 11
                      ? "Summoner's Rift"
                      : match?.info?.mapId === 12
                        ? "Howling Abyss"
                        : "-"}
                  </p>
                </div>
              </div>

              <div className={styles.match_details_score_wrapper}>
                <div className={styles.match_details_dire}>
                  <p className={styles.primary_text}>Red Side</p>
                  <p className={styles.primary_text_large}>
                    {match?.info?.teams[0]?.objectives?.champion?.kills || 0}
                  </p>
                  {match?.info?.teams[0]?.teamId === 100 &&
                  match?.info?.teams[0]?.win ? (
                    <span className={`${styles.won_lost} ${styles.won_red}`}>
                      WON
                    </span>
                  ) : (
                    <span className={`${styles.won_lost} ${styles.lost_red}`}>
                      LOST
                    </span>
                  )}
                </div>

                <div className={styles.match_details_radiant}>
                  {match?.info?.teams[1]?.teamId === 200 &&
                  match?.info?.teams[1]?.win ? (
                    <span className={`${styles.won_lost} ${styles.won_green}`}>
                      WON
                    </span>
                  ) : (
                    <span className={`${styles.won_lost} ${styles.lost_green}`}>
                      LOST
                    </span>
                  )}
                  <p className={styles.primary_text}>Green Side</p>
                  <p className={styles.primary_text_large}>
                    {match?.info?.teams[1]?.objectives?.champion?.kills || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h3 className={styles.stats_page_title}>Red Side</h3>

          {players.reds.map((e: any) => (
            <LOLStatsCell
              player={e}
              key={e?.puuid}
              isYou={match?.user_puuid === e?.puuid}
            />
          ))}

          <h3 className={styles.stats_page_title}>Green Side</h3>

          {players.greens.map((e: any) => (
            <LOLStatsCell
              player={e}
              key={e?.puuid}
              isYou={match?.user_puuid === e?.puuid}
            />
          ))}
        </div>

        <div className={styles.stats_sidebar}>
          <LastMatches
            isLOL={true}
            matches={stats?.data}
            player_id={stats?.user_id}
          />
        </div>
      </div>
    </div>
  ) : (
    <p className={styles.loading}>
      The user&#39;s statistics are currently unavailable. Please try again
      later
    </p>
  );
};

export default LOLStatistics;
