export const utcToLocalTime = (date: string) => {
  const utcDate: any = new Date(date + " UTC");

  const currentDate: any = new Date();

  const timeDifference = currentDate - utcDate;

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30.44 * day;
  const year = 365.25 * day;

  const yearsAgo = Math.floor(timeDifference / year);
  const monthsAgo = Math.floor((timeDifference % year) / month);
  const daysAgo = Math.floor((timeDifference % year) / day);
  const hoursAgo = Math.floor((timeDifference % day) / hour);
  const minutesAgo = Math.floor((timeDifference % hour) / minute);

  let timeAgoMessage = "";

  if (yearsAgo > 0) {
    timeAgoMessage =
      yearsAgo + " " + (yearsAgo === 1 ? "year" : "years") + " ago";
  } else if (monthsAgo > 0) {
    timeAgoMessage =
      monthsAgo + " " + (monthsAgo === 1 ? "month" : "months") + " ago";
  } else if (daysAgo > 0) {
    timeAgoMessage = daysAgo + " " + (daysAgo === 1 ? "day" : "days") + " ago";
  } else if (hoursAgo > 0) {
    timeAgoMessage =
      hoursAgo + " " + (hoursAgo === 1 ? "hour" : "hours") + " ago";
  } else if (minutesAgo > 0) {
    timeAgoMessage =
      minutesAgo + " " + (minutesAgo === 1 ? "minute" : "minutes") + " ago";
  } else {
    timeAgoMessage = "Just now";
  }
  return timeAgoMessage;
};
