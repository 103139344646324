import { Stack, Typography } from "@mui/material";

interface BadgeProps {
  label: string | number;
  color?: string;
  backgroundColor?: string;
  className?: string;
}

export default function Badge({ label }: BadgeProps) {
  return (
    <Stack
      ml={1}
      alignItems="center"
      width={28}
      height={28}
      justifyContent="center"
      borderRadius="100%"
      sx={{
        backgroundColor: "#1D1D23",
      }}
    >
      <Typography fontSize="12px" fontWeight={600}>
        {label}
      </Typography>
    </Stack>
  );
}
