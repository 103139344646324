import React, { useEffect, useRef, useState } from "react";

import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { IInput } from "../types";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import styles from "./style.module.scss";

const Input: React.FC<IInput> = ({
  inputClassName,
  errorMessage,
  placeholder,
  isComment,
  className,
  onChange,
  isError,
  value,
  type,
  isDarkColored,
  onBlur,
  onFocus,
  inHash,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const isPassword = type === "password";
  const isSearch = type === "search";
  const [isSelect, setIsSelect] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setIsSelect(false);
    }
  };

  useEffect(() => {
    if (onFocus) setIsSelect(false);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${styles.input_wrapper} ${className}`}
      style={{
        width: "100%",
      }}
    >
      <div
        className={`${styles.icons_input} ${
          (isPassword || isComment) && styles.left
        }`}
      >
        {inHash && isSelect && <p className={styles.fake_hash}>#</p>}

        {isSearch && <SearchIcon className={styles.search_icon} />}

        <input
          ref={inputRef}
          value={value}
          onChange={onChange}
          autoComplete="new-password"
          type={!isHidden || isSearch ? "text" : type}
          style={{
            backgroundColor: isDarkColored ? "#1D1D23" : "",
          }}
          className={`
						${styles.input} 
						${inputClassName}
						${isError && styles.error_input} 
						${isSearch && styles.search_input}
            ${inHash && isSelect && styles.input_hash}
					`}
          onBlur={onBlur}
          onFocus={() => setIsSelect(true)}
        />

        {isPassword && (
          <button
            type="button"
            className={styles.input_button}
            onClick={() => setIsHidden((prev) => !prev)}
          >
            {isHidden ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <RemoveRedEyeOutlinedIcon />
            )}
          </button>
        )}

        {isComment && (
          <button
            type="submit"
            className={`${styles.input_button} ${styles.comment_button}`}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <ArrowUpwardRoundedIcon />
          </button>
        )}

        <span
          className={`${styles.input_label} ${
            isSearch ? styles.left_icon_label : styles.right_icon_label
          } ${
            value.length && isSearch
              ? styles.focused_left
              : value.length && !isSearch
                ? styles.focused_right
                : ""
          }`}
        >
          {placeholder}
        </span>
      </div>

      {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default Input;
