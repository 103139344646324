import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import NoStats from "../../../../components/NoStats";
import GameCard from "../../../../components/GameCard";
import GameTableRow from "../../../../components/TableRow/GameTableRow";
import { useMyGamesQuery } from "../../../../services/myGames";
import styles from "./myGameContainer.module.scss";
import Button from "../../../../components/Button";
import { useAddUserSteamGamesMutation } from "../../../../services/userGames";
import Loader from "../../../../components/Loader";

const MyGames = () => {
  const user = useSelector((state: any) => state.user);
  const addSteamGames = useAddUserSteamGamesMutation();
  const [isSteamPrivate, setIsSteamPrivate] = useState(false);

  const { data: games, isLoading } = useMyGamesQuery({
    params: {
      id: user?.id,
      steam_id: user?.connected_platforms?.steam?.steam_id,
    },
    querySettings: {
      enabled: !!user?.id,
    },
  });

  useEffect(() => {
    if (
      games?.data?.core_games?.length &&
      user.connected_platforms?.steam?.steam_id &&
      !games?.data?.core_games?.some(
        (game: any) =>
          game?.game_id === process.env.REACT_APP_DOTA2_ID ||
          game?.game_id === process.env.REACT_APP_CSGO_ID,
      )
    ) {
      setIsSteamPrivate(true);
    } else if (
      user?.connected_platforms?.steam?.steam_id &&
      !games?.data?.core_games?.length
    ) {
      setIsSteamPrivate(true);
    } else {
      setIsSteamPrivate(false);
    }
  }, [games]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader text="Loading My Games..." />
        </div>
      ) : (
        <>
          {games?.data?.core_games?.length ? (
            <>
              <div className={styles.topContainer}>
                {games?.data?.core_games?.map(
                  (coreGame: {
                    id: string;
                    game_id: string;
                    game_logo: string;
                    game_title: string;
                    game_data: {
                      playtime_2weeks: number;
                      playtime_forever: number;
                    };
                  }) => (
                    <Link
                      key={coreGame?.id}
                      to={`/app/dashboard/game/${coreGame?.game_id}`}
                    >
                      <GameCard
                        game_title={coreGame?.game_title}
                        bgImg={coreGame?.game_logo || ""}
                        playtime_2weeks={
                          coreGame?.game_data?.playtime_2weeks || 0
                        }
                        playtime_forever={
                          coreGame?.game_data?.playtime_forever || 0
                        }
                      />
                    </Link>
                  ),
                )}
                {isSteamPrivate && user?.connected_platforms?.riot?.id && (
                  <div className={styles.no_stats_wrapper}>
                    <div className={styles.empty_box}></div>
                    <div className={styles.empty_box}></div>
                  </div>
                )}
              </div>

              <div className={styles.centerContainer}>
                <Typography color="grey">
                  {games?.data?.other_games?.length &&
                    `${games?.data?.other_games?.length} Games`}
                </Typography>
              </div>
              <div className={styles.bottomContainer}>
                {games?.data?.other_games?.map(
                  (
                    otherGame: {
                      name: string;
                      appid: string;
                      img_icon_url: string;
                      playtime_2weeks: number;
                      playtime_forever: number;
                    },
                    i: number,
                  ) => (
                    <GameTableRow
                      rowNumber={i + 1}
                      key={otherGame?.appid}
                      name={otherGame?.name}
                      totalTime={otherGame?.playtime_forever / 60}
                      lastTwoWeeks={otherGame?.playtime_2weeks / 60}
                      img={`http://media.steampowered.com/steamcommunity/public/images/apps/${otherGame?.appid}/${otherGame?.img_icon_url}.jpg`}
                    />
                  ),
                )}
              </div>

              {isSteamPrivate && (
                <div className={styles.private_steam_info_wrapper}>
                  <div className={styles.disclaimer_wrapper}>
                    <p className={styles.disclaimer_title_primary}>
                      Unable to retrieve statistics
                    </p>
                    <p className={styles.disclaimer_text}>
                      Your steam profile is set to private. Please follow the
                      instructions{" "}
                      <a
                        href="https://help.steampowered.com/en/faqs/view/588C-C67D-0251-C276"
                        target="blank"
                        className={styles.here_link}
                      >
                        here
                      </a>{" "}
                      to set your profile to Public and display your games.
                    </p>
                  </div>
                  <Button
                    text="Reload"
                    className={styles.reload_button}
                    onClick={() => {
                      addSteamGames.mutate(
                        {
                          user_id: user.id,
                          steam_id: user?.connected_platforms?.steam?.steam_id,
                        },
                        { onSuccess: () => window.location.reload() },
                      );
                    }}
                  />
                </div>
              )}
              <div className={styles.disclaimer_wrapper}>
                <p className={styles.disclaimer_title}>Disclaimer</p>
                <p className={styles.disclaimer_text}>
                  GameDrive is not endorsed by Riot Games and does not reflect
                  the views or opinions of Riot Games or anyone officially
                  involved in producing or managing Riot Games properties. Riot
                  Games and all associated properties are trademarks or
                  registered trademarks of Riot Games, Inc.
                </p>
              </div>
            </>
          ) : isSteamPrivate &&
            user?.connected_platforms?.steam?.steam_id &&
            !user?.connected_platforms?.riot?.id ? (
            <NoStats isConnected={true} />
          ) : (
            <NoStats />
          )}
        </>
      )}
    </div>
  );
};

export default MyGames;
