import {
  createStore,
  applyMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { user } from "./userReducer";
import { auth } from "./authReducer";
import { post } from "./postReducer";
import { otherUser } from "./otherUserReducer";
import { visibility } from "./visibilityReducer";
import { showSidebar } from "./showSidebarReducer";
import { notification } from "./notificationReducer";

const rootReducer = combineReducers({
  auth,
  post,
  user,
  otherUser,
  visibility,
  showSidebar,
  notification,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
