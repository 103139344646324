import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Input from "../../components/Input";
import Radio from "../../components/Radio";
import Button from "../../components/Button";
import Banner from "../../components/Banner";
import { Riot } from "../../assets/icons/Riot";
import { Steam } from "../../assets/icons/Steam";
import Notification from "./components/Notification";
import { useDispatch, useSelector } from "react-redux";
import PasswordChange from "./components/PasswordChange";
import ProfilePhoto from "../../components/ProfilePhoto";
import { isUsernameCorrect } from "../auth/utils/helper";
import { updateUserAction } from "../../store/userReducer";
import { setBinaryImage } from "../../helpers/setBinaryImage";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  useUpdateUserMutation,
  useUpdateUsernameMutation,
} from "../../services/user";
import styles from "./style.module.scss";
import { useImage } from "../../services/getImages";

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const updateUser = useUpdateUserMutation();
  const [username, setUsername] = useState("");
  const [showInput, setShowInput] = useState(false);
  const updateUsername = useUpdateUsernameMutation();
  const [visibility, setVisibility] = useState(false);
  const user = useSelector((state: any) => state.user);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [imagesIds, setImagesIds] = useState({ banner: "", profilePhoto: "" });
  const [images, setImages] = useState<{ banner: any; profilePhoto: any }>({
    banner: null,
    profilePhoto: null,
  });
  const [notification, setNotification] = useState<{
    date?: string;
    show: boolean;
    type: "info" | "success" | "error";
  }>({ type: "info", show: false, date: "" });
  const [formError, setFormError] = useState({
    usernameError: {
      text: "",
      isError: false,
    },
  });

  const { data: userBanner } = useImage(user.banner);
  const { data: userAvatar } = useImage(user.avatar);

  useEffect(() => {
    setBtnDisabled(
      (username !== user.username ||
        visibility !== user.is_private ||
        imagesIds.banner !== user.banner ||
        imagesIds.profilePhoto !== user.avatar) &&
        isUsernameCorrect(username),
    );
  }, [username, visibility, imagesIds.banner, imagesIds.profilePhoto]);

  useEffect(() => {
    setUsername(user.username || "");

    setVisibility(user.is_private);

    setImagesIds({ banner: user.banner, profilePhoto: user.avatar });
  }, [user.username, user.is_private, user.avatar, user.banner]);

  const handleBannerChange = useCallback(async (banner: any) => {
    setImages((prev: any) => ({
      ...prev,
      banner: URL.createObjectURL(banner[0]),
    }));
    const bannerId = await setBinaryImage(banner[0]);

    setImagesIds((prev: any) => ({ ...prev, banner: bannerId }));
  }, []);

  const handleProfilePhotoChange = useCallback(async (profilePhoto: any) => {
    setImages((prev: any) => ({
      ...prev,
      profilePhoto: URL.createObjectURL(profilePhoto[0]),
    }));
    const profilePhotoId = await setBinaryImage(profilePhoto[0]);

    setImagesIds((prev: any) => ({ ...prev, profilePhoto: profilePhotoId }));
  }, []);

  const handleSave = () => {
    setBtnDisabled(false);
    if (username && username !== user.username) {
      updateUsername.mutate(
        {
          id: user.id,
          username,
        },
        {
          onSuccess: (res: any) => {
            dispatch(updateUserAction(res?.data));
            setNotification({ type: "success", show: true });
          },
          onError: (err: any) => {
            if (err.response?.data?.validations[0]?.field === "0") {
              toast.error(
                "Error: " + err.response?.data?.validations[0]?.error,
              );
            } else if (err.response?.data?.validations[0]?.field) {
              setUsername(user.username);
              setNotification({
                type: "error",
                show: true,
                date: err.response?.data?.validations[0]?.field,
              });
            }
          },
        },
      );
    }

    if (
      visibility !== user.is_private ||
      imagesIds.banner !== user.banner ||
      imagesIds.profilePhoto !== user.avatar
    ) {
      updateUser.mutate(
        {
          ...user,
          is_private: visibility,
          banner: imagesIds.banner,
          avatar: imagesIds.profilePhoto,
        },
        {
          onSuccess: (res: any) => {
            dispatch(updateUserAction(res?.data));
            setNotification({ type: "success", show: true });
          },
        },
      );
    }
  };

  return (
    <div className={styles.profile_edit}>
      <PasswordChange
        open={open}
        close={() => setOpen(false)}
        onSuccess={() => setNotification({ type: "success", show: true })}
      />

      <div className={styles.container}>
        <h1 className={styles.heading}>Edit profile</h1>

        <div>
          <div className={styles.container_inner}>
            <Banner
              height="77px"
              isEditMode={true}
              img={images.banner || (user.banner ? userBanner : "")}
              className={styles.banner}
              onDrop={handleBannerChange}
            />

            <div className={styles.account_info_wrapper}>
              <ProfilePhoto
                size="72px"
                rounded={true}
                isEditMode={true}
                img={images.profilePhoto || (user.avatar ? userAvatar : "")}
                className={styles.profile_photo}
                onDrop={handleProfilePhotoChange}
              />

              <div className={styles.profile_text}>
                <h3 className={styles.username}>{user.username}</h3>

                <p className={styles.secondary_heading}>Connected Platforms</p>

                <div className={styles.platforms_icons_wrapper}>
                  {!user.connected_platforms?.steam?.steam_id &&
                    !user.connected_platforms?.riot?.id && (
                      <p className={styles.no_connections}>
                        No connections yet
                      </p>
                    )}

                  {user.connected_platforms?.steam?.steam_id && <Steam />}

                  {user.connected_platforms?.riot?.id && <Riot />}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.secondary_select}>
            <p className={styles.secondary_heading}>Profile Visibility</p>

            <div className={styles.radios_container}>
              <Radio
                value=""
                label="Public"
                checked={!visibility}
                name="profileVisibility"
                onClick={(e: any) => setVisibility(!!e)}
              />

              <Radio
                value="t"
                label="Private"
                checked={!!visibility}
                name="profileVisibility"
                onClick={(e: any) => setVisibility(!!e)}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputs_wrapper}>
          <div className={styles.change_username_wrapper}>
            <button
              className={styles.change_username}
              onClick={() =>
                setShowInput((prev: boolean) => {
                  if (!prev) {
                    setNotification({ show: true, type: "info" });
                  } else {
                    setNotification({ show: false, type: "info" });
                  }
                  return !prev;
                })
              }
            >
              Create New Username{" "}
              {showInput ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </button>
            {showInput && (
              <Input
                value={username}
                placeholder="Username"
                isError={formError.usernameError.isError}
                errorMessage={formError.usernameError.text}
                onBlur={() => isUsernameCorrect(username, setFormError)}
                onChange={(e: any) => {
                  setFormError({
                    usernameError: {
                      text: "",
                      isError: false,
                    },
                  });
                  setUsername(e.target.value);
                }}
              />
            )}
          </div>

          <button
            onClick={() => setOpen(true)}
            className={styles.change_password}
          >
            Change Password
          </button>
        </div>

        <Button
          text="Save"
          onClick={handleSave}
          disabled={!btnDisabled}
          className={styles.save_button}
        />
      </div>

      {notification.show && (
        <Notification
          date={notification.date}
          state={notification.type}
          close={() => setNotification((prev) => ({ ...prev, show: false }))}
        />
      )}
    </div>
  );
};

export default ProfileEdit;
