import React from "react";
import Button from "../Button";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { useAddUserSteamGamesMutation } from "../../services/userGames";

const NoStats: React.FC<{ isConnected?: boolean; isStats?: boolean }> = ({
  isConnected,
  isStats,
}) => {
  const user = useSelector((state: any) => state.user);
  const addSteamGames = useAddUserSteamGamesMutation();

  return (
    <div className={styles.no_stats_wrapper}>
      <div className={styles.empty_box}></div>
      <div className={styles.empty_box}></div>
      <div className={styles.empty_box}></div>

      <div className={styles.no_stats_text_wrapper}>
        {isConnected && (
          <p className={styles.no_stats_title}>Unable to get statistics</p>
        )}
        {isConnected ? (
          <p className={styles.no_stats_text}>
            Your steam profile is set to private. Please follow the instructions{" "}
            <a
              href="https://help.steampowered.com/en/faqs/view/588C-C67D-0251-C276"
              target="blank"
              className={styles.here_link}
            >
              here
            </a>{" "}
            to set your profile to Public and display your games.
          </p>
        ) : (
          <p className={styles.no_stats_text}>
            Please connect Game Platforms to your account in order to see your{" "}
            {isStats ? "game statistics" : "games"}
          </p>
        )}
        {isConnected && (
          <Button
            text="Reload"
            className={styles.no_stats_button}
            onClick={() => {
              addSteamGames.mutate(
                {
                  user_id: user.id,
                  steam_id: user?.connected_platforms?.steam?.steam_id,
                },
                { onSuccess: () => window.location.reload() },
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NoStats;
