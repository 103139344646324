import { IGetGames } from "./types";
import { client } from "./config/index";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

const gamesService = {
  getGames: async () => {
    const res = await client.get("/v1/game");
    return res;
  },
  getGamePosts: async (params: { gameId?: string }) => {
    return await client.get(
      `/v1/post/game-related-posts?game_id=${params.gameId}`,
    );
  },
};

export const useGamesQuery = ({
  querySettings,
}: {
  querySettings?: object;
}) => {
  return useQuery(["allGames"], () => {
    return gamesService.getGames(), querySettings;
  });
};

export const getGamePostsQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: object;
  queryParams?: {
    gameId: string;
  };
}) => {
  return useQuery(
    ["gamePosts", queryParams?.gameId],
    () => {
      return gamesService.getGamePosts({ gameId: queryParams?.gameId });
    },
    querySettings,
  );
};

export const useGetAllGamesQuery = (data: IGetGames) =>
  useQuery({
    queryKey: ["games"],
    queryFn: () =>
      client.get("/v1/game", {
        params: data,
      }),
    onError: (err: any) => {
      toast.error("Error: " + err?.response?.data?.error_message);
    },
  });

export const useGetGameQuery = (id: string) =>
  useQuery({
    queryKey: ["game"],
    queryFn: () => client.get(`/v1/game/${id}`),
    enabled: !!id,
    onError: (err: any) => {
      toast.error("Error: " + err?.response?.data?.error_message);
    },
  });
