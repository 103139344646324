export default function StarIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8714 15.8571L10.2 17.5071C9.87143 17.8357 9.47143 18 9 18C8.52857 18 8.12857 17.8357 7.8 17.5071L6.12857 15.8571H3.85714C3.38571 15.8571 2.98214 15.6893 2.64643 15.3536C2.31071 15.0179 2.14286 14.6143 2.14286 14.1429V11.8714L0.492857 10.2C0.164286 9.87143 0 9.47143 0 9C0 8.52857 0.164286 8.12857 0.492857 7.8L2.14286 6.12857V3.85714C2.14286 3.38571 2.31071 2.98214 2.64643 2.64643C2.98214 2.31071 3.38571 2.14286 3.85714 2.14286H6.12857L7.8 0.492857C8.12857 0.164286 8.52857 0 9 0C9.47143 0 9.87143 0.164286 10.2 0.492857L11.8714 2.14286H14.1429C14.6143 2.14286 15.0179 2.31071 15.3536 2.64643C15.6893 2.98214 15.8571 3.38571 15.8571 3.85714V6.12857L17.5071 7.8C17.8357 8.12857 18 8.52857 18 9C18 9.47143 17.8357 9.87143 17.5071 10.2L15.8571 11.8714V14.1429C15.8571 14.6143 15.6893 15.0179 15.3536 15.3536C15.0179 15.6893 14.6143 15.8571 14.1429 15.8571H11.8714ZM9 11.1214L10.65 12.1071C10.8071 12.2071 10.9607 12.2036 11.1107 12.0964C11.2607 11.9893 11.3143 11.8429 11.2714 11.6571L10.8429 9.79286L12.3 8.52857C12.4429 8.4 12.4857 8.24643 12.4286 8.06786C12.3714 7.88929 12.2429 7.79286 12.0429 7.77857L10.1357 7.62857L9.38571 5.85C9.31429 5.67857 9.18571 5.59286 9 5.59286C8.81429 5.59286 8.68571 5.67857 8.61429 5.85L7.86429 7.62857L5.95714 7.77857C5.75714 7.79286 5.62857 7.88929 5.57143 8.06786C5.51429 8.24643 5.55714 8.4 5.7 8.52857L7.15714 9.79286L6.72857 11.6571C6.68571 11.8429 6.73929 11.9893 6.88929 12.0964C7.03929 12.2036 7.19286 12.2071 7.35 12.1071L9 11.1214Z"
        fill="#FFD54F"
      />
    </svg>
  );
}
