import { Stack, Typography } from "@mui/material";

import InfiniteScroll from "react-infinite-scroll-component";

interface ScrollableBoxProps {
  fetchData: any;
  hasMore: boolean;
  items: any;
  dataLength?: number;
  height?: number | string;
}

export default function ScrollableBox({
  fetchData,
  hasMore,
  items,
  dataLength,
  height,
}: ScrollableBoxProps) {
  return (
    <Stack direction="column" gap={1} py={1}>
      <InfiniteScroll
        dataLength={dataLength ?? 0}
        next={fetchData}
        hasMore={hasMore}
        loader={
          <Typography color="white" fontSize="12px">
            Loading...
          </Typography>
        }
        height={height ?? 400}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {items}
      </InfiniteScroll>
    </Stack>
  );
}
