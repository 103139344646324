import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../store/userReducer";
import { updateAuthAction } from "../../store/authReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddUserSteamGamesMutation } from "../../services/userGames";
import { getByUsername, useUpdateUserMutation } from "../../services/user";
import { updateNotificationAction } from "../../store/notificationReducer";
import toast from "react-hot-toast";

const Redirect = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const updateUser = useUpdateUserMutation();
  const addSteamGames = useAddUserSteamGamesMutation();
  const user = useSelector((state: any) => state.user);
  const notification = useSelector((state: any) => state.notification);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (location.pathname === "/app/redirect" && user.id) {
      const steam_id = searchParams.get("steam_id");

      if (!steam_id) {
        toast.error("Something went wrong\nPlease try again");
        navigate("/app/dashboard/home");
      } else {
        getByUsername({ username: steam_id, usertype: "steam" }).catch(
          (err: any) => {
            if (err?.response?.status === 404) {
              updateUser.mutate(
                {
                  ...user,
                  steam_id,
                  connected_platforms: {
                    ...user.connected_platforms,
                    steam: {
                      steam_id,
                    },
                  },
                },
                {
                  onSuccess: (res: any) => {
                    dispatch(updateUserAction(res?.data));
                    dispatch(
                      updateNotificationAction({
                        ...notification,
                        steam: true,
                      }),
                    );
                    navigate("/app/dashboard/home");
                  },
                  onError: () => {
                    toast.error("Something went wrong\nPlease try again");
                    navigate("/app/dashboard/home");
                  },
                },
              );

              addSteamGames.mutate({
                user_id: user.id,
                steam_id,
              });
            } else {
              toast.error(err?.response?.data?.error_message);
              navigate("/app/dashboard/home");
            }
          },
        );
      }
    } else if (location.pathname === "/auth/redirect") {
      if (searchParams.get("access_token")) {
        const auth = {
          access_token: searchParams.get("access_token") || "",
          refresh_token: searchParams.get("refresh_token") || "",
        };

        localStorage.setItem("auth", JSON.stringify(auth));
        dispatch(updateAuthAction(auth));
        navigate("/app/dashboard/home");
      } else {
        toast.error("Something went wrong\nPlease try again");
        navigate("/auth/sign-in");
      }
    }
  }, [user.id]);
  return <div>Redirect</div>;
};

export default Redirect;
