import { useContext, useMemo } from "react";

import BannerImg from "../../../components/Banner/BannerImg";
import BannerUpload from "../../../components/Upload/BannerUpload";
import { TeamContext } from "../../../contexts/TeamContext";
import usePermission from "../../../hooks/usePermission";

export default function TeamBanner() {
  const { checkPermission } = usePermission();
  const { setBannerId, teamData } = useContext(TeamContext);

  const savedImg = useMemo(() => {
    return teamData?.data?.background_logo;
  }, [teamData?.data?.background_logo]);

  if (checkPermission("team/edit/background_image")) {
    return <BannerUpload setBannerId={setBannerId} defaultBanner={savedImg} />;
  }
  return <BannerImg imgFile={teamData?.data?.background_logo} />;
}
