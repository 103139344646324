import { Grid, Stack, Typography } from "@mui/material";
import {
  deleteTeamMemberMutation,
  getSingleTeamQuery,
  getTeamMembersQuery,
  getTeamPostsQuery,
  getTeamRostersQuery,
  setJoinTeamMutation,
  updateTeamMutation,
} from "../../../services/team";
import { useEffect, useMemo, useState } from "react";

import AboutTeamBar from "../components/AboutTeamBar";
import CustomTab from "../../../components/Tab/CustomTab";
import JoinStatus from "../components/JoinStatus";
import JoinTeam from "../components/JoinTeam";
import PostCreate from "../../dashboard/components/PostCreate";
import TeamBanner from "../components/TeamBanner";
import { TeamContext } from "../../../contexts/TeamContext";
import TeamLogo from "../components/TeamLogo";
import TeamOverviewTab from "../components/TeamOverviewTab";
import TeamPlayersTab from "../components/TeamPlayersTab";
import TeamPost from "../components/TeamPost";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SingleTeam() {
  const { id } = useParams();
  const user = useSelector((state: any) => state.user);
  const [bannerId, setBannerId] = useState<string>("");
  const [joinTeamText, setJoinTeamText] = useState<string>("");
  const [isJoinOpen, setIsJoinOpen] = useState<boolean>(false);
  const [logoId, setLogoId] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("Overview");
  const [teamDescription, setTeamDescription] = useState<string>("");
  const [isPostCreateOpen, setIsPostCreateOpen] = useState<boolean>(false);
  const [isDescriptionSaveDisabled, setDescriptionSaveDisabled] =
    useState<boolean>(true);
  const [isHiringChecked, setIsHiringChecked] = useState<{
    isHiring: boolean;
    status: string;
  }>({
    isHiring: false,
    status: "default",
  });
  const { data: teamData, refetch: refetchTeamData } = getSingleTeamQuery({
    id: id ?? "",
    querySettings: {
      enabled: !!id,
      onSuccess: (initialData: any) => {
        setTeamDescription(initialData?.data?.description || "");
        setIsHiringChecked({
          isHiring: initialData?.data?.is_hiring,
          status: "default",
        });
      },
    },
  });

  const {
    data: teamRosters,
    refetch: refetchRosters,
    fetchNextPage: fetchNextRosters,
  } = getTeamRostersQuery({
    querySettings: {
      enabled: !!id,
      keepPreviousData: false,
    },
    queryParams: {
      teamId: id,
      isRoster: 1,
      limit: 10,
    },
  });

  const overallRostersCount = useMemo(() => {
    return teamRosters?.pages?.[0]?.data?.count;
  }, [teamRosters?.pages?.[0]?.data?.count]);

  const teamRostersData =
    teamRosters?.pages.flatMap((page) => page?.data?.team_members) ?? [];

  const {
    data: teamMembers,
    refetch: refetchTeamMembers,
    fetchNextPage: fetchNextMembers,
  } = getTeamMembersQuery({
    querySettings: {
      enabled: !!id,
      keepPreviousData: false,
    },
    queryParams: {
      teamId: id,
      isRoster: 2,
      limit: 10,
    },
  });

  const teamMembersData =
    teamMembers?.pages.flatMap((page) => page?.data?.team_members) ?? [];

  const overallTeamTembers = useMemo(() => {
    return teamMembers?.pages?.[0]?.data?.count;
  }, [teamMembers?.pages?.[0]?.data?.count]);

  const { data: teamPosts, refetch: refetchPosts } = getTeamPostsQuery({
    querySettings: {
      enabled: !!id,
    },
    queryParams: {
      teamId: id,
    },
  });

  const { mutate: updateTeamMutate } = updateTeamMutation({
    id: id ?? "",
    mutationSettings: {
      onSuccess: () => {
        setDescriptionSaveDisabled(true);
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    },
  });

  const { mutate: joinTeamMutate } = setJoinTeamMutation({
    mutationSettings: {
      onSuccess: () => {
        setIsJoinOpen(false);
        refetchTeamData();
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    },
  });

  const { mutate: deleteMemberMutate } = deleteTeamMemberMutation({
    mutationSettings: {
      onSuccess: () => {
        refetchTeamData();
        refetchTeamMembers();
        refetchRosters();
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    },
  });

  const handleSave = (path: string) => {
    updateTeamMutate({
      ...teamData?.data,
      description:
        path == "team/edit/description"
          ? teamDescription
          : teamData?.data?.description,
      logo: logoId || teamData?.data?.logo,
      background_logo: bannerId || teamData?.data?.background_logo,
      is_hiring: isHiringChecked.isHiring,
      path: path,
    });
  };

  const handleDescriptionSave = () => {
    if (!teamData?.data) return;
    handleSave("team/edit/description");
  };

  const handleJoinTeam = () => {
    joinTeamMutate({ team_id: id, user_id: user.id, message: joinTeamText });
  };

  useEffect(() => {
    if (bannerId) {
      handleSave("team/edit/background_image");
    } else if (logoId) {
      handleSave("team/edit/logo");
    }
  }, [bannerId, logoId]);

  useEffect(() => {
    if (
      isHiringChecked.isHiring == teamData?.data?.is_hiring ||
      !teamData ||
      isHiringChecked?.status == "default"
    )
      return;

    handleSave("team/edit/hiring");

    setTimeout(() => {
      refetchTeamData();
    }, 1000);
  }, [isHiringChecked]);

  return (
    <TeamContext.Provider
      value={{
        teamData: teamData,
        teamDescription,
        setTeamDescription,
        handleDescriptionSave,
        savedTeamData: teamData?.data,
        joinTeamText: joinTeamText,
        setJoinTeamText: setJoinTeamText,
        deleteMemberMutate,
        setBannerId,
        setLogoId,
        setIsPostCreateOpen,
        refetchTeamMembers,
        refetchRosters,
        isHiring: isHiringChecked,
        setIsHiring: setIsHiringChecked,
        refetchPosts,
        fetchNextMembers,
        permissions: new Map(
          teamData?.data?.team_member_info?.permissions?.map((p: any) => [
            p?.name.toLowerCase(),
            p,
          ]),
        ),
      }}
    >
      <Stack direction="column" spacing={2}>
        <TeamBanner />
        <TeamLogo />

        <Stack direction="column" px={3} gap={1}>
          <Stack direction="row" gap={2}>
            <Typography fontSize="24px" fontWeight={600} color="white">
              {teamData?.data?.name}
            </Typography>
            <JoinStatus
              joinStatus={teamData?.data?.is_member}
              setIsJoinOpen={setIsJoinOpen}
            />
          </Stack>
          <Typography fontSize="14px" fontWeight={500} color="#8C8D94">
            {/* {teamMembers?.data?.count + teamRosters?.data?.count} Players */}
          </Typography>
        </Stack>

        <JoinTeam
          handleJoinTeam={handleJoinTeam}
          isOpen={isJoinOpen}
          setIsOpen={setIsJoinOpen}
        />

        <Stack direction="column" px={3} gap={4} pb={3} width="100%">
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <CustomTab
                  setActiveTab={setActiveTab}
                  options={["Overview", "Players"]}
                />
                <TeamPost />
              </Stack>
            </Grid>
            <Grid item md={4} />
          </Grid>

          <PostCreate
            type="team"
            teamId={teamData?.data?.id}
            open={isPostCreateOpen}
            handleClose={() => {
              setIsPostCreateOpen(false);
              refetchPosts();
            }}
            gameId={teamData?.data?.game_id}
          />

          <Grid container spacing={2}>
            <Grid item md={8}>
              {activeTab == "Overview" && (
                <TeamOverviewTab teamPosts={teamPosts} />
              )}
              {activeTab == "Players" && (
                <TeamPlayersTab
                  teamMembers={teamMembersData}
                  teamRosters={teamRostersData}
                  overallMembersCount={overallTeamTembers}
                  overallRostersCount={overallRostersCount}
                  fetchNextMembers={fetchNextMembers}
                  fetchNextRosters={fetchNextRosters}
                />
              )}
            </Grid>
            <Grid item md={4}>
              <AboutTeamBar
                name={teamData?.data?.name}
                createdDate={teamData?.data?.created_at}
                gameName={teamData?.data?.game_title}
                gameLogo={teamData?.data?.game_logo}
                isDescriptionSaveDisabled={isDescriptionSaveDisabled}
                setDescriptionSaveDisabled={setDescriptionSaveDisabled}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </TeamContext.Provider>
  );
}
