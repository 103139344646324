/* eslint-disable @typescript-eslint/no-explicit-any */
import { client } from "../services/config";
import Compressor from "compressorjs";

const imageCache = new Map<string, string>();

const compressImage = (file: File): Promise<File | Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 900,
      maxHeight: 900,
      success: (compressedResult: any) => {
        resolve(compressedResult);
      },
      error: (error: any) => {
        reject(error);
      },
    });
  });
};

export const setBinaryImage = async (image: File): Promise<string | null> => {
  const cacheKey = image.name + image.size + image.lastModified;
  if (imageCache.has(cacheKey)) {
    return imageCache.get(cacheKey) || null;
  }

  try {
    const compressedImage = await compressImage(image);

    const binaryImg = new FormData();
    binaryImg.append("file", compressedImage);

    const response = await client.post(`/v1/file/upload`, binaryImg);
    const fileId = response.data.filename;

    imageCache.set(cacheKey, fileId);

    return fileId;
  } catch (error) {
    return null;
  }
};
