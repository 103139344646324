import { Stack } from "@mui/material";
import { useState } from "react";

type CustomTabProps = {
  options: Array<string>;
  setActiveTab: (value: string) => void;
};

export default function CustomTab({ options, setActiveTab }: CustomTabProps) {
  const [isActive, setIsActive] = useState(0);

  function handleActive(index: number) {
    setIsActive(index);
    setActiveTab(options[index]);
  }

  return (
    <Stack
      direction="row"
      borderRadius="12px"
      border="1px solid #222228"
      width="max-content"
      fontSize="14px"
      p="3px"
      height={50}
      justifyContent="space-between"
      alignItems={"center"}
    >
      {options?.map((option, index) => (
        <Stack
          onClick={() => handleActive(index)}
          key={index}
          px={3}
          bgcolor={isActive === index ? "#4957E9" : ""}
          color={isActive === index ? "white" : "grey"}
          height="100%"
          borderRadius="6px"
          alignItems="center"
          direction="row"
          sx={{ cursor: "pointer" }}
        >
          {option}
        </Stack>
      ))}
    </Stack>
  );
}
