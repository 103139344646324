import { useState } from "react";
import { Tooltip } from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import styles from "./style.module.scss";

interface IStatsCell {
  player: {
    hero_details: {
      shortName: string;
      displayName: string;
    };
    item_details: {
      shortName: string;
      displayName: string;
    }[];
    personaname?: string;
    level: number;
    kills: number;
    deaths: number;
    assists: number;
    last_hits: number;
    denies: number;
    gold_per_min: number;
    xp_per_min: number;
    net_worth: number;
    gold_spent: number;
  };
  isYou: boolean;
}

const StatsCell: React.FC<IStatsCell> = ({ player, isYou }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={`${styles.stats_cell_container} ${isYou && styles.you}`}
      onClick={() => setIsCollapsed((prev) => !prev)}
    >
      <div className={styles.stats_cell_top}>
        <div className={styles.hero_info_wrapper}>
          <img
            loading="lazy"
            className={styles.hero_image}
            src={`https://cdn.stratz.com/images/dota2/heroes/${player?.hero_details?.shortName}_vert.png`}
            alt="Hero Image"
          />
          <div className={styles.text_info_wrapper}>
            {isCollapsed && player?.personaname && (
              <h3 className={styles.username}>{player?.personaname}</h3>
            )}
            <div className={styles.hero_info_text_wrapper}>
              <span className={styles.hero_level}>{player?.level}</span>
              <p className={styles.secondary_text}>
                {player?.hero_details?.displayName}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.info_right_wrapper}>
          <div className={styles.kda_info_wrapper}>
            <div className={styles.kda_text_wrapper}>
              <span className={styles.secondary_text}>K</span>
              <span className={styles.secondary_text}>D</span>
              <span className={styles.secondary_text}>A</span>
            </div>

            <div className={styles.kda_text_wrapper}>
              <span className={styles.secondary_text}>{player?.kills}</span>/
              <span className={styles.secondary_text}>{player?.deaths}</span>/
              <span className={styles.secondary_text}>{player?.assists}</span>
            </div>
          </div>

          {isCollapsed ? (
            <KeyboardArrowUpRoundedIcon className={styles.icon} />
          ) : (
            <KeyboardArrowDownRoundedIcon className={styles.icon} />
          )}
        </div>
      </div>

      {isCollapsed && (
        <div className={styles.stats_cell_bottom}>
          <div className={styles.items_wrapper}>
            <p className={styles.stats_text}>Items</p>

            <ul className={styles.items_list}>
              {player?.item_details?.map((item: any, i: number) => {
                return (
                  <Tooltip key={i} placement="top" title={item?.displayName}>
                    <li className={styles.item}>
                      <img
                        loading="lazy"
                        alt="Item img"
                        className={styles.item_img}
                        src={`https://cdn.stratz.com/images/dota2/items/${item?.shortName}.png`}
                      />
                    </li>
                  </Tooltip>
                );
              })}
            </ul>
          </div>

          <div className={styles.details_wrapper}>
            <p className={styles.stats_text}>Details</p>

            <ul className={styles.details_list}>
              <li className={styles.details_item}>
                <p className={styles.secondary_text}>Last Hits</p>
                <p className={styles.stats_text}>{player?.last_hits}</p>
              </li>

              <hr className={styles.vertical_line} />

              <li className={styles.details_item}>
                <p className={styles.secondary_text}>Denied</p>
                <p className={styles.stats_text}>{player?.denies}</p>
              </li>

              <hr className={styles.vertical_line} />

              <li className={styles.details_item}>
                <p className={styles.secondary_text}>Gold Mined</p>
                <p className={styles.stats_text}>{player?.gold_per_min}</p>
              </li>

              <hr className={styles.vertical_line} />

              <li className={styles.details_item}>
                <p className={styles.secondary_text}>XP Mined</p>
                <p className={styles.stats_text}>{player?.xp_per_min}</p>
              </li>

              <hr className={styles.vertical_line} />

              <li className={styles.details_item}>
                <p className={styles.secondary_text}>Level</p>
                <p className={styles.stats_text}>{player?.level}</p>
              </li>

              <hr className={styles.vertical_line} />

              <li className={styles.details_item}>
                <p className={styles.secondary_text}>Net Worth</p>
                <p className={styles.stats_text}>{player?.net_worth}</p>
              </li>

              <hr className={styles.vertical_line} />

              <li className={styles.details_item}>
                <p className={styles.secondary_text}>Gold Spent</p>
                <p className={styles.stats_text}>{player?.gold_spent}</p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsCell;
