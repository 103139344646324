import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { subpages } from "../helper/demo";
import Tooltip from "@mui/material/Tooltip";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import { Riot } from "../../assets/icons/Riot";
import PostCreate from "./components/PostCreate";
import { Steam } from "../../assets/icons/Steam";
import riot from "../../assets/lotties/riot.json";
import RiotConnect from "./components/RiotConnect";
import steam from "../../assets/lotties/steam.json";
import FollowModal from "../../components/FollowsModal";
import ProfilePhoto from "../../components/ProfilePhoto";
import { useSteamConnectMutation } from "../../services/user";
import { showSidebarAction } from "../../store/showSidebarReducer";
import NotificationLotify from "../../components/NotificationLotify";
import { updateNotificationAction } from "../../store/notificationReducer";
import styles from "./style.module.scss";
import { useImage } from "../../services/getImages";

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [active, setActive] = useState("");
  const steamConnect = useSteamConnectMutation();
  const user = useSelector((state: any) => state.user);
  const [open, setOpen] = useState({ connect: false, post: false });
  const notification = useSelector((state: any) => state.notification);
  const [openFollowModal, setOpenFollowModal] = useState({
    followers: false,
    followings: false,
  });

  const { data: userAvatar } = useImage(user?.avatar);
  const { data: userBanner } = useImage(user?.banner);

  useEffect(() => {
    dispatch(showSidebarAction(false));
  }, []);

  useEffect(() => {
    setActive(location.pathname.split("/")[3]);
  }, [location.pathname]);

  useEffect(() => {
    let timer: any;
    if (notification.riot) {
      timer = setTimeout(() => {
        dispatch(updateNotificationAction({ ...notification, riot: false }));
      }, 3000);
    }

    if (notification.steam) {
      timer = setTimeout(() => {
        dispatch(updateNotificationAction({ ...notification, steam: false }));
      }, 3500);
    }

    return () => clearTimeout(timer);
  }, [notification.steam, notification.riot]);

  const handleSteamConnect = () => {
    steamConnect.mutate(
      {},
      {
        onSuccess: (res: any) => {
          window.location.href = res.data;
        },
      },
    );
  };

  return (
    <div className={styles.dashboard}>
      {open.connect && (
        <RiotConnect
          open={open.connect}
          handleClose={() => setOpen((prev) => ({ ...prev, connect: false }))}
        />
      )}

      {open.post && (
        <PostCreate
          open={open.post}
          handleClose={() => setOpen((prev) => ({ ...prev, post: false }))}
        />
      )}

      {(notification.riot || notification.steam) && (
        <NotificationLotify
          src={notification.riot ? riot : notification.steam ? steam : ""}
        />
      )}

      <FollowModal
        userId={user?.id}
        isFollowersModal={true}
        open={openFollowModal.followers}
        handleClose={() =>
          setOpenFollowModal({ followers: false, followings: false })
        }
      />

      <FollowModal
        userId={user?.id}
        isFollowersModal={false}
        open={openFollowModal.followings}
        handleClose={() =>
          setOpenFollowModal({ followers: false, followings: false })
        }
      />

      <Banner
        height="300px"
        isDashboard={true}
        img={user.banner ? userBanner : ""}
      />

      <div className={styles.dashboard_body}>
        <div className={styles.dashboard_inner_body}>
          <div className={styles.body_account_wrapper}>
            <div className={styles.account_info_wrapper}>
              <ProfilePhoto
                size="128px"
                rounded={true}
                isDashboard={true}
                className={styles.profile_photo}
                img={user.avatar ? userAvatar : ""}
              />

              <div className={styles.account_info_inner_wrapper}>
                <h1 className={styles.account_profile_name}>
                  {user.username || "Unknown"}
                </h1>

                <div className={styles.accounts_connect}>
                  <Tooltip
                    placement="top"
                    title={`${
                      !user.connected_platforms?.steam?.steam_id ? "Not " : ""
                    }Connected`}
                  >
                    <Box>
                      <Button
                        icon={<Steam />}
                        text={
                          !user.connected_platforms?.steam?.steam_id
                            ? "Connect"
                            : ""
                        }
                        onClick={handleSteamConnect}
                        disabled={user.connected_platforms?.steam?.steam_id}
                        className={`${styles.connect_button} ${
                          user.connected_platforms?.steam?.steam_id &&
                          styles.connect_success
                        }`}
                      />
                    </Box>
                  </Tooltip>

                  <Tooltip
                    placement="top"
                    title={`${
                      !user.connected_platforms?.riot?.id ? "Not " : ""
                    }Connected`}
                  >
                    <Box>
                      <Button
                        icon={<Riot />}
                        text={
                          !user.connected_platforms?.riot?.id ? "Connect" : ""
                        }
                        disabled={user.connected_platforms?.riot?.id}
                        onClick={() =>
                          setOpen((prev) => ({ ...prev, connect: true }))
                        }
                        className={`${styles.connect_button} ${
                          user.connected_platforms?.riot?.id &&
                          styles.connect_success
                        }`}
                      />
                    </Box>
                  </Tooltip>
                </div>
              </div>
            </div>

            <Button
              text="Create Post"
              className={styles.post_button}
              onClick={() => setOpen((prev) => ({ ...prev, post: true }))}
            />
          </div>

          <div className={styles.body_follow_wrapper}>
            <p
              className={styles.follow_text}
              onClick={() =>
                setOpenFollowModal({ followers: false, followings: true })
              }
            >
              {user?.following} Following
            </p>
            <p
              className={styles.follow_text}
              onClick={() =>
                setOpenFollowModal({ followers: true, followings: false })
              }
            >
              {user?.followers} Follower{user?.followers !== 1 && "s"}
              {user?.follow_requests ? (
                <span
                  className={styles.follow_requests}
                  style={{
                    padding:
                      user?.follow_requests < 10
                        ? "2px 6px"
                        : user?.follow_requests < 100
                          ? "4px"
                          : user?.follow_requests < 1000
                            ? "8px 4px"
                            : "12px 4px",
                  }}
                >
                  {user?.follow_requests}
                </span>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>

        <div className={styles.subpages_wrapper}>
          <div className={styles.subpages_header_wrapper}>
            {subpages.map((e, i) => {
              const path = e.toLocaleLowerCase().replaceAll(" ", "-");
              const isActive = active === path;

              return (
                <Link
                  key={i}
                  to={`/app/dashboard/${path}`}
                  className={styles.subpage_link}
                >
                  <Button
                    text={e}
                    className={`${styles.subpage_button} ${
                      isActive && styles.active_subpage
                    }`}
                  />
                </Link>
              );
            })}
          </div>

          <div className={styles.subpage_content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
