import { IconButton } from "@mui/material";
import LOLStatistics from "./LOLStatistics";
import CS2Statistics from "./CS2Statistics";
import DotaStatistics from "./DotaStatistics";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import styles from "./style.module.scss";

const UserStats = () => {
  const { game, userId } = useParams();
  const navigate = useNavigate();

  return (
    <div className={styles.stats_wrapper}>
      <header className={styles.stats_header}>
        <IconButton
          className={styles.back_icon}
          onClick={() => navigate(`/app/user/${userId}/statistics`)}
        >
          <KeyboardBackspaceRoundedIcon className={styles.back_icon} />
        </IconButton>
        Back
      </header>

      <div className={styles.stats_content}>
        {game === "dota2" ? (
          <DotaStatistics />
        ) : game === "leagueoflegends" ? (
          <LOLStatistics />
        ) : (
          <CS2Statistics />
        )}
      </div>
    </div>
  );
};

export default UserStats;
