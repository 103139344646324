import { Stack, Typography } from "@mui/material";

import BlockIcon from "@mui/icons-material/Block";
import DoneIcon from "@mui/icons-material/Done";

interface HiringStatusLabelProps {
  isHiring?: boolean;
}

export default function HiringStatusLabel({
  isHiring,
}: HiringStatusLabelProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1} width="100%">
      <Stack
        bgcolor={
          isHiring ? "rgba(73, 87, 233, 0.15)" : "rgba(226, 50, 47, 0.15)"
        }
        width={32}
        height={32}
        borderRadius="6px"
        alignItems="center"
        justifyContent="center"
      >
        {isHiring ? (
          <DoneIcon sx={{ width: "20px" }} htmlColor="#4957E9" />
        ) : (
          <BlockIcon sx={{ width: "20px" }} htmlColor="#E2322F" />
        )}
      </Stack>
      <Typography fontSize="14px" color="#8C8D94">
        {isHiring
          ? "This team is currently recruiting"
          : "This team is currently not recruiting"}
      </Typography>
    </Stack>
  );
}
