import React from "react";
import { IProfilePhoto } from "../types";
import { useDropzone } from "react-dropzone";
import defaulAvatar from "../../assets/images/default-avatar.png";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

const ProfilePhoto: React.FC<IProfilePhoto> = ({
  img,
  size,
  onDrop,
  rounded,
  className,
  isEditMode,
  isDashboard,
}) => {
  const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const style = {
    width: size,
    height: size,
    backgroundColor: "#000",
    borderRadius: rounded ? "50%" : "8px",
    backgroundImage: `url(${img || defaulAvatar})`,
    borderWidth: size === "128px" || size === "72px" ? "5px" : "0",
  };

  return (
    <div className={`${styles.profile_photo} ${className}`} style={style}>
      {!img && isDashboard && (
        <button
          className={styles.edit_button}
          onClick={() => navigate("/app/profile-edit")}
        >
          <CameraAltRoundedIcon />
        </button>
      )}
      {isEditMode && (
        <button {...getRootProps()} className={styles.photo_button}>
          <input {...getInputProps()} />
          <CameraAltRoundedIcon />
        </button>
      )}
    </div>
  );
};

export default ProfilePhoto;
