import { IGameHeader } from "../../../dashboard/components/types";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import defaultImg from "../../../../assets/images/default-banner.png";
import styles from "./style.module.scss";
import { useImage } from "../../../../services/getImages";
import Loader from "../../../../components/Loader";

const GameHeader: React.FC<IGameHeader> = ({
  genre,
  gameImg,
  website,
  features,
  gameLogo,
  developer,
  publisher,
  releaseDate,
}) => {
  const { data: gameImgST, isLoading: isLoadingImgST } = useImage(gameImg);
  const { data: gameLogoST, isLoading: isLoadingLogoST } = useImage(gameLogo);

  return (
    <div className={styles.game_logo_block}>
      {isLoadingImgST && isLoadingLogoST ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.game_header}>
          <div className={styles.game_image_wrapper}>
            <img
              loading="lazy"
              className={styles.game_image}
              src={gameImg ? gameImgST : defaultImg}
              alt="Game Photo"
            />
          </div>

          <div className={styles.game_description_wrapper}>
            <div className={styles.game_logo_wrapper}>
              <img
                loading="lazy"
                className={styles.game_image}
                src={gameLogoST}
                alt="Game Logo"
              />
            </div>

            <ul className={styles.game_info_wrapper}>
              <li className={styles.info_wrapper}>
                <p className={styles.info_key}>Developer</p>
                <p className={styles.info_value}>{developer}</p>
              </li>

              <li className={styles.info_wrapper}>
                <p className={styles.info_key}>Publisher</p>
                <p className={styles.info_value}>{publisher}</p>
              </li>

              <li className={styles.info_wrapper}>
                <p className={styles.info_key}>Release Date</p>
                <p className={styles.info_value}>
                  {new Date(releaseDate).toLocaleDateString()}
                </p>
              </li>

              <li className={styles.info_wrapper}>
                <p className={styles.info_key}>Website</p>
                <a
                  href={website}
                  target="blank"
                  className={styles.info_value_link}
                >
                  Visit <OpenInNewIcon className={styles.link_icon} />
                </a>
              </li>
            </ul>

            <ul className={`${styles.game_info_wrapper} ${styles.down}`}>
              <li>
                <p className={styles.info_key}>Genre</p>

                <div className={styles.values_wrapper}>
                  {genre?.map((e, i) => (
                    <p className={styles.info_value_genre} key={i}>
                      {e}
                      {genre.length - 1 === i ? "" : ","}
                    </p>
                  ))}
                </div>
              </li>

              <li>
                <p className={styles.info_key}>Features</p>
                <div className={styles.values_wrapper}>
                  {features?.map((e, i) => (
                    <p className={styles.info_value_genre} key={i}>
                      {e}
                      {features.length - 1 === i ? "" : ","}
                    </p>
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameHeader;
