import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Button from "../Button";
import DeletePopup from "../../views/Teams/components/DeletePopup";
import Like from "../Like";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import { Markup } from "interweave";
import ProfilePhoto from "../ProfilePhoto";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { deletePostMutation } from "../../services/post";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { utcToLocalTime } from "../../helpers/utcToLocalTime";
import ЕхпаndMore from "../Expand/ExpandMore";
import { useImage } from "../../services/getImages";

const Post: React.FC<{
  post: any;
  refetchPostsFn?: any;
  isOwner?: boolean;
}> = ({ post, refetchPostsFn, isOwner }) => {
  const location = useLocation();
  const [likesCount, setLikesCount] = useState(0);
  const user = useSelector((state: any) => state.user);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isExpandOpen, setIsExpandOpen] = useState({ status: "default" });

  const { data: userAvatar } = useImage(post?.user_avatar);
  const { data: postMedia } = useImage(post?.media);

  const { mutate: deletePostMutate } = deletePostMutation({
    mutationSettings: {
      onSuccess: () => {
        refetchPostsFn && refetchPostsFn();
        setIsDeleteOpen(false);
      },
    },
  });

  useEffect(() => {
    setLikesCount(post?.likes);
  }, [post?.likes]);

  function handleDelete() {
    setIsDeleteOpen(true);
    deletePostMutate(post?.id);
    isExpandOpen && setIsExpandOpen({ status: "close" });
  }

  return (
    <div className={styles.post_wrapper}>
      <div className={styles.post}>
        <div className={styles.post_header}>
          <div className={styles.post_info}>
            <Link
              to={
                post?.user_id === user?.id
                  ? "/app/dashboard/home"
                  : `/app/user/${post?.user_id}/posts`
              }
            >
              <ProfilePhoto
                rounded={false}
                size="40px"
                img={post?.user_avatar ? userAvatar : ""}
              />
            </Link>
            <div className={styles.profile_info}>
              <Link
                to={
                  post?.user_id === user?.id
                    ? "/app/dashboard/home"
                    : `/app/user/${post?.user_id}/posts`
                }
              >
                <h3 className={styles.profile_name}>{post?.username}</h3>
              </Link>
              <p className={styles.post_time}>
                {utcToLocalTime(post?.created_at)}
              </p>
            </div>
          </div>

          <Stack direction="row" alignItems="center">
            {post?.team_name && (
              <Typography color="#8C8D94" fontSize="14px" fontWeight={600}>
                <Link
                  style={{ color: "#4655f4" }}
                  to={`/app/teams/${post?.team_id}`}
                >
                  {post?.team_name}
                </Link>
              </Typography>
            )}
            {(isOwner || post?.is_own) && (
              <ЕхпаndMore
                options={[
                  {
                    canManage: post?.is_own || isOwner,
                    id: post?.id,
                    name: "Delete",
                    onClick: () => {
                      setIsDeleteOpen(true);
                    },
                  },
                ]}
                isExpandOpen={isExpandOpen}
              />
            )}
          </Stack>

          <DeletePopup
            title="Delete post"
            content="Are you sure you want to delete this post?"
            buttonLabel="Delete"
            isOpen={isDeleteOpen}
            setIsOpen={setIsDeleteOpen}
            id={post?.id}
            handleDelete={handleDelete}
            helperFn={() => {
              isExpandOpen && setIsExpandOpen({ status: "close" });
            }}
          />
        </div>

        <div className={styles.post_text_wrapper}>
          <h3 className={styles.post_title}>{post?.title}</h3>
          <div className={styles.post_text}>
            <Markup content={post?.content} />
          </div>
        </div>

        {post?.media && (
          <div
            className={styles.post_image_wrapper}
            style={{
              backgroundImage: `url(${postMedia})`,
            }}
          ></div>
        )}

        <div className={styles.post_footer}>
          <div className={styles.buttons_wrapper}>
            <Like
              post={post}
              likes={likesCount}
              onClick={setLikesCount}
              current_user_liked={post?.current_user_liked}
            />

            <Link
              to={`/app/${
                location.pathname.split("/")[2]
              }/publication/${post?.id}`}
            >
              <Button
                text="Comment"
                className={styles.post_button}
                icon={<MapsUgcRoundedIcon className={styles.post_icon} />}
              />
            </Link>
          </div>

          <div className={styles.stats_wrapper}>
            <p className={styles.stats_text}>
              {likesCount} Like{likesCount !== 1 && "s"}
            </p>

            <Link
              to={`/app/${
                location.pathname.split("/")[2]
              }/publication/${post?.id}`}
            >
              <p className={styles.stats_text}>
                {post?.count_comments} Comment
                {post?.count_comments !== 1 && "s"}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
