import CustomCheckbox from "../../../components/Checkbox/CustomCheckbox";
import { TeamContext } from "../../../contexts/TeamContext";
import { useContext } from "react";

export default function HiringStatusCheck() {
  const { isHiring, setIsHiring } = useContext(TeamContext);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsHiring({
      isHiring: event.target.checked,
      status: "loading",
    });
  }

  return (
    <CustomCheckbox
      label="Currently Recruiting"
      checked={isHiring?.isHiring}
      onChange={handleOnChange}
    />
  );
}
