import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./style.module.scss";

interface INotification {
  close: any;
  date?: string;
  state: "info" | "error" | "success";
}

const Notification: React.FC<INotification> = ({ state, date, close }) => {
  const style = {
    borderColor: state === "success" ? "#4655f4" : "#FFD54F",
  };

  return (
    <div style={style} className={styles.noification_wrapper}>
      {state === "info" && (
        <div className={styles.text_wrapper}>
          <h2 className={styles.info_heading}>Please note!</h2>
          <p className={styles.info_text}>
            If you change your name on Game drive, you can&#39;t change it again
            for <strong>60 days</strong>. Don&#39;t add any unusual
            capitalization, punctuation, characters or random words.
          </p>
        </div>
      )}

      {state === "error" &&
        (date?.includes(":") ? (
          <p className={styles.error_text}>
            You have recently changed your username! you have{" "}
            <strong>{date}</strong> left until you can change your username
            again.
          </p>
        ) : (
          <p className={styles.error_text}>
            You have recently changed your username! you have{" "}
            <strong>{date}</strong> day{date !== "1" && "s"} left until you can
            change your username again.
          </p>
        ))}

      {state === "success" && (
        <p className={styles.success_text}>
          You have successfully updated your profile.
        </p>
      )}

      <CloseRoundedIcon className={styles.close_icon} onClick={close} />
    </div>
  );
};

export default Notification;
