import { Stack, Typography } from "@mui/material";
import defaultImg from "../../assets/images/default-avatar.png";
import { useImage } from "../../services/getImages";

export default function TeamTableRow({
  team,
  onClick,
  viewRef,
}: {
  team?: any;
  onClick?: () => void;
  viewRef?: any;
}) {
  const { data: teamLogo } = useImage(team?.logo);

  return (
    <Stack
      direction="row"
      ref={viewRef}
      bgcolor="#1D1D23"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      p={0.5}
      borderRadius="12px"
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <img
          loading="lazy"
          alt="team"
          src={team?.logo ? teamLogo : defaultImg}
          width={52}
          height={52}
          style={{
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />

        <Stack direction="column" spacing={0}>
          <Typography color="white" fontWeight={700} fontSize="14px">
            {team?.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="#fff" fontSize="14px">
              {team?.game_title}
            </Typography>
            <Typography color="#8C8D94" fontSize="12px">
              {team?.joined ? "Joined" : null}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
        px={2}
        py={0.5}
      >
        <Stack direction="column" spacing={0.5} alignItems="start">
          <Typography color="#8C8D94" fontSize="12px">
            Coaches
          </Typography>
          <Typography color="white" fontSize="14px">
            {team?.couches_count}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={0.5} alignItems="start">
          <Typography color="#8C8D94" fontSize="12px">
            Players
          </Typography>
          <Typography color="white" fontSize="14px">
            {team?.members_count}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
