import { useEffect, useState } from "react";
import Input from "../../../components/Input";
import { useLocation } from "react-router-dom";
import { getGlobalSearch } from "../../../services/user";
import SearchResults from "../../../components/SearchResults";
import styles from "./style.module.scss";

const Header = () => {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [resultsCount, setResultsCount] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState<any>([]);
  const { data, refetch, fetchNextPage } = getGlobalSearch({
    search: searchValue,
    limit: 10,
  });

  useEffect(() => {
    if (searchValue) {
      refetch();
    }
  }, [searchValue]);

  useEffect(() => {
    setSearchResults(
      data?.pages?.flatMap((page: any) => {
        setResultsCount(page?.data?.count);
        return page?.data?.response;
      }) || [],
    );
  }, [data]);

  useEffect(() => {
    if (
      location.pathname === "/app/news" ||
      location.pathname === "/app/games" ||
      location.pathname === "/app/teams" ||
      location.pathname === "/app/statistics" ||
      location.pathname === "/app/dashboard/home/"
    ) {
      setSearchValue("");
    }
  }, [location.pathname]);

  const handleSearchInput = (e: any) => {
    if (e.target.value) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    setSearchValue(e.target.value);
  };

  const handleInputFocus = () => {
    if (searchValue?.length) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.header_inner_wrapper}>
        <div className={styles.header_input_wrapper} tabIndex={0}>
          <Input
            type="search"
            value={searchValue}
            onFocus={handleInputFocus}
            onChange={handleSearchInput}
            inputClassName={styles.header_input}
            placeholder="Search for players, teams and games"
          />

          {showResults && (
            <div className={styles.search_results_wrapper}>
              <SearchResults
                next={fetchNextPage}
                data={searchResults}
                dataLength={resultsCount}
                close={() => setShowResults(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.header_icons_wrapper}></div>
    </header>
  );
};

export default Header;
