export const newsGames = [
  {
    id: 730,
    title: "CS2",
  },
  {
    id: 2338770,
    title: "2K",
  },
  {
    id: 570,
    title: "Dota 2",
  },
  {
    id: 578080,
    title: "PUBG",
  },
  {
    id: 271590,
    title: "GTA V",
  },
  {
    id: 489830,
    title: "Skyrim",
  },
  {
    id: 2195250,
    title: "Fifa 24",
  },
  {
    id: 2140330,
    title: "Madden 24",
  },
  {
    id: 1085660,
    title: "Destiny 2",
  },
  {
    id: 1245620,
    title: "Elden Ring",
  },
  {
    id: 1716740,
    title: "Starfield",
  },
  {
    id: 2357570,
    title: "Overwatch 2",
  },
  {
    id: 1938090,
    title: "Call of Duty",
  },
  {
    id: 292030,
    title: "The Witcher 3",
  },
  {
    id: 1172470,
    title: "Apex Legends",
  },
  {
    id: 1086940,
    title: "Baldur's Gate 3",
  },
  {
    id: 1091500,
    title: "Cyberpunk 2077",
  },
];
