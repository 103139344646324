import React from "react";
import Modal from "../Modal";
import ProfilePhoto from "../ProfilePhoto";
import styles from "./style.module.scss";
import Button from "../Button";
import { useImage } from "../../services/getImages";

interface ICancelFollowModal {
  open: boolean;
  user: {
    id: string;
    avatar: string;
    username: string;
  };
  close: () => void;
  modalType: string;
  handleConfirm: () => void;
}

const CancelFollowModal: React.FC<ICancelFollowModal> = ({
  open,
  user,
  close,
  modalType,
  handleConfirm,
}) => {
  const { data: userAvatar } = useImage(user?.avatar);

  return (
    <Modal open={open} close={close} width="400px" title="Cancel request">
      <div className={styles.cancel_request_wrapper}>
        <ProfilePhoto
          size="128px"
          rounded={true}
          img={user?.avatar ? userAvatar : ""}
        />
        <p className={styles.cancel_request_title}>
          Do you really want to
          {modalType === "unfollow"
            ? " unfollow "
            : modalType === "remove_follower"
              ? " remove "
              : " cancel your follow request to "}
          <span className={styles.user_name_link}>{user?.username}</span>{" "}
          {modalType === "remove_follower" ? " from followers" : ""}?
        </p>
        <div className={styles.cancel_request_buttons_wrapper}>
          <Button
            text="Cancel"
            onClick={close}
            className={styles.cancel_button}
          />
          <Button
            text="Confirm"
            onClick={handleConfirm}
            className={styles.confirm_button}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CancelFollowModal;
