import { useEffect, useState } from "react";
import AuthBlock from "../../Block";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import { useSignUpMutation } from "../../../../services/auth";
import { updateAuthAction } from "../../../../store/authReducer";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { isUsernameCorrect, isPasswordCorrect } from "../../utils/helper";
import styles from "../style.module.scss";

const Info = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postSignUp = useSignUpMutation();
  const [steamId, setSteamId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [formError, setFormError] = useState({
    usernameError: {
      text: "",
      isError: false,
    },
    passwordError: {
      text: "",
      isError: false,
    },
  });
  const authType = sessionStorage.getItem("type") || "";

  useEffect(() => {
    if (authType === "steam") {
      sessionStorage.setItem("google_token", "");
      const searchParams = new URLSearchParams(window.location.search);
      setSteamId(searchParams.get("steam_id") || "");
    }
  }, []);

  useEffect(() => {
    if (username && password && recaptcha) {
      setBtnDisabled(
        isPasswordCorrect(password) && isUsernameCorrect(username),
      );
    }
  }, [username, password, recaptcha]);

  const handleInputChange = () => {
    setFormError({
      usernameError: {
        text: "",
        isError: false,
      },
      passwordError: {
        text: "",
        isError: false,
      },
    });
  };

  const handleClick = (e: any) => {
    e.preventDefault();

    if (
      isUsernameCorrect(username, setFormError) &&
      isPasswordCorrect(password, setFormError)
    ) {
      postSignUp.mutate(
        {
          username,
          password,
          type: authType,
          steam_id: steamId,
          captcha_token: recaptcha ?? "",
          google_token: sessionStorage.getItem("google_token") || "",
        },
        {
          onSuccess: (res) => {
            const auth = {
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
            };

            localStorage.setItem("auth", JSON.stringify(auth));
            dispatch(updateAuthAction(auth));
            navigate("/app/dashboard/home");
          },
        },
      );
    }
  };

  return (
    <div className={styles.info_wrapper}>
      <button className={styles.back_button} onClick={() => navigate(-1)}>
        <ArrowBackRoundedIcon />
      </button>

      <AuthBlock title="Sign Up" isInfo={true}>
        <form className={styles.content_wrapper}>
          <div className={styles.inputs_wrapper}>
            <Input
              value={username}
              placeholder="Username"
              isError={formError.usernameError.isError}
              errorMessage={formError.usernameError.text}
              onBlur={() => isUsernameCorrect(username, setFormError)}
              onChange={(e: any) => {
                handleInputChange();
                setUsername(e.target.value);
              }}
            />

            <Input
              type="password"
              value={password}
              placeholder="Password"
              isError={formError.passwordError.isError}
              errorMessage={formError.passwordError.text}
              onBlur={() => isPasswordCorrect(password, setFormError)}
              onChange={(e: any) => {
                handleInputChange();
                setPassword(e.target.value);
              }}
            />
          </div>

          <ReCAPTCHA
            theme="dark"
            onChange={(e: any) => setRecaptcha(e)}
            sitekey={process.env.REACT_APP_RECAPCHA_KEY!}
          />

          <Button
            type="submit"
            text="Sign Up"
            disabled={!btnDisabled}
            onClick={handleClick}
          />
        </form>
      </AuthBlock>
    </div>
  );
};

export default Info;
