import toast from "react-hot-toast";
import { client } from "./config/index";
import { useMutation } from "@tanstack/react-query";

export const useCreateFollowRequest = () =>
  useMutation({
    mutationFn: ({ following_id }: { following_id: string }) =>
      client.post("/v1/follow", { following_id }),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "follow-request-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "follow-request-err",
        });
      }
    },
  });

export const useUpdateFollowRequest = () =>
  useMutation({
    mutationFn: ({
      id,
      user_id,
      is_rejected,
      current_user_id,
    }: {
      id: string;
      current_user_id: string;
      user_id: string;
      is_rejected: boolean;
    }) =>
      client.put(`/v1/follow/request/${id}`, {
        is_rejected,
        current_user_id,
        user_id,
        id,
      }),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "follow-request-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "follow-request-err",
        });
      }
    },
  });

export const useDeleteFollow = () =>
  useMutation({
    mutationFn: ({
      id,
      user_id,
      is_remove_follower,
    }: {
      id?: string;
      user_id?: string;
      is_remove_follower?: boolean;
    }) => {
      const data = {
        id,
        user_id: user_id,
        is_remove_follower,
      };

      return client.delete("/v1/follow", { data });
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "delete-follow-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "delete-follow-err",
        });
      }
    },
  });

export const useDeclineFollowRequest = () =>
  useMutation({
    mutationFn: ({
      id,
      user_id,
      current_user_id,
    }: {
      id?: string;
      user_id?: string;
      current_user_id?: string;
    }) => {
      const data = {
        id,
        user_id: user_id,
        current_user_id,
      };

      return client.delete(`/v1/follow/request`, { data });
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "delete-follow-request-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "delete-follow-request-err",
        });
      }
    },
  });

export const useAcceptFollowReq = () =>
  useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.post(`/v1/follow/request/accept`, { id }),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "follow-request-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "follow-request-err",
        });
      }
    },
  });

export const getFollowers = (following_id: string) =>
  client
    .get("/v1/follow/followers", {
      params: { following_id, limit: 100, page: 1 },
    })
    .catch((err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "followers-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "followers-err",
        });
      }
    });

export const getFollowings = (follower_id: string) =>
  client
    .get("/v1/follow/following", {
      params: { follower_id, limit: 100, page: 1 },
    })
    .catch((err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "followings-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "followings-err",
        });
      }
    });

export const getFollowRequests = () =>
  client
    .get("/v1/follow/request", { params: { limit: 100, page: 1 } })
    .catch((err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "follow-requests-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "follow-requests-err",
        });
      }
    });
