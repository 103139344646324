import React from "react";
import { useDropzone } from "react-dropzone";
import defaultBanner from "../../assets/images/default-banner.png";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

interface IBanner {
  img?: string;
  onDrop?: any;
  className?: string;
  isEditMode?: boolean;
  isDashboard?: boolean;
  height: "300px" | "77px";
}

const Banner: React.FC<IBanner> = ({
  img,
  height,
  onDrop,
  className,
  isEditMode,
  isDashboard,
}) => {
  const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div
      className={`${styles.banner_image} ${className}`}
      style={{
        height: height,
        backgroundImage: `url(${img || defaultBanner})`,
      }}
    >
      {!img && isDashboard && (
        <button
          className={styles.edit_button}
          onClick={() => navigate("/app/profile-edit")}
        >
          <CameraAltRoundedIcon /> <p>Add</p>
        </button>
      )}
      {isEditMode && (
        <div className={styles.change_banner_wrapper} {...getRootProps()}>
          <input {...getInputProps()} />
          <p className={styles.change_banner}>Change Banner</p>
        </div>
      )}
    </div>
  );
};

export default Banner;
