import { useEffect, useState } from "react";
import Overall from "./Overall";
import Weapons from "./Weapons";
import LastMatch from "./LastMatch";
import Button from "../../../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserStatsMutation } from "../../../../../../services/userStats";
import styles from "./style.module.scss";

const CS2Statistics = () => {
  const navigate = useNavigate();
  const getStats = useGetUserStatsMutation();
  const { id, matchId, userId } = useParams();
  const [isError, setIsError] = useState(false);
  const [weapons, setWeapons] = useState<any>([]);
  const [topMaps, setTopMaps] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [overall, setOverall] = useState<any>(null);
  const [topWeapons, setTopWeapons] = useState<any>([]);
  const [lastMatch, setLastMatch] = useState<any>(null);

  useEffect(() => {
    if (!matchId) {
      navigate(
        `/app/user/${userId}/stats/counter-strike:globaloffensive/${process.env.REACT_APP_CSGO_ID}/overall`,
      );
    }
  }, [matchId]);

  useEffect(() => {
    if (id && userId) {
      getStats.mutate(
        {
          game_id: id,
          user_id: userId,
          type: "total-stats",
        },
        {
          onSuccess: (res: any) => {
            setIsLoading(false);
            const total_plant_ratio =
              (res.data?.data?.total_planted_bombs /
                res.data?.data?.total_rounds_played) *
              200;

            const total_defuse_ratio =
              (res.data?.data?.total_defused_bombs /
                res.data?.data?.total_rounds_played) *
              200;

            setOverall({
              total_plant_ratio,
              total_defuse_ratio,
              total_kills: res.data.data?.total_kills,
              total_deaths: res.data?.data?.total_deaths,
              defuse_ratio: res.data?.data?.defuse_ratio,
              total_matches_won: res.data?.data?.total_matches_won,
              total_defused_bombs: res.data?.data?.total_defused_bombs,
              total_planted_bombs: res.data?.data?.total_planted_bombs,
              total_kills_headshot: res.data?.data?.total_kills_headshot,
              head_shot_percentage: res.data?.data?.head_shot_percentage,
              total_contribution_score:
                res.data?.data?.total_contribution_score,
            });
            setTopMaps(
              [...res.data.data.top_maps.slice(0, 5)].map((e: any) => [
                e?.map_name,
                e?.total_rounds,
                e?.success_rate,
              ]),
            );
            setTopWeapons(
              [...res.data.data.top_weapons.slice(0, 5)].map((e: any) => [
                e.weapon_name,
                e.kills,
                e.accuracy,
              ]),
            );
            setLastMatch({
              demage_per_round: res.data.data.demage_per_round,
              last_match_t_wins: res.data.data.last_match_t_wins,
              last_match_wins: res.data.data.last_match_wins,
              last_match_ct_wins: res.data.data.last_match_ct_wins,
              last_match_mvps: res.data.data.last_match_mvps,
              last_match_kills: res.data.data.last_match_kills,
              last_match_deaths: res.data.data.last_match_deaths,
              last_match_favweapon_kills:
                res.data.data.last_match_favweapon_kills,
              last_match_favweapon_shots:
                res.data.data.last_match_favweapon_shots,
              last_match_favweapon_hits:
                res.data.data.last_match_favweapon_hits,
              last_match_favorite_weapon_accuracy:
                res.data.data.last_match_favorite_weapon_accuracy,
              is_last_match_won: res.data.data.is_last_match_won,
              last_match_damage: res.data.data.last_match_damage,
            });
            setWeapons(
              res.data.data.top_weapons.map((e: any) => Object.values(e)),
            );
          },
          onError: () => {
            setIsLoading(false);
            setIsError(true);
          },
        },
      );
    }
  }, [id, userId]);

  return (
    <div className={styles.cs2_stats_wrapper}>
      <div className={styles.tab_bar_wrapper}>
        <div className={styles.tab_bar}>
          <Button
            className={`${styles.tab_button} ${
              matchId === "1" || matchId === "overall" ? styles.active : ""
            }`}
            text="Overall"
            onClick={() =>
              navigate(
                `/app/user/${userId}/stats/counter-strike:globaloffensive/${process.env.REACT_APP_CSGO_ID}/overall`,
              )
            }
          />
          <Button
            className={`${styles.tab_button} ${
              matchId === "last-match" ? styles.active : ""
            }`}
            text="Last Match"
            onClick={() =>
              navigate(
                `/app/user/${userId}/stats/counter-strike:globaloffensive/${process.env.REACT_APP_CSGO_ID}/last-match`,
              )
            }
          />
          <Button
            className={`${styles.tab_button} ${
              matchId === "weapons" ? styles.active : ""
            }`}
            text="Weapons"
            onClick={() =>
              navigate(
                `/app/user/${userId}/stats/counter-strike:globaloffensive/${process.env.REACT_APP_CSGO_ID}/weapons`,
              )
            }
          />
        </div>
      </div>

      {isLoading ? (
        <p className={styles.loading}>Loading...</p>
      ) : isError ? (
        <p className={styles.loading}>
          The user&#39;s statistics are currently unavailable. Please try again
          later
        </p>
      ) : (
        <>
          {matchId === "overall" || matchId === "1" ? (
            <Overall
              isError={isError}
              overallData={overall}
              topMapsData={topMaps}
              setIsError={setIsError}
              topWeaponsData={topWeapons}
            />
          ) : matchId === "last-match" ? (
            <LastMatch lastMatch={lastMatch} />
          ) : matchId === "weapons" ? (
            <Weapons weaponsAll={weapons} />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default CS2Statistics;
