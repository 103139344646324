import { useEffect, useState } from "react";

import GameCard from "../../components/GameCard";
import GameTableRow from "../../components/TableRow/GameTableRow";
import { Link } from "react-router-dom";
import styles from "./gameContainer.module.scss";
import { useGetAllGamesQuery } from "../../services/game";
import { useMyGamesQuery } from "../../services/myGames";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";

const GamesContainer = () => {
  const gamesIds = [
    "3a82f6ec-61f3-4197-8fe8-926eb197b6a9",
    "ff335f10-12d8-4ca7-9c9e-e1603ec30d90",
    "dc174d2b-c984-4ec6-8c9d-09763aea632c",
    "1ec63bf3-5e13-4466-b6c5-097b3d9ca2fd",
  ];
  const [allGames, setAllGames] = useState<any>([]);
  const [coreGames, setCoreGames] = useState<any>([]);
  const user = useSelector((state: any) => state.user);
  const { data: getAllGames, isLoading } = useGetAllGamesQuery({ page: 1 });

  const [userCoreGamesIds, setUserCoreGamesIds] = useState<any>([]);

  const { data: games } = useMyGamesQuery({
    params: {
      id: user?.id,
      steam_id: user?.connected_platforms?.steam?.steam_id,
    },
    querySettings: {
      enabled: !!user?.id,
    },
  });

  useEffect(() => {
    if (games?.data.core_games?.length) {
      setCoreGames(games?.data?.core_games);

      games?.data?.core_games?.forEach((e: any) => {
        setUserCoreGamesIds((prev: any) => [...prev, e?.game_id]);
      });
    }
  }, [games]);

  useEffect(() => {
    if (getAllGames?.data?.games?.length) {
      setAllGames(getAllGames.data?.games);
    }
  }, [getAllGames]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader text="Loading Games..." />
        </div>
      ) : (
        <div className={styles.topContainer}>
          {allGames?.length || coreGames?.length ? (
            allGames?.map((game: any) =>
              gamesIds?.includes(game?.id) ? (
                <Link key={game?.id} to={`/app/games/game/${game?.id}`}>
                  <GameCard
                    playtime_2weeks={
                      userCoreGamesIds?.indexOf(game?.id) >= 0
                        ? coreGames[userCoreGamesIds.indexOf(game?.id)]
                            ?.game_data?.playtime_2weeks || 0
                        : -1
                    }
                    playtime_forever={
                      userCoreGamesIds?.indexOf(game?.id) >= 0
                        ? coreGames[userCoreGamesIds.indexOf(game?.id)]
                            ?.game_data?.playtime_forever || 0
                        : -1
                    }
                    game_title={game?.title}
                    bgImg={game?.logo}
                  />
                </Link>
              ) : (
                <></>
              ),
            )
          ) : (
            <p>HELLO</p>
          )}
        </div>
      )}

      <div className={styles.bottomContainer}>
        {allGames?.length &&
          allGames?.map(
            (game: any, i: number) =>
              !gamesIds?.includes(game?.id) && (
                <GameTableRow
                  key={i}
                  rowNumber={i + 1}
                  img={game?.logo}
                  name={game?.title}
                  totalTime={0}
                  lastTwoWeeks={0}
                />
              ),
          )}
      </div>
      <div className={styles.disclaimer_wrapper}>
        <p className={styles.disclaimer_title}>Disclaimer</p>
        <p className={styles.disclaimer_text}>
          GameDrive is not endorsed by Riot Games and does not reflect the views
          or opinions of Riot Games or anyone officially involved in producing
          or managing Riot Games properties. Riot Games and all associated
          properties are trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div>
    </div>
  );
};

export default GamesContainer;
