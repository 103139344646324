import React from "react";
import { IComment } from "../types";
import { Markup } from "interweave";
import ProfilePhoto from "../ProfilePhoto";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { utcToLocalTime } from "../../helpers/utcToLocalTime";
import { useImage } from "../../services/getImages";

const Comment: React.FC<IComment> = ({
  // id,
  date,
  user_id,
  content,
  profileImg,
  profileName,
}) => {
  const user = useSelector((state: any) => state.user);
  const { data: profileImgST } = useImage(profileImg);
  return (
    <div className={styles.comment_wrapper}>
      <div className={styles.comment_header}>
        <Link
          to={
            user_id === user?.id
              ? "/app/dashboard/home"
              : `/app/user/${user_id}/posts`
          }
        >
          <ProfilePhoto
            rounded={true}
            size="40px"
            img={profileImg ? profileImgST : ""}
          />
        </Link>
        <Link
          to={
            user_id === user?.id
              ? "/app/dashboard/home"
              : `/app/user/${user_id}/posts`
          }
        >
          <p className={styles.profile_name}>{profileName}</p>
        </Link>
        <p className={styles.date}>{utcToLocalTime(date)}</p>
      </div>

      <div className={styles.comment_content}>
        <Markup content={content} />
      </div>
    </div>
  );
};

export default Comment;
