import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAccountConnect } from "../types";
import Radio from "../../../../components/Radio";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import AccountConnectModal from "../../../../components/Modal";
import { updateUserAction } from "../../../../store/userReducer";
import {
  getByUsername,
  useGetRiotMutation,
  useUpdateUserMutation,
} from "../../../../services/user";
import styles from "./style.module.scss";
import { updateNotificationAction } from "../../../../store/notificationReducer";
import { useCreateUserGameMutation } from "../../../../services/userGames";
import toast from "react-hot-toast";

const RiotConnect: React.FC<IAccountConnect> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const connectRiot = useGetRiotMutation();
  const updateUser = useUpdateUserMutation();
  const [isVerify, setIsVerify] = useState(false);
  const createUserGame = useCreateUserGameMutation();
  const [isDisabled, setIsDisabled] = useState(true);
  const user = useSelector((state: any) => state.user);
  const notification = useSelector((state: any) => state.notification);
  const [accountInfo, setAccountInfo] = useState({
    game_id: "",
    level: "",
    server: "",
    game_name: "",
    tag_line: "",
  });

  useEffect(() => {
    if (
      accountInfo.game_id &&
      accountInfo.server &&
      accountInfo.game_name &&
      accountInfo.tag_line
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [accountInfo.game_id, accountInfo.server, accountInfo.game_name]);

  useEffect(() => {
    if (isVerify && accountInfo.level) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [accountInfo.level, isVerify]);

  const handleNext = () => {
    setIsDisabled(true);
    connectRiot.mutate(
      {
        game_id: accountInfo.game_id,
        level: Number(accountInfo.level),
        server: accountInfo.server.toLowerCase(),
        game_name: accountInfo.game_name,
        tag_line: accountInfo.tag_line,
      },
      {
        onSuccess: () => {
          setIsDisabled(false);
          setIsVerify(true);
        },
        onError: () => {
          setIsDisabled(false);
        },
      },
    );
  };

  const handleBack = () => setIsVerify(false);

  const onClose = () => {
    handleBack();
    handleClose();
  };

  const handleChange = (e: any) => {
    if (e.target.value === "0") {
      setAccountInfo((prev) => ({ ...prev, level: "" }));
    } else {
      setAccountInfo((prev) => ({ ...prev, level: e.target.value }));
    }
  };

  const handleVerify = () => {
    setIsDisabled(true);
    connectRiot.mutate(
      {
        game_id: accountInfo.game_id,
        level: Number(accountInfo.level),
        server: accountInfo.server.toLowerCase(),
        game_name: accountInfo.game_name,
        tag_line: accountInfo.tag_line,
      },
      {
        onSuccess: (res: any) => {
          getByUsername({ username: res.data.id, usertype: "riot" }).catch(
            (err: any) => {
              if (err?.response?.status === 404) {
                updateUser.mutate(
                  {
                    ...user,
                    riot_id: res?.data?.id,
                    connected_platforms: {
                      ...user.connected_platforms,
                      riot: {
                        ...res.data,
                        game_id: accountInfo.game_id,
                        server: accountInfo.server.toLowerCase(),
                        game_name: accountInfo.game_name,
                      },
                    },
                  },
                  {
                    onSuccess: (updatedUser: any) => {
                      createUserGame.mutate(
                        {
                          user_id: user.id,
                          game_data: res.data,
                          game_id: accountInfo.game_id,
                        },
                        {
                          onSuccess: () => {
                            setIsDisabled(false);
                            dispatch(
                              updateUserAction({
                                ...updatedUser.data,
                              }),
                            );
                            dispatch(
                              updateNotificationAction({
                                ...notification,
                                riot: true,
                              }),
                            );
                            onClose();
                          },
                          onError: (err: any) => {
                            console.log("ERRCG", err);
                          },
                        },
                      );
                    },
                    onError: () => {
                      setIsDisabled(false);
                    },
                  },
                );
              } else {
                setIsDisabled(false);
                if (err.response?.data?.validations?.length) {
                  if (
                    err.response?.data?.validations[0]?.error ===
                    "riot account already exists"
                  ) {
                    toast.error(
                      "This Summoner name has already been connected to another account on this platform, please try again. If you believe that this has happened by mistake, please reach out to our support team, support@gamedrivellc.com with more information about the issue.",
                      {
                        id: "riot-connect-err",
                        duration: 15000,
                      },
                    );
                  } else {
                    toast.error(
                      "Error: " + err.response?.data?.validations[0]?.error,
                      {
                        id: "riot-connect-err",
                      },
                    );
                  }
                } else {
                  toast.error("Error: " + err.response?.data?.error_message, {
                    id: "riot-connect-err",
                  });
                }
              }
            },
          );
        },
      },
    );
  };

  const options = [
    {
      id: 8,
      title: "NA1",
    },
    {
      id: 1,
      title: "BR1",
    },
    {
      id: 2,
      title: "EUN1",
    },
    {
      id: 3,
      title: "EUW1",
    },
    {
      id: 4,
      title: "JP1",
    },
    {
      id: 5,
      title: "KR",
    },
    {
      id: 6,
      title: "LA1",
    },
    {
      id: 7,
      title: "LA2",
    },
    {
      id: 9,
      title: "OC1",
    },
    {
      id: 10,
      title: "PH2",
    },
    {
      id: 11,
      title: "RU",
    },
    {
      id: 12,
      title: "SG2",
    },
    {
      id: 13,
      title: "TH2",
    },
    {
      id: 14,
      title: "TRI",
    },
    {
      id: 15,
      title: "TW2",
    },
    {
      id: 16,
      title: "VN2",
    },

    {
      id: 17,
      title: "TR1",
    },
  ];

  return (
    <AccountConnectModal
      open={open}
      close={onClose}
      withBackBtn={isVerify}
      withFooter={!isVerify}
      handleBack={handleBack}
      width={!isVerify ? "500px" : "400px"}
      title={
        !isVerify
          ? "Connect your RIOT account"
          : "Please answer the below question to verify your account"
      }
    >
      {!isVerify ? (
        <div className={styles.modal_content_wrapper}>
          <div className={styles.content_form_wrapper}>
            <div className={styles.radios_wrapper}>
              <Radio
                name="game_id"
                label="League of legends"
                value={process.env.REACT_APP_L_O_L_ID || ""}
                checked={accountInfo.game_id === process.env.REACT_APP_L_O_L_ID}
                onClick={(e: any) =>
                  setAccountInfo((prev) => ({
                    ...prev,
                    game_id: e,
                  }))
                }
              />

              <Radio
                name="game_id"
                label="Valorant"
                value={process.env.REACT_APP_VALORANT_ID || ""}
                checked={
                  accountInfo.game_id === process.env.REACT_APP_VALORANT_ID
                }
                onClick={(e: any) =>
                  setAccountInfo((prev) => ({
                    ...prev,
                    game_id: e,
                  }))
                }
              />
            </div>

            <div className={styles.inputs_wrapper}>
              <Input
                placeholder="Game Name"
                value={accountInfo.game_name}
                className={styles.summoner_input}
                onChange={(e: any) =>
                  setAccountInfo((prev) => ({
                    ...prev,
                    game_name: e.target.value,
                  }))
                }
              />

              <Input
                placeholder="Tag Line"
                value={accountInfo.tag_line}
                className={styles.tag_line}
                inHash
                onChange={(e: any) =>
                  setAccountInfo((prev) => ({
                    ...prev,
                    tag_line: e.target.value,
                  }))
                }
              />

              <Dropdown
                label="Server"
                options={options}
                className={styles.server_dropdown}
                onSelect={(e: any) =>
                  setAccountInfo((prev) => ({ ...prev, server: e }))
                }
              />
            </div>
          </div>

          <Button
            disabled={isDisabled}
            text="Next"
            onClick={handleNext}
          ></Button>
        </div>
      ) : (
        <div className={`${styles.modal_content_wrapper} ${styles.no_border}`}>
          <div className={styles.content_form_wrapper}>
            <Input
              type="number"
              onChange={handleChange}
              value={accountInfo.level}
              className={styles.summoner_input}
              placeholder="Your summoner level in League of Legends"
            />
          </div>

          <Button
            disabled={isDisabled}
            text="Verify"
            onClick={handleVerify}
          ></Button>
        </div>
      )}
    </AccountConnectModal>
  );
};

export default RiotConnect;
