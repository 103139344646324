import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Button from "../../../../../../../components/Button";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import styles from "./style.module.scss";
import { useImage } from "../../../../../../../services/getImages";

interface ILastMatch {
  lastMatch: {
    last_match_mvps: number;
    last_match_wins: number;
    demage_per_round: number;
    last_match_kills: number;
    is_last_match_won: string;
    last_match_damage: number;
    last_match_deaths: number;
    last_match_t_wins: number;
    last_match_ct_wins: number;
    last_match_favweapon_hits: number;
    last_match_favweapon_kills: number;
    last_match_favweapon_shots: number;
    last_match_favorite_weapon_accuracy: number;
  };
}

const LastMatch: React.FC<ILastMatch> = ({ lastMatch }) => {
  const { data: gunImage } = useImage("m4-a1");
  return (
    <div className={styles.last_match_wrapper}>
      <div className={styles.results_wrapper}>
        <div className={styles.match_result_wrapper}>
          <p className={styles.stats_title}>Match Result: </p>
          {lastMatch?.is_last_match_won === "LOSS" ? (
            <span className={styles.lost}>Lost</span>
          ) : (
            <span className={styles.won}>Won</span>
          )}
        </div>

        <div className={styles.t_ct_wins_wrapper}>
          <div>
            <p className={styles.stats_title}>T-Wins</p>
            <p className={styles.stats_text}>
              {lastMatch?.last_match_t_wins || 0}
            </p>
          </div>
          <div>
            <p className={styles.stats_title}>CT-Wins</p>
            <p className={styles.stats_text}>
              {lastMatch?.last_match_ct_wins || 0}
            </p>
          </div>
        </div>

        <div>
          <p className={styles.stats_title}>MVPs</p>
          <p className={styles.stats_text}>
            {Math.round(lastMatch?.last_match_mvps)}{" "}
            <span className={styles.stats_percentage}>
              (
              {Math.round(
                (lastMatch?.last_match_mvps / lastMatch?.last_match_wins) *
                  100 || 0,
              )}
              %)
            </span>
          </p>
        </div>
      </div>

      <div className={styles.middle_column}>
        <div className={styles.inner_top_wrapper}>
          <div className={styles.text_wrapper}>
            <div className={styles.stats_wrapper}>
              <p className={styles.stats_title}>Kills</p>
              <p className={styles.stats_text}>{lastMatch?.last_match_kills}</p>
            </div>
            <div className={styles.stats_wrapper}>
              <p className={styles.stats_title}>Deaths</p>
              <p className={styles.stats_text}>
                {lastMatch?.last_match_deaths}
              </p>
            </div>
          </div>

          <div className={styles.bar_chart}>
            <div
              style={{
                width: `${
                  (lastMatch?.last_match_kills * 100) /
                  (lastMatch?.last_match_kills + lastMatch?.last_match_deaths)
                }%`,
              }}
              className={styles.chart_kills}
            ></div>
            <div
              style={{
                width: `${
                  (lastMatch?.last_match_deaths * 100) /
                  (lastMatch?.last_match_deaths + lastMatch?.last_match_kills)
                }%`,
              }}
              className={styles.chart_deaths}
            ></div>
          </div>
        </div>
        <div className={styles.inner_bottom_wrapper}>
          <div className={styles.fav_gun_wrapper}>
            <p className={styles.stats_title}>Favorite Gun</p>
            <div className={styles.gun_image_wrapper}>
              <img
                loading="lazy"
                alt="Gun Photo"
                className={styles.gun_image}
                src={gunImage}
              />
            </div>
          </div>

          <Tooltip
            arrow
            placement="bottom"
            classes={{
              tooltip: styles.tooltip_wrapper,
              arrow: styles.tooltip_arrow,
            }}
            title={
              <ul className={styles.tooltip_list}>
                <li className={styles.list_item}>
                  <p className={styles.tooltip_stats_title}>Kills:</p>
                  <p className={styles.tooltip_stats_text}>
                    {lastMatch?.last_match_favweapon_kills || 0}
                  </p>
                </li>
                <li className={styles.list_item}>
                  <p className={styles.tooltip_stats_title}>Shots:</p>
                  <p className={styles.tooltip_stats_text}>
                    {lastMatch?.last_match_favweapon_shots || 0}
                  </p>
                </li>
                <li className={styles.list_item}>
                  <p className={styles.tooltip_stats_title}>Hits:</p>
                  <p className={styles.tooltip_stats_text}>
                    {lastMatch?.last_match_favweapon_hits || 0}
                  </p>
                </li>
                <li className={styles.list_item}>
                  <p className={styles.tooltip_stats_title}>Accuracy:</p>
                  <p className={styles.tooltip_stats_text}>
                    {Math.round(
                      lastMatch?.last_match_favorite_weapon_accuracy || 0,
                    )}
                    %
                  </p>
                </li>
              </ul>
            }
          >
            <div>
              <Button
                className={styles.chart_icon}
                icon={<LeaderboardRoundedIcon style={{ color: "white" }} />}
              />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className={styles.average_damage_wrapper}>
        <div>
          <p className={styles.stats_title}>Avg. Damage per Round</p>
          <p className={styles.stats_text}>
            {Math.round(lastMatch?.demage_per_round || 0)}
          </p>
        </div>
        <div className={styles.total_damage_wrapper}>
          <p className={styles.stats_title}>Total Damage</p>
          <p className={styles.stats_text_small}>
            {Math.round(lastMatch?.last_match_damage || 0)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LastMatch;
