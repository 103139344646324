import React from "react";
import { useDropzone } from "react-dropzone";
import { IDropzone } from "../types";
import styles from "./style.module.scss";
import Button from "../Button";

const Dropzone: React.FC<IDropzone> = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
  });

  return (
    <div className={styles.dropzone}>
      <input {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <div className={styles.dropzone_content}>
            <div className={styles.dropzone_text_wrapper}>
              <p className={styles.dropzone_title}>
                Select a file or drag and drop here
              </p>
              <p className={styles.dropzone_text}>
                JPG, JPEG or PNG files are accepted
              </p>
            </div>
            <Button
              text="Select file"
              className={styles.dropzone_button}
              {...getRootProps()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
