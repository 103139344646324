import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Column from "../../../components/CS2Column";
import CS2Table from "../../../components/CS2Table";
import { Headshot } from "../../../../../assets/icons/Headshot";
import Button from "../../../../../components/Button";
import { useGetUserStatsMutation } from "../../../../../services/userStats";
import styles from "./style.module.scss";

interface IOverall {
  overallData: {
    total_kills: number;
    total_deaths: number;
    defuse_ratio: number;
    total_plant_ratio: number;
    total_matches_won: number;
    total_defuse_ratio: number;
    total_defused_bombs: number;
    total_planted_bombs: number;
    total_kills_headshot: number;
    head_shot_percentage: number;
    total_contribution_score: number;
  };
  topMapsData: (string | number)[][];
  topWeaponsData: (string | number)[][];
}

const Overall: React.FC<IOverall> = ({
  overallData,
  topMapsData,
  topWeaponsData,
}) => {
  const { id } = useParams();
  const getStats = useGetUserStatsMutation();
  const [isError, setIsError] = useState(false);
  const [topMaps, setTopMaps] = useState<any>([]);
  const [isWeekly, setIsWeekly] = useState(false);
  const [overall, setOverall] = useState<any>(null);
  const user = useSelector((state: any) => state.user);
  const [topWeapons, setTopWeapons] = useState<any>([]);

  useEffect(() => {
    if (id && user.id && isWeekly) {
      getStats.mutate(
        {
          game_id: id,
          user_id: user.id,
          type: "weekly-stats",
        },
        {
          onSuccess: (res: any) => {
            const total_plant_ratio =
              (res.data?.data?.total_planted_bombs /
                res.data?.data?.total_rounds_played) *
              200;

            const total_defuse_ratio =
              (res.data?.data?.total_defused_bombs /
                res.data?.data?.total_rounds_played) *
              200;

            setOverall({
              total_plant_ratio,
              total_defuse_ratio,
              total_kills: res?.data?.data?.total_kills,
              total_deaths: res?.data?.data?.total_deaths,
              defuse_ratio: res?.data?.data?.defuse_ratio,
              total_matches_won: res?.data?.data?.total_matches_won,
              total_defused_bombs: res?.data?.data?.total_defused_bombs,
              total_planted_bombs: res?.data?.data?.total_planted_bombs,
              total_kills_headshot: res?.data?.data?.total_kills_headshot,
              head_shot_percentage: res?.data?.data?.head_shot_percentage,
              total_contribution_score:
                res?.data?.data?.total_contribution_score,
            });
            setTopMaps(
              [...res.data.data.top_maps.slice(0, 5)].map((e: any) => [
                e.map_name,
                e.total_rounds,
                e.success_rate,
              ]),
            );
            setTopWeapons(
              [...res.data.data.top_weapons.slice(0, 5)].map((e: any) => [
                e.weapon_name,
                e.kills,
                e.accuracy,
              ]),
            );
            setIsError(false);
          },
          onError: () => {
            setIsError(true);
          },
        },
      );
    }
  }, [id, user.id, isWeekly]);

  useEffect(() => {
    if (!isWeekly) {
      setIsError(false);
      setOverall(overallData);
      setTopMaps(topMapsData);
      setTopWeapons(topWeaponsData);
    }
  }, [overallData, topMapsData, topWeaponsData, isWeekly]);

  return (
    <div className={styles.overall_wrapper}>
      <div className={styles.overall_header_wrapper}>
        <Button
          text="Lifetime"
          onClick={() => setIsWeekly(false)}
          className={`${styles.header_button} ${!isWeekly && styles.active}`}
        />
        <Button
          text="Weekly"
          onClick={() => setIsWeekly(true)}
          className={`${styles.header_button} ${isWeekly && styles.active}`}
        />
      </div>

      <div className={styles.overall_body_wrapper}>
        {isError ? (
          <p className={styles.error_text}>
            Your weekly statistics are currently unavailable. Weekly statistics
            are updated every Sunday & begin tracking on the first Monday
            following your sign-up date.
          </p>
        ) : (
          <>
            <div className={styles.overall_inner_wrapper}>
              <Column
                topHeading="Matches Won"
                topStats={overall?.total_matches_won || 0}
                bottomHeading="Avg. Contribution Score"
                bottomStats={overall?.total_contribution_score || 0}
              />
              <div className={styles.middle_column}>
                <div className={styles.inner_top_wrapper}>
                  <div className={styles.text_wrapper}>
                    <div className={styles.stats_wrapper}>
                      <p className={styles.stats_title}>Kills</p>
                      <p className={styles.stats_text}>
                        {overall?.total_kills || 0}
                      </p>
                    </div>
                    <div className={styles.stats_wrapper}>
                      <p className={styles.stats_title}>Deaths</p>
                      <p className={styles.stats_text}>
                        {overall?.total_deaths || 0}
                      </p>
                    </div>
                  </div>

                  <div className={styles.bar_chart}>
                    <div
                      style={{
                        width: `${
                          (overall?.total_kills * 100) /
                            (overall?.total_kills + overall?.total_deaths) || 0
                        }%`,
                      }}
                      className={styles.chart_kills}
                    ></div>
                    <div
                      style={{
                        width: `${
                          (overall?.total_deaths * 100) /
                            (overall?.total_kills + overall?.total_deaths) || 0
                        }%`,
                      }}
                      className={styles.chart_deaths}
                    ></div>
                  </div>
                </div>

                <div className={styles.headshots_wrapper}>
                  <div className={styles.inner_bottom_wrapper}>
                    <p className={styles.stats_title}>Headshot Kills</p>
                    <p className={styles.stats_text}>
                      {overall?.total_kills_headshot || 0}
                      <span className={styles.stats_percentage}>
                        ({Math.round(overall?.head_shot_percentage || 0)}%)
                      </span>
                    </p>
                  </div>

                  <Headshot />
                </div>
              </div>
              <Column
                withIcon={true}
                topHeading="Bombs Planted"
                bottomHeading="Bombs Defused"
                topStats={overall?.total_planted_bombs || 0}
                bottomStats={overall?.total_defused_bombs || 0}
                topPercentage={Math.round(overall?.total_plant_ratio)}
                bottomPercentage={Math.round(overall?.total_defuse_ratio)}
              />
            </div>

            <div className={styles.overall_inner_wrapper}>
              <CS2Table
                rows={topWeapons}
                headings={["Top Weapons", "Kills", "Accuracy"]}
              />
              <CS2Table
                rows={topMaps}
                isMapsTable={true}
                headings={["Top Maps", "Matches", "Success rate"]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Overall;
