import { IRadio } from "../types";
import React from "react";
import styles from "./style.module.scss";

const Radio: React.FC<IRadio> = ({
  label,
  name,
  value,
  onClick,
  checked,
  inputCustomStyle,
  labelCustomStyle,
}) => {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <div className={styles.radio_wrapper}>
      <input
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={handleClick}
        className={styles.radio_input}
        style={inputCustomStyle}
      />
      <label
        className={styles.label}
        style={labelCustomStyle}
        onClick={handleClick}
      >
        {label}
      </label>
    </div>
  );
};

export default Radio;
