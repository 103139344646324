export const replaceCustomTags = (contents: string): string => {
  if (!contents) return "";

  return contents
    .replaceAll(
      /\[([A-Z ]+)\]/g,
      (_, tag) => `<strong>[ ${tag.trim()} ]</strong>`,
    )
    .replaceAll(/\[i\](.+?)\[\/i\]/g, "<em>$1</em>")
    .replaceAll(
      /\[list\](.+?)\[\/list\]/gs,
      (_, listContent) =>
        `<ul>${listContent
          .replaceAll(/\[\*\](.+?)(?=(?:\[\*\]|$))/gs, "<li>$1</li>")
          .trim()}</ul>`,
    )
    .replaceAll(
      /\[url=(.+?)\](?:\[img\](.+?)\[\/img\])\[\/url\]/g,
      (_, url, imgSrc) =>
        `<a href="${url}" target="_blank"><img src="${imgSrc}" alt="Link image" /></a>`,
    )
    .replaceAll(
      /\[url=(.+?)\](.+?)\[\/url\]/g,
      (_, url, text) => `<a href="${url}" target="_blank">${text}</a>`,
    )
    .replaceAll(/\[img\](.+?)\[\/img\]/g, '<img src="$1" alt="Image" />')
    .replaceAll(/\[[^\]]+?\]/g, "");
};

export const extractAndCleanImageURL = (contents: string) => {
  const regex = /\[img\]\{STEAM_CLAN_(LOC_)?IMAGE\}(.+?)\[\/img\]/g;
  const match = contents.match(regex);

  const imageUrl = match
    ? `https://clan.akamai.steamstatic.com/images${regex.exec(contents)?.[2]}`
    : "";

  return {
    imageUrl,
    cleanedText: contents.replace(regex, ""),
  };
};

export const extractAndRemoveImgTag = (contents: string) => {
  const imgRegex = /<img.*?src=["'](.+?)["'].*?>/g;
  const match = contents.match(imgRegex);

  return {
    srcValue: match ? (match[0].match(/src=["'](.+?)["']/)?.[1] ?? "") : "",
    pContent: contents.replace(imgRegex, ""),
  };
};
