import { useEffect, useState } from "react";

const Riot = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    fetch("https://test.cdn.delever.uz/game-drive/riot.txt").then((res: any) =>
      res.text().then((data: any) => setText(data)),
    );
  }, []);

  return (
    <p
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
        color: "white",
      }}
    >
      {text}
    </p>
  );
};

export default Riot;
