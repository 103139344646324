import { useDispatch, useSelector } from "react-redux";

import Post from "../../../../components/Post";
import { getAllPostsAction } from "../../../../store/postReducer";
import { getAllUsersPostsQuery } from "../../../../services/post";
import styles from "./style.module.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const UserPosts = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const posts = useSelector((state: any) => state.post);
  const visibility = useSelector((state: any) => state.visibility);
  const { refetch, fetchNextPage: fetchNextPosts } = getAllUsersPostsQuery({
    user_id: userId,
    limit: 10,
  });

  useEffect(() => {
    if (userId && !visibility?.is_private) {
      refetch().then((res: any) =>
        dispatch(
          getAllPostsAction(
            res.data?.pages?.flatMap((page: any) => page?.data?.posts),
          ),
        ),
      );
    }
  }, [userId, visibility?.is_private]);

  return (
    <div className={styles.home_wrapper}>
      <InfiniteScroll
        dataLength={posts?.length}
        next={fetchNextPosts}
        hasMore={true}
        loader={<></>}
        endMessage={<></>}
      >
        {posts?.length && posts[0] ? (
          posts?.map((post: any, i: number) => {
            return <Post post={post} key={`${post?.id}${i}`} />;
          })
        ) : (
          <p className={styles.no_posts}>There are no posts yet</p>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default UserPosts;
