export default function ChatIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.0757 17.0392C4.47423 17.0392 3.88662 16.8857 3.35408 16.5866C3.08008 16.4455 2.97395 16.0925 3.13764 15.8269C3.6815 14.9579 3.87789 13.8955 3.62888 12.8965C3.37079 11.8607 2.99734 10.9884 3.00001 9.89199C3.00928 6.04523 6.21109 2.92669 10.0539 3.00131C13.7657 3.07607 16.8246 6.19622 16.8247 9.90892C16.8247 14.7578 11.7431 18.1621 7.25689 16.2896C6.63854 16.775 5.86131 17.0392 5.0757 17.0392ZM4.34485 15.8553C5.18994 16.1178 6.14039 15.9081 6.79207 15.2781C6.95264 15.1228 7.19282 15.0837 7.39429 15.1801C11.2351 17.0164 15.7554 14.1381 15.7554 9.90892C15.7554 6.75304 13.188 4.1339 10.0324 4.07036C6.78039 4.00576 4.07713 6.63957 4.06929 9.89457C4.06675 10.936 4.47307 11.7683 4.69513 12.7548C4.92908 13.7945 4.80584 14.8939 4.34485 15.8553Z"
        fill="white"
      />
      <path
        d="M18.9247 20.9992C18.1392 20.9992 17.3619 20.735 16.7436 20.2498C14.3578 21.2455 11.5199 20.792 9.56368 19.1027C9.3402 18.9097 9.31552 18.5721 9.50848 18.3486C9.70148 18.1251 10.0391 18.1003 10.2625 18.2934C11.9975 19.7917 14.5384 20.1288 16.6062 19.1402C16.8076 19.0438 17.0478 19.0829 17.2084 19.2382C17.8601 19.8682 18.8106 20.078 19.6556 19.8154C19.1118 18.6811 19.0365 17.3816 19.4579 16.1786C19.4617 16.1678 19.4658 16.1571 19.4703 16.1465C19.7781 15.421 19.9332 14.6498 19.9312 13.8547C19.9279 12.4527 19.4615 11.1558 18.5824 10.1041C18.3931 9.87749 18.4233 9.54035 18.6498 9.351C18.8763 9.16161 19.2135 9.19181 19.4029 9.41837C20.4292 10.6463 20.9966 12.2208 21.0005 13.8521C21.0028 14.7864 20.8215 15.6931 20.4617 16.5475C20.0838 17.6414 20.2576 18.8127 20.8628 19.7869C21.0266 20.0525 20.9204 20.4056 20.6463 20.5468C20.1139 20.8457 19.5261 20.9992 18.9247 20.9992Z"
        fill="white"
      />
      <path
        d="M9.91226 10.779C10.3795 10.779 10.7582 10.4004 10.7582 9.93342C10.7582 9.46645 10.3795 9.08789 9.91226 9.08789C9.44504 9.08789 9.06628 9.46645 9.06628 9.93342C9.06628 10.4004 9.44504 10.779 9.91226 10.779Z"
        fill="white"
      />
      <path
        d="M6.79702 10.779C7.26424 10.779 7.643 10.4004 7.643 9.93342C7.643 9.46645 7.26424 9.08789 6.79702 9.08789C6.32981 9.08789 5.95105 9.46645 5.95105 9.93342C5.95105 10.4004 6.32981 10.779 6.79702 10.779Z"
        fill="white"
      />
      <path
        d="M13.03 10.779C13.4972 10.779 13.876 10.4004 13.876 9.93342C13.876 9.46645 13.4972 9.08789 13.03 9.08789C12.5628 9.08789 12.184 9.46645 12.184 9.93342C12.184 10.4004 12.5628 10.779 13.03 10.779Z"
        fill="white"
      />
    </svg>
  );
}
