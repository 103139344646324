export const Google = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_604_4497)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3101 10C4.3101 9.35048 4.41791 8.72766 4.61057 8.1436L1.24025 5.57001C0.583379 6.9036 0.213379 8.40641 0.213379 10C0.213379 11.5924 0.583066 13.0941 1.23885 14.4269L4.60729 11.8483C4.4165 11.2669 4.3101 10.6464 4.3101 10Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2276 4.09094C11.6387 4.09094 12.9132 4.59094 13.9146 5.40906L16.8277 2.5C15.0526 0.954531 12.7766 0 10.2276 0C6.27008 0 2.86883 2.26312 1.24023 5.57L4.61039 8.14359C5.38695 5.78641 7.60055 4.09094 10.2276 4.09094Z"
          fill="#EA4335"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2276 15.9091C7.6007 15.9091 5.38711 14.2136 4.61055 11.8564L1.24023 14.4295C2.86883 17.7369 6.27008 20 10.2276 20C12.6701 20 15.0021 19.1327 16.7523 17.5077L13.5532 15.0345C12.6505 15.6031 11.5138 15.9091 10.2276 15.9091Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7867 9.99999C19.7867 9.40906 19.6956 8.77265 19.559 8.18187H10.2278V12.0455H15.599C15.3304 13.3628 14.5995 14.3755 13.5534 15.0345L16.7525 17.5077C18.5909 15.8014 19.7867 13.2595 19.7867 9.99999Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_604_4497">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
