import { Box, Divider, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import React, { useEffect } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

type expandMoreProps = {
  options: {
    canManage?: boolean;
    id: string;
    name: string;
    onClick: () => void;
  }[];
  isExpandOpen?: {
    status: string;
  };
  setIsExpandOpen?: (value: { status: string }) => void;
};

export default function ExpandMore({
  options,
  isExpandOpen,
  setIsExpandOpen,
}: expandMoreProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isExpandOpen?.status == "close") {
      handleClose();
      setIsExpandOpen &&
        setIsExpandOpen({
          status: "default",
        });
    }
  }, [isExpandOpen]);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon
          sx={{
            color: "#8C8D94",
            cursor: "pointer",
            "&:hover": {
              color: "white",
            },
          }}
        />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "#1D1D23",
            color: "white",
            borderRadius: "8px",
          },
        }}
      >
        {options.map((option, i) => {
          if (!option?.canManage) return;

          return (
            <Stack key={i}>
              <MenuItem
                key={option.id}
                onClick={option.onClick}
                sx={{
                  fontSize: "14px",
                  px: 2,
                  py: 0.5,
                  width: "140px",
                }}
              >
                {option.name}
              </MenuItem>
              {i !== options.length - 1 && (
                <Divider
                  sx={{
                    backgroundColor: "#2C2C34",
                    mx: 2,
                    my: 0,
                  }}
                />
              )}
            </Stack>
          );
        })}
      </Menu>
    </Box>
  );
}
