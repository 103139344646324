import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { time2TimeAgo } from "../../../../helpers/formatDate";
import styles from "./style.module.scss";

interface ILastMatch {
  match: any;
  isLOL?: boolean;
  isFirst: boolean;
  player_id: string;
}

const LastMatch: React.FC<ILastMatch> = ({
  isLOL,
  match,
  isFirst,
  player_id,
}) => {
  const { matchId } = useParams();
  const [player, setPlayer] = useState<any>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (matchId === "1" && isFirst) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [matchId]);

  useEffect(() => {
    if (match && player_id) {
      if (isLOL) {
        setPlayer(
          match?.info?.participants?.find(
            (player: any) => player_id === player?.puuid,
          ),
        );
      } else {
        setPlayer(
          match?.Players?.find(
            (player: any) => player?.account_id == player_id,
          ),
        );
      }
    }
  }, [match, player_id]);

  return (
    <div
      className={`${styles.last_match}  ${
        (matchId === match?.metadata?.matchId ||
          matchId == match?.match_id ||
          isActive) &&
        styles.last_match_active
      }`}
    >
      <div className={styles.last_match_text_wrapper}>
        <p className={styles.hero_name}>
          {isLOL ? player?.championName : player?.hero_details?.displayName}
        </p>
        <p className={styles.secondary_text}>
          {isLOL && player?.teamId === 100
            ? "Red side"
            : player?.teamId === 200
            ? "Green side"
            : match?.is_radiant
            ? "Radiant"
            : "Dire"}
        </p>
      </div>

      <div className={styles.last_match_text_wrapper}>
        {match?.is_match_won || player?.win ? (
          <p className={styles.won_match}>Won Match</p>
        ) : (
          <p className={styles.lost_match}>Lost Match</p>
        )}
        <p className={styles.secondary_text}>
          {match?.info?.gameEndTimestamp || match?.start_time
            ? time2TimeAgo(
                isLOL
                  ? match?.info?.gameEndTimestamp / 1000
                  : match?.start_time,
              )
            : "-"}
        </p>
      </div>
    </div>
  );
};

export default LastMatch;
