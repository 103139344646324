import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import LastMatch from "../LastMatch";

const LastMatches: React.FC<{
  matches: any;
  isLOL?: boolean;
  player_id: string;
}> = ({ isLOL, matches, player_id }) => {
  const location = useLocation();
  const [isMyStats, setIsMyStats] = useState(true);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      setIsMyStats(true);
    } else {
      setIsMyStats(false);
    }
  }, [location.pathname]);

  return (
    <div className={styles.last_matches_container}>
      <div className={styles.last_matches_header}>
        <p className={styles.header_text}>Last 7 Matches</p>
      </div>

      <div className={styles.last_matches_wrapper}>
        {matches?.map((match: any, i: number) => (
          <Link
            key={isLOL ? match?.metadata?.matchId : match?.match_id}
            to={`/app/${isMyStats ? "dashboard" : "statistics"}/stats/${
              isLOL ? "leagueoflegends" : "dota2"
            }/${
              isLOL
                ? process.env.REACT_APP_L_O_L_ID
                : process.env.REACT_APP_DOTA2_ID
            }/${isLOL ? match?.metadata?.matchId : match?.match_id}`}
          >
            <LastMatch
              isLOL={isLOL}
              isFirst={!i}
              match={match}
              player_id={isLOL ? match?.user_puuid : player_id}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LastMatches;
