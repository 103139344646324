import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StatsCell from "../../components/Dota2StatsCell";
import LastMatches from "../../components/LastMatches";
import { time2TimeAgo } from "../../../../helpers/formatDate";
import { useGetUserStatsMutation } from "../../../../services/userStats";
import styles from "./style.module.scss";
import Button from "../../../../components/Button";

const DotaStatistics = () => {
  const { id, matchId } = useParams();
  const getStats = useGetUserStatsMutation();
  const [match, setMatch] = useState<any>(null);
  const [stats, setStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state: any) => state.user);
  const [players, setPlayers] = useState({ radiants: [], dires: [] });

  useEffect(() => {
    if (id && user.id) {
      getStats.mutate(
        {
          game_id: id,
          user_id: user.id,
          type: "last-matches",
        },
        {
          onSuccess: (res: any) => {
            setStats(res.data.data);
            setIsLoading(false);
          },
        },
      );
    }
  }, [id, user.id]);

  useEffect(() => {
    if (stats?.matches?.length && id && user.id && matchId) {
      setPlayers({ radiants: [], dires: [] });
      getStats.mutate(
        {
          game_id: id,
          user_id: user.id,
          type: "match-details",
          additional_data: {
            match_id:
              matchId === "1" ? stats?.matches[0].match_id.toString() : matchId,
          },
        },
        {
          onSuccess: (res: any) => {
            setMatch(res.data.data.result);
            res.data.data.result.Players.forEach((e: any) => {
              if (e.team_number) {
                setPlayers((prev: any) => ({
                  ...prev,
                  dires: [...prev.dires, e],
                }));
              } else {
                setPlayers((prev: any) => ({
                  ...prev,
                  radiants: [...prev.radiants, e],
                }));
              }
            });
          },
        },
      );
    }
  }, [matchId, id, user.id, stats]);

  const dateFormat = (unix_timestamp: number) => {
    const date = new Date(unix_timestamp * 1000);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}${
      day === 1 || day === 21
        ? "st"
        : day === 2 || day === 22
        ? "nd"
        : day === 3 || day === 23
        ? "rd"
        : "th"
    }, ${year}`;
  };

  return isLoading ? (
    <p className={styles.loading}>Loading...</p>
  ) : stats?.matches ? (
    <div className={styles.dota_stats_wrapper}>
      <div>
        <div className={styles.match_details_wrapper}>
          <div className={styles.match_details_header}>
            <h3 className={styles.stats_page_title}>Overall Stats</h3>
          </div>

          <div className={styles.overall_stats_body}>
            <div
              className={`${styles.stats_container} ${styles.wins_loses_wrapper}`}
            >
              <div className={styles.wins_loses_content}>
                <p className={styles.overall_stats_secondary_text}>Wins</p>
                <p className={styles.wins_count}>
                  {stats?.account_details?.winCount || 0}
                </p>
              </div>

              <div className={styles.wins_loses_content}>
                <p className={styles.overall_stats_secondary_text}>Losses</p>
                <p className={styles.loses_count}>
                  {`${
                    stats?.account_details?.matchCount -
                      stats?.account_details?.winCount || 0
                  }`}
                </p>
              </div>
            </div>

            <div
              className={`${styles.stats_container} ${styles.total_matches_wrapper}`}
            >
              <p className={styles.overall_stats_secondary_text}>
                Total Matches
              </p>
              <p className={styles.total_matches_count}>
                {stats?.account_details?.matchCount || 0}
              </p>
            </div>

            <div
              className={`${styles.stats_container} ${styles.matches_time_wraper}`}
            >
              <p className={styles.overall_stats_secondary_text}>First Match</p>
              <p className={styles.matches_time}>
                {stats?.account_details?.firstMatchDate
                  ? dateFormat(stats?.account_details?.firstMatchDate)
                  : "-"}
              </p>
            </div>

            <div
              className={`${styles.stats_container} ${styles.matches_time_wraper}`}
            >
              <p className={styles.overall_stats_secondary_text}>Last Match</p>
              <p className={styles.matches_time}>
                {stats?.account_details?.lastMatchDate
                  ? dateFormat(stats?.account_details?.lastMatchDate)
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.dota_stats_body}>
        <div className={styles.body_center}>
          <div className={styles.match_details_wrapper}>
            <div className={styles.match_details_header}>
              <h3 className={styles.stats_page_title}>Match Details</h3>
            </div>

            <div className={styles.match_details_body}>
              <div className={styles.match_details_timeline}>
                <div className={styles.duration_wrapper}>
                  <p className={styles.secondary_text}>Duration</p>
                  <hr className={styles.vertical_line}></hr>
                  <p className={styles.primary_text}>
                    {match?.duration
                      ? `${(match?.duration / 60).toFixed(2)}`.replaceAll(
                          ".",
                          ":",
                        )
                      : "-"}
                  </p>
                </div>

                <p className={styles.secondary_text}>
                  {match?.start_time
                    ? time2TimeAgo(match?.start_time || 0)
                    : "-"}
                </p>
              </div>

              <div className={styles.match_details_score_wrapper}>
                <div className={styles.match_details_dire}>
                  <p className={styles.primary_text}>Dire Score</p>
                  <p className={styles.primary_text_large}>
                    {match?.dire_score || 0}
                  </p>
                  {!match?.radiant_win && (
                    <span className={`${styles.winner} ${styles.dire_win}`}>
                      Winner
                    </span>
                  )}
                </div>

                <div className={styles.match_details_radiant}>
                  {match?.radiant_win && (
                    <span className={`${styles.winner} ${styles.radiant_win}`}>
                      Winner
                    </span>
                  )}
                  <p className={styles.primary_text}>Radiant Score</p>
                  <p className={styles.primary_text_large}>
                    {match?.radiant_score || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h3 className={styles.stats_page_title}>Radiants</h3>

          {players.radiants.map((e: any, i: number) => (
            <StatsCell
              key={i}
              player={e}
              isYou={stats?.user_account_id === e?.account_id?.toString()}
            />
          ))}

          <h3 className={styles.stats_page_title}>Dires</h3>

          {players.dires.map((e: any, i: number) => (
            <StatsCell
              key={i}
              player={e}
              isYou={stats?.user_account_id === e?.account_id?.toString()}
            />
          ))}
        </div>

        <div className={styles.stats_sidebar}>
          <LastMatches
            matches={stats?.matches}
            player_id={stats?.user_account_id}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.no_stats_wrapper}>
      <div className={styles.no_stats_text_wrapper}>
        <p className={styles.no_stats_title}>
          Unable to retrieve statistics for DOTA 2. Please check your privacy
          settings by following the instructions listed below:
        </p>
        <p className={styles.no_stats_text}>
          1. Launch Dota 2 <br /> 2. Click the gear icon in the top left-hand
          corner of the screen <br />
          3. Next click the &#34;social&#34; tab
          <br />
          4. Check the box &#34;expose public match data&#34;
        </p>
      </div>
      <Button
        className={styles.no_stats_button}
        text="Reload"
        onClick={() => window.location.reload()}
      />
    </div>
  );
};

export default DotaStatistics;
