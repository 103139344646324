import { Modal, Stack, Typography } from "@mui/material";

import CloseIcon from "../../assets/icons/CloseIcon";

type modalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  width?: string;
  height?: string;
  customStyles?: any;
};

export default function CustomModal({
  open,
  onClose: handleClose,
  title,
  children,
  width,
  height,
  customStyles,
}: modalProps) {
  return (
    <Modal
      open={open}
      onClose={(e?: any, reason?: any) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        direction="column"
        bgcolor="#0D0D12"
        top={0}
        right={0}
        bottom={0}
        left={0}
        m="auto"
        position="absolute"
        width={width ?? "610px"}
        height={height ?? "300px"}
        borderRadius="16px"
        sx={{ border: "none", outline: "none" }}
        style={customStyles}
      >
        <Stack
          direction="row"
          px={3}
          py={2}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          borderBottom="1px #222228 solid"
        >
          <Typography color="#EFEFEF" fontWeight="700">
            {title}
          </Typography>
          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </Stack>
        {children}
      </Stack>
    </Modal>
  );
}
