import {
  ICreateComment,
  ICreatePost,
  IGetAllPosts,
  IGetComments,
  IUpdatePost,
} from "./types";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import { client } from "./config/index";
import toast from "react-hot-toast";

// ----------------------------------------------------------------------
export const useCreatePostMutation = () =>
  useMutation({
    mutationFn: (data: ICreatePost) => {
      if (data?.game_id == "0") {
        data.game_id = undefined;
      }

      return client.post("/v1/post", data);
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "create-post-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "create-post-err",
        });
      }
    },
  });

// ----------------------------------------------------------------------
export const useUpdatePostMutation = () =>
  useMutation({
    mutationFn: (data: IUpdatePost) => {
      return client.put(`/v1/post/${data.id}`, data);
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "update-post-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "update-post-err",
        });
      }
    },
  });

// ----------------------------------------------------------------------
export const useDeletePostMutation = () =>
  useMutation({
    mutationFn: (id: string) => {
      return client.delete(`/v1/post/${id}`);
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "delete-post-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "delete-post-err",
        });
      }
    },
  });

// ----------------------------- GET USERS ALL POSTS -----------------------------
export const getAllUsersPostsQuery = (data: IGetAllPosts) =>
  useInfiniteQuery(
    ["user-posts"],
    ({ pageParam = 1 }) =>
      client
        .get("/v1/post", {
          params: { ...data, page: pageParam },
        })
        .catch((err: any) => {
          if (err.response?.status === 500) {
            toast.error("Error: " + err?.response?.statusText);
          } else {
            if (err.response?.data?.validations?.length) {
              toast.error(
                "Error: " + err.response?.data?.validations[0]?.error,
                {
                  id: "get-users-posts-err",
                },
              );
            } else {
              toast.error("Error: " + err.response?.data?.error_message, {
                id: "get-users-posts-err",
              });
            }
          }
        }),
    {
      enabled: false,
      getNextPageParam: (lastPage: any, allPages: any) => {
        const nextPage =
          lastPage?.data?.posts?.length == data?.limit
            ? allPages?.length + 1
            : undefined;

        return nextPage;
      },
    },
  );

// ----------------------------- GET POST BY ID -----------------------------
export const useGetPostQuery = (id: string) =>
  useQuery({
    queryKey: ["post"],
    queryFn: () => client.get(`/v1/post/${id}`),
    enabled: !!id,
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "get-post-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "get-post-err",
        });
      }
    },
  });

// ----------------------------------------------------------------------
export const useCreateCommentMutation = () =>
  useMutation({
    mutationFn: ({ post_id, title, user_id }: ICreateComment) => {
      return client.post("/v1/post/post-comment", { post_id, title, user_id });
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "create-comment-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "create-comment-err",
        });
      }
    },
  });

// ----------------------------------------------------------------------
export const getCommentsQuery = (data: IGetComments) =>
  client
    .get(`/v1/post/post-comment`, {
      params: data,
    })
    .catch((err) => toast.error("Error: " + err.response?.data?.error_message));

export const deletePostMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation((id: string) => {
    return client.delete(`/v1/post/${id}`);
  }, mutationSettings);
};
