import { useContext, useMemo } from "react";

import { Box } from "@mui/material";
import { TeamContext } from "../../contexts/TeamContext";
import defaultImg from "../../assets/images/default-avatar.png";
import { useImage } from "../../services/getImages";

type LogoImgProps = {
  imgFile?: string;
  onLoad?: () => void;
};

export default function LogoImg({ imgFile, onLoad }: LogoImgProps) {
  const { teamData } = useContext(TeamContext);

  const savedImg = useMemo(() => {
    return teamData?.data?.logo;
  }, [teamData?.data?.logo]);

  const { data: savedImgST } = useImage(savedImg);

  const image = useMemo(() => {
    if (imgFile) {
      return imgFile == savedImg ? savedImgST : imgFile;
    } else {
      return defaultImg;
    }
  }, [imgFile]);

  return (
    <>
      {image ? (
        <img
          loading="lazy"
          src={image}
          height={72}
          width={72}
          style={{ objectFit: "cover", borderRadius: "8px" }}
          onLoad={onLoad}
        />
      ) : (
        <Box width={72} height={72} bgcolor="grey" borderRadius="8px" />
      )}
    </>
  );
}
