import {
  getCommentsQuery,
  useCreateCommentMutation,
  useGetPostQuery,
} from "../../services/post";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Comment from "../../components/Comment";
import { IconButton } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import Post from "../../components/Post";
import Textarea from "../../components/Textarea";
import { showSidebarAction } from "../../store/showSidebarReducer";
import styles from "./style.module.scss";

const Publication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: postId } = useParams();
  const getPost = useGetPostQuery(postId || "");
  const createComment = useCreateCommentMutation();
  const [comments, setComments] = useState<any>({});
  const user = useSelector((state: any) => state.user);
  const [newComment, setNewComment] = useState<any>({ html: "", text: "" });
  const [post, setPost] = useState({
    id: "",
    likes: 0,
    title: "",
    content: "",
    game_id: "",
    username: "",
    media: "",
    user_id: "",
    subtitle: "",
    created_at: "",
    game_title: "",
    updated_at: "",
    user_avatar: "",
    count_comments: 0,
    current_user_liked: false,
  });

  useEffect(() => {
    dispatch(showSidebarAction(true));
  }, []);

  useEffect(() => {
    if (getPost.data?.data) {
      setPost(getPost.data?.data);
    }
  }, [getPost.data?.data]);

  useEffect(() => {
    if (postId) {
      getPost.refetch();
      getCommentsQuery({
        page: 1,
        limit: 100,
        post_id: postId,
      }).then((res: any) => {
        setComments(res.data);
      });
    }
  }, [postId]);

  const handleTextareaChange = (
    content: any,
    delta: any,
    source: any,
    editor: any,
  ) => {
    setNewComment({ html: content, text: editor.getText() });
  };

  const handleCommentPost = () => {
    createComment.mutate(
      {
        post_id: post.id,
        user_id: user.id,
        title: newComment.html,
      },
      {
        onSuccess: () => {
          setNewComment({ html: "", text: "" });

          getCommentsQuery({
            page: 1,
            limit: 100,
            post_id: post.id,
          }).then((res: any) => setComments(res.data));
        },
      },
    );
  };

  return (
    <div>
      <div className={styles.post_header}>
        <IconButton style={{ color: "white" }} onClick={() => navigate(-1)}>
          <KeyboardBackspaceRoundedIcon className={styles.button_icon} />
        </IconButton>

        <p className={styles.header_text}>Post by {post?.username}</p>

        <div className={styles.header_button}></div>
      </div>

      <Post post={post} />

      <div className={styles.comments_wrapper}>
        <div className={styles.comment_input_wrapper}>
          <Textarea
            value={newComment.html}
            buttonText="Post Comment"
            onClick={handleCommentPost}
            onChange={handleTextareaChange}
            placeholder="Don’t be shy, leave a reply…"
            buttonDisabled={!newComment.text.replaceAll("\n", "")}
          />
        </div>

        <ul className={styles.comments_list}>
          {comments?.post_comments?.map((e: any) => (
            <li key={e.id} className={styles.comment_wrapper}>
              <div className={styles.comment}>
                <Comment
                  id={e.id}
                  content={e.title}
                  date={e.created_at}
                  user_id={e.user_id}
                  profileName={e.username}
                  profileImg={e.user_avatar}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Publication;
