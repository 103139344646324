const GET_POSTS = "GET_POSTS";
const CREATE_POST = "CREATE_POST";
const UPDATE_POST = "UPDATE_POST";
const DELETE_POST = "UPDATE_POST";

interface IPost {
  id: string;
  likes: number;
  media: string;
  title: string;
  user_id: string;
  content: string;
  subtitle: string;
  updated_at: string;
  created_at: string;
  current_user_liked: boolean;
}

const defaultState: IPost[] = [];

export function post(state = defaultState, action: any) {
  const data = action.payload;

  switch (action.type) {
    case GET_POSTS:
      return data || [];
    case CREATE_POST:
      return [data, ...state];
    case DELETE_POST:
      return state.filter((e) => e.id !== data);
    case UPDATE_POST:
      return state.map((e) => (e.id === data.id ? data : e));
    default:
      return state;
  }
}

export const getAllPostsAction = (payload: IPost[]) => ({
  type: GET_POSTS,
  payload,
});

export const createPostAction = (payload: IPost) => ({
  type: CREATE_POST,
  payload,
});

export const updatePostAction = (payload: IPost) => ({
  type: UPDATE_POST,
  payload,
});

export const deletePostAction = (payload: string) => ({
  type: DELETE_POST,
  payload,
});
