import React, { useEffect, useState } from "react";
import Button from "../Button";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { ILike } from "../types";
import styles from "./style.module.scss";
import { useUpdatePostMutation } from "../../services/post";
import { useDispatch } from "react-redux";
import { updatePostAction } from "../../store/postReducer";

const Like: React.FC<ILike> = ({
  post,
  likes,
  onClick,
  current_user_liked,
}) => {
  const dispatch = useDispatch();
  const updatePost = useUpdatePostMutation();
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    setIsLiked(current_user_liked);
  }, [current_user_liked]);

  const handleClick = () => {
    updatePost.mutate(
      {
        ...post,
        likes: isLiked ? likes - 1 : likes + 1,
        actions: !isLiked ? "like" : "dislike",
      },
      {
        onSuccess: (res: any) => {
          setIsLiked(res.data.current_user_liked);
          dispatch(updatePostAction(res.data));
          onClick(res.data.likes);
        },
      },
    );
  };

  return (
    <Button
      text="Like"
      onClick={handleClick}
      className={`${styles.like_button} ${isLiked && styles.liked_button}`}
      icon={
        isLiked ? (
          <FavoriteRoundedIcon className={styles.liked_icon} />
        ) : (
          <FavoriteBorderRoundedIcon className={styles.like_icon} />
        )
      }
    />
  );
};

export default Like;
