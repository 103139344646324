import { Checkbox, FormControlLabel } from "@mui/material";

interface CustomCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ml?: number;
  defaultChecked?: boolean;
}

export default function CustomCheckbox({
  label,
  checked,
  onChange,
  defaultChecked,
  ml,
}: CustomCheckboxProps) {
  return (
    <FormControlLabel
      sx={{
        "& .MuiTypography-root": {
          color: "#EBEBEB",
          fontSize: "14px",
        },
        marginTop: "0 !important",
      }}
      control={
        <Checkbox
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e);
          }}
          sx={{
            ml: ml ?? 0.5,
            marginTop: "0 !important",
            "& .MuiCheckbox-root": {
              marginTop: "0 !important",
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
              borderRadius: "6px !important",
              marginTop: "0 !important",
            },
            "&.Mui-checked .MuiSvgIcon-root": {
              color: "#4957E9",
              borderRadius: "6px",
              border: "2px solid #4957E9",
              marginTop: "0 !important",
            },
          }}
        />
      }
      label={label}
    />
  );
}
