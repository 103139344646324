import { getGamePostsQuery, useGetGameQuery } from "../../../services/game";
import { useEffect, useState } from "react";

import GameHeader from "./GameHeader";
import Post from "../../../components/Post";
import styles from "./style.module.scss";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const Game = () => {
  const { id } = useParams();
  const getGame = useGetGameQuery(id || "");
  const [game, setGame] = useState<any>({});
  const { data: posts } = getGamePostsQuery({
    querySettings: {
      enabled: !!id,
      onError: (err: any) => {
        toast.error("Error: " + err?.response?.data?.error_message);
      },
    },
    queryParams: {
      gameId: id || "",
    },
  });

  useEffect(() => {
    getGame.refetch().then((res: any) => {
      setGame(res?.data?.data);
    });
  }, [id]);

  return (
    <div className={styles.game}>
      <GameHeader
        gameImg={game.banner}
        releaseDate={game.release_date}
        gameLogo={game.logo}
        developer={game.developer}
        publisher={game.publisher}
        features={game.feature}
        website={game.link}
        genre={game.genre}
      />

      <div className={styles.game_body}>
        {posts?.data?.posts?.map((post: any) => {
          return <Post post={post} key={post?.id} />;
        })}
      </div>
    </div>
  );
};

export default Game;
