import { Outlet } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import styles from "./style.module.scss";
import { Toaster } from "react-hot-toast";

const Auth = () => {
  return (
    <div className={styles.auth}>
      <Toaster position="top-right" />
      <div className={styles.auth_inner}>
        {!window.location.pathname.includes("riot.txt") && (
          <div className={styles.logo_wrapper}>
            <img loading="lazy" src={logo} alt="Logo" className={styles.logo} />
            <p className={styles.logo_slogan}>
              Drive Your Connections In Gaming
            </p>
          </div>
        )}

        <div className={styles.auth_right_block}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Auth;
