import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import NoStats from "../../../../components/NoStats";
import GameCard from "../../../../components/GameCard";
import { useMyGamesQuery } from "../../../../services/myGames";
import styles from "./style.module.scss";
import Button from "../../../../components/Button";
import { useAddUserSteamGamesMutation } from "../../../../services/userGames";
import Loader from "../../../../components/Loader";

const MyStatistics = () => {
  const user = useSelector((state: any) => state.user);
  const addSteamGames = useAddUserSteamGamesMutation();
  const [isSteamPrivate, setIsSteamPrivate] = useState(false);

  const { data: games, isLoading } = useMyGamesQuery({
    params: {
      id: user?.id,
      steam_id: user?.connected_platforms?.steam?.steam_id,
    },
    querySettings: {
      enabled: !!user?.id,
    },
  });

  useEffect(() => {
    if (
      games?.data?.core_games?.length &&
      user.connected_platforms?.steam?.steam_id &&
      !games?.data?.core_games?.some(
        (game: any) =>
          game.game_id === process.env.REACT_APP_DOTA2_ID ||
          game.game_id === process.env.REACT_APP_CSGO_ID,
      )
    ) {
      setIsSteamPrivate(true);
    } else if (
      user?.connected_platforms?.steam?.steam_id &&
      !games?.data?.core_games?.length
    ) {
      setIsSteamPrivate(true);
    } else {
      setIsSteamPrivate(false);
    }
  }, [games]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader text="Loading My Statistics..." />
        </div>
      ) : (
        <>
          {games?.data?.core_games?.length ? (
            <>
              <div className={styles.topContainer}>
                {games?.data?.core_games?.map(
                  (coreGame: {
                    id: string;
                    game_id: string;
                    game_logo: string;
                    game_title: string;
                    game_data: {
                      playtime_forever: number;
                      playtime_2weeks: number;
                    };
                  }) => (
                    <Link
                      key={coreGame?.id}
                      to={`/app/dashboard/stats/${coreGame?.game_title
                        .toLowerCase()
                        .replaceAll(" ", "")}/${coreGame?.game_id}/1`}
                    >
                      <GameCard
                        game_title={coreGame?.game_title}
                        playtime_2weeks={
                          coreGame?.game_data?.playtime_2weeks || 0
                        }
                        playtime_forever={
                          coreGame?.game_data?.playtime_forever || 0
                        }
                        bgImg={coreGame?.game_logo || ""}
                      />
                    </Link>
                  ),
                )}
                {isSteamPrivate && (
                  <div className={styles.no_stats_wrapper}>
                    <div className={styles.empty_box}></div>
                    <div className={styles.empty_box}></div>
                  </div>
                )}
              </div>

              {isSteamPrivate && (
                <div className={styles.private_steam_info_wrapper}>
                  <div className={styles.disclaimer_wrapper}>
                    <p className={styles.disclaimer_title_primary}>
                      Unable to retrieve statistics
                    </p>
                    <p className={styles.disclaimer_text}>
                      Your steam profile is set to private. Please follow the
                      instructions{" "}
                      <a
                        href="https://help.steampowered.com/en/faqs/view/588C-C67D-0251-C276"
                        target="blank"
                        className={styles.here_link}
                      >
                        here
                      </a>{" "}
                      to set your profile to Public and display your gaming
                      statistics.
                    </p>
                  </div>
                  <Button
                    text="Reload"
                    className={styles.reload_button}
                    onClick={() => {
                      addSteamGames.mutate(
                        {
                          user_id: user.id,
                          steam_id: user?.connected_platforms?.steam?.steam_id,
                        },
                        { onSuccess: () => window.location.reload() },
                      );
                    }}
                  />
                </div>
              )}

              <div className={styles.disclaimer_wrapper}>
                <p className={styles.disclaimer_title}>Disclaimer</p>
                <p className={styles.disclaimer_text}>
                  GameDrive is not endorsed by Riot Games and does not reflect
                  the views or opinions of Riot Games or anyone officially
                  involved in producing or managing Riot Games properties. Riot
                  Games and all associated properties are trademarks or
                  registered trademarks of Riot Games, Inc.
                </p>
              </div>
            </>
          ) : isSteamPrivate &&
            user?.connected_platforms?.steam?.steam_id &&
            !user?.connected_platforms?.riot?.id ? (
            <NoStats isStats={true} isConnected={true} />
          ) : (
            <NoStats isStats={true} />
          )}{" "}
        </>
      )}
    </div>
  );
};

export default MyStatistics;
