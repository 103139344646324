import { Divider, Stack } from "@mui/material";
import { getTeamRolesQuery, updateRoleMutation } from "../../../services/team";
import { useContext, useMemo, useState } from "react";

import Button from "../../../components/Button";
import CustomCheckbox from "../../../components/Checkbox/CustomCheckbox";
import CustomModal from "../../../components/Modal/CustomModal";
import CustomTooltip from "../../../components/Tooltip/CustomTooltip";
import HelpIcon from "@mui/icons-material/Help";
import Radio from "../../../components/Radio";
import { TeamContext } from "../../../contexts/TeamContext";
import { checkRole } from "../../../helpers/checkRole";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import usePermission from "../../../hooks/usePermission";

interface UpdateRoleModalProps {
  isOpen: boolean;
  setIsExpandOpen?: (value: { status: string }) => void;
  onClose: () => void;
  id: string;
  currRole: string;
  currRoster: boolean;
  roleId?: string;
}

export default function UpdateRoleModal({
  isOpen,
  onClose,
  id,
  currRole,
  currRoster,
  roleId,
  setIsExpandOpen,
}: UpdateRoleModalProps) {
  const { id: teamId } = useParams();
  const { checkPermission } = usePermission();
  const { refetchTeamMembers, refetchRosters } = useContext(TeamContext);
  const [checkedRole, setCheckedRole] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [isRoster, setIsRoster] = useState(currRoster);
  const { data: teamRoles } = getTeamRolesQuery({
    queryParams: {
      id: teamId,
    },
    querySettings: {
      onSuccess: () => {},
      enabled:
        !!checkPermission("team/member/assign/coach") ||
        !!checkPermission("team/member/assign/captain") ||
        !!checkPermission("team/member/assign/owner"),
    },
  });
  const { mutate: updateRoleMutate } = updateRoleMutation({
    mutationSettings: {
      onSuccess: () => {
        refetchTeamMembers && refetchTeamMembers();
        refetchRosters && refetchRosters();
        setIsExpandOpen &&
          setIsExpandOpen({
            status: "close",
          });
        setCheckedRole({
          id: "",
          name: "",
          description: "",
        });
      },
      onError: (err: any) => {
        console.log("err", err);
        toast.error(err?.response?.data?.error_message);
        setIsExpandOpen &&
          setIsExpandOpen({
            status: "close",
          });
      },
    },
  });

  const canEditRoster = useMemo(() => {
    return (
      checkPermission("team/member/roster/set") ||
      checkPermission("team/member/roster/take-out")
    );
  }, []);

  function handleRoleUpdate() {
    const path = checkRole(checkedRole?.name, currRole, isRoster, currRoster);
    setIsExpandOpen &&
      setIsExpandOpen({
        status: "close",
      });

    updateRoleMutate({
      id,
      path,
      role_id: checkedRole?.id ? checkedRole?.id : roleId ?? "",
      team_id: teamId ?? "",
      is_roster: isRoster,
    });
  }

  return (
    <CustomModal
      title="Update player role"
      open={isOpen}
      onClose={() => {
        onClose();
        setIsExpandOpen &&
          setIsExpandOpen({
            status: "close",
          });

        setCheckedRole({
          id: "",
          name: "",
          description: "",
        });
        setIsRoster(currRoster);
      }}
      height="max-content"
      width="400px"
    >
      <Stack direction="column" spacing={1} px={3} py={2}>
        {teamRoles?.data?.roles?.map(
          (role: { id: string; name: string; description: string }) => {
            if (role?.id === roleId) return null;
            return (
              <Stack key={role?.id} direction="column" py={1} spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Radio
                    label={role?.name}
                    checked={checkedRole?.id === role?.id}
                    onClick={() => {
                      setCheckedRole(role);
                    }}
                    name="role"
                    value={role?.id}
                    inputCustomStyle={{
                      width: 20,
                      height: 20,
                    }}
                  />
                  <CustomTooltip
                    content={role?.description}
                    icon={
                      <HelpIcon
                        htmlColor="#FFFFFF"
                        sx={{
                          width: 18,
                          opacity: 0.5,
                        }}
                      />
                    }
                  />
                </Stack>

                <Divider
                  sx={{
                    backgroundColor: "#313137",
                  }}
                />
              </Stack>
            );
          },
        )}

        {canEditRoster && (
          <CustomCheckbox
            label="Set as roster"
            checked={isRoster}
            onChange={() => {
              setIsRoster((prev) => !prev);
            }}
            ml={-1}
          />
        )}

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          gap={1}
        >
          <Button
            text="Cancel"
            isCancelButton
            onClick={() => {
              onClose();
              setIsExpandOpen &&
                setIsExpandOpen({
                  status: "close",
                });

              setCheckedRole({
                id: "",
                name: "",
                description: "",
              });
              setIsRoster(currRoster);
            }}
          />
          <Button
            text="Confirm"
            disabled={
              !checkedRole?.id ? isRoster == currRoster : !checkedRole?.id
            }
            onClick={() => {
              onClose();
              handleRoleUpdate();
            }}
          />
        </Stack>
      </Stack>
    </CustomModal>
  );
}
