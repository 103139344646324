import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import { Riot } from "../../assets/icons/Riot";
import { Steam } from "../../assets/icons/Steam";
import { otherUserSubpages } from "../helper/demo";
import { useGetUserQuery } from "../../services/user";
import { useDispatch, useSelector } from "react-redux";
import FollowModal from "../../components/FollowsModal";
import ProfilePhoto from "../../components/ProfilePhoto";
import { updateUserAction } from "../../store/userReducer";
import { showSidebarAction } from "../../store/showSidebarReducer";
import CancelFollowModal from "../../components/CancelFollowModal";
import { updateVisibilityAction } from "../../store/visibilityReducer";
import { Link, Outlet, useParams, useLocation } from "react-router-dom";
import {
  useDeleteFollow,
  useCreateFollowRequest,
  useDeclineFollowRequest,
} from "../../services/follow";
import styles from "./style.module.scss";
import { updateOtherUserAction } from "../../store/otherUserReducer";
import { useImage } from "../../services/getImages";

const OtherUser = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const unfollow = useDeleteFollow();
  const [open, setOpen] = useState(false);
  const follow = useCreateFollowRequest();
  const [active, setActive] = useState("");
  const cancelReq = useDeclineFollowRequest();
  const getUser = useGetUserQuery(userId || "");
  const [modalType, setModalType] = useState("");
  const [followStatus, setFollowStatus] = useState(0);
  const user = useSelector((state: any) => state.user);
  const otherUser = useSelector((state: any) => state.otherUser);
  const [isFollowDisabled, setIsFollowDisabled] = useState(false);
  const [openFollowModal, setOpenFollowModal] = useState({
    followers: false,
    followings: false,
  });

  const { data: otherUserBanner } = useImage(otherUser?.banner);
  const { data: otherUserAvatar } = useImage(otherUser?.avatar);

  useEffect(() => {
    dispatch(showSidebarAction(false));
  }, []);

  useEffect(() => {
    setActive(location.pathname.split("/")[4]);
  }, [location.pathname]);

  useEffect(() => {
    if (userId) {
      getUser.refetch().then((res: any) => {
        setFollowStatus(res?.data?.data?.follow_status);
        dispatch(updateOtherUserAction(res?.data?.data));
        dispatch(
          updateVisibilityAction(
            res?.data?.data?.is_private && res?.data?.data?.follow_status !== 2,
          ),
        );
      });
    }
  }, [userId]);

  const handleFollow = () => {
    setIsFollowDisabled(true);

    follow.mutate(
      {
        following_id: userId || "",
      },
      {
        onSuccess: (res: any) => {
          setIsFollowDisabled(false);
          if (res.data?.follow_status === 2) {
            dispatch(
              updateOtherUserAction({
                ...otherUser,
                followers: otherUser.followers + 1,
              }),
            );
            dispatch(
              updateUserAction({ ...user, following: user?.following + 1 }),
            );
          }
          setFollowStatus(res.data?.follow_status);
        },
        onError: () => {
          setIsFollowDisabled(false);
        },
      },
    );
  };

  const handleCancelRequest = () => {
    setIsFollowDisabled(true);
    cancelReq.mutate(
      { current_user_id: user?.id, user_id: userId },
      {
        onSuccess: () => {
          setIsFollowDisabled(false);
          setFollowStatus(0);
          setOpen(false);
        },
        onError: () => {
          setIsFollowDisabled(false);
        },
      },
    );
  };

  const handleUnfollow = () => {
    unfollow.mutate(
      { user_id: otherUser?.id },
      {
        onSuccess: () => {
          dispatch(
            updateOtherUserAction({
              ...otherUser,
              followers: otherUser.followers - 1,
            }),
          );
          setFollowStatus(0);
          dispatch(
            updateUserAction({
              ...user,
              following: user.following ? user.following - 1 : 0,
            }),
          );
          setOpen(false);
        },
      },
    );
  };

  return (
    <div className={styles.dashboard}>
      <Banner height="300px" img={otherUser?.banner ? otherUserBanner : ""} />

      <FollowModal
        isOtherUser={true}
        userId={otherUser?.id}
        isFollowersModal={true}
        open={openFollowModal.followers}
        handleClose={() =>
          setOpenFollowModal({ followers: false, followings: false })
        }
      />

      <FollowModal
        isOtherUser={true}
        userId={otherUser?.id}
        isFollowersModal={false}
        open={openFollowModal.followings}
        handleClose={() =>
          setOpenFollowModal({ followers: false, followings: false })
        }
      />

      <div className={styles.dashboard_body}>
        <div className={styles.dashboard_inner_body}>
          <div className={styles.body_account_wrapper}>
            <div className={styles.account_info_wrapper}>
              <ProfilePhoto
                size="128px"
                rounded={true}
                className={styles.profile_photo}
                img={otherUser?.avatar ? otherUserAvatar : ""}
              />

              <div className={styles.account_info_inner_wrapper}>
                <h1 className={styles.account_profile_name}>
                  {otherUser?.username || "Unknown"}
                </h1>

                <div className={styles.accounts_connect}>
                  {otherUser?.connected_platforms?.steam?.steam_id && (
                    <Tooltip placement="top" title="Connected">
                      <Box>
                        <Button
                          icon={<Steam />}
                          disabled={true}
                          className={styles.connect_success}
                        />
                      </Box>
                    </Tooltip>
                  )}

                  {otherUser?.connected_platforms?.riot?.id && (
                    <Tooltip placement="top" title="Connected">
                      <Box>
                        <Button
                          icon={<Riot />}
                          disabled={true}
                          className={styles.connect_success}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>

            {followStatus === 0 ? (
              <Button
                text="Follow"
                disabled={isFollowDisabled}
                className={styles.post_button}
                onClick={handleFollow}
              />
            ) : followStatus === 1 ? (
              <Button
                text="Requested"
                disabled={isFollowDisabled}
                onClick={() => {
                  setModalType("cancel_req");
                  setOpen(true);
                }}
                className={styles.requested_button}
              />
            ) : (
              <Button
                text="Following"
                disabled={isFollowDisabled}
                onClick={() => {
                  setModalType("unfollow");
                  setOpen(true);
                }}
                className={styles.following_button}
              />
            )}
          </div>

          <div className={styles.body_follow_wrapper}>
            <button
              className={styles.follow_text}
              disabled={otherUser?.is_private && otherUser?.follow_status !== 2}
              onClick={() =>
                setOpenFollowModal({ followers: false, followings: true })
              }
            >
              {otherUser?.following} following
            </button>
            <button
              className={styles.follow_text}
              disabled={otherUser?.is_private && otherUser?.follow_status !== 2}
              onClick={() =>
                setOpenFollowModal({ followers: true, followings: false })
              }
            >
              {otherUser.followers} follower{otherUser.followers !== 1 && "s"}
            </button>
          </div>
        </div>

        <div className={styles.subpages_wrapper}>
          <div className={styles.subpages_header_wrapper}>
            {otherUserSubpages.map((e: string, i: number) => {
              const path = e.toLocaleLowerCase().replaceAll(" ", "-");
              const isActive = active === path;

              return (
                <Link
                  key={i}
                  className={styles.subpage_link}
                  to={
                    otherUser?.is_private && otherUser?.follow_status !== 2
                      ? "#"
                      : `/app/user/${userId}/${path}${
                          path === "games" || path === "statistics"
                            ? `/${otherUser?.connected_platforms?.steam?.steam_id}`
                            : ""
                        }`
                  }
                >
                  <Button
                    text={e}
                    className={`${styles.subpage_button} ${
                      isActive && styles.active_subpage
                    }`}
                  />
                </Link>
              );
            })}
          </div>

          <div className={styles.subpage_content}>
            {otherUser?.is_private && otherUser?.follow_status !== 2 ? (
              <div className={styles.private_account_wrapper}>
                <p className={styles.private_account_title}>
                  This Account is private
                </p>
                <p className={styles.private_account_text}>
                  If this player accepts your follow request, you will be able
                  to see their posts.
                </p>
              </div>
            ) : (
              <Outlet />
            )}
          </div>
        </div>
      </div>

      <CancelFollowModal
        open={open}
        user={otherUser}
        modalType={modalType}
        close={() => setOpen(false)}
        handleConfirm={
          followStatus === 2 ? handleUnfollow : handleCancelRequest
        }
      />
    </div>
  );
};

export default OtherUser;
