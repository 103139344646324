import { Box } from "@mui/material";
import LogoImg from "../../../components/Logo/LogoImg";
import LogoUpload from "../../../components/Upload/LogoUpload";
import { TeamContext } from "../../../contexts/TeamContext";
import { useContext } from "react";
import usePermission from "../../../hooks/usePermission";

export default function TeamLogo() {
  const { checkPermission } = usePermission();
  const { setLogoId, teamData } = useContext(TeamContext);

  if (checkPermission("team/edit/logo")) {
    return <LogoUpload setLogoId={setLogoId} />;
  }

  return (
    <Box position="relative">
      <Box position="absolute" top={-80} left={16}>
        <LogoImg imgFile={teamData?.data?.logo} />
      </Box>
    </Box>
  );
}
