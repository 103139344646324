export const arePasswordsCorrect = (
  setFormError: any,
  password: { newPassword: string; confirmPassword: string },
) => {
  if (!password.newPassword) return false;

  if (password.newPassword.length < 8) {
    setFormError((prev: any) => ({
      ...prev,
      newPassword: {
        isError: true,
        text: "Password must contain at least 8 symbols",
      },
    }));
    return false;
  }

  if (
    !/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(password.newPassword) ||
    !/^[^~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/][^~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]*$/.test(
      password.newPassword,
    )
  ) {
    setFormError((prev: any) => ({
      ...prev,
      newPassword: {
        isError: true,
        text: "Password must be 8 or more characters including a combination of letters, numbers and at least 1 of the following special characters ~`!@#$%^&*()_-+={[}]|:;”’<,>.?/",
      },
    }));

    return false;
  }

  if (!password.confirmPassword) return false;

  if (password.newPassword !== password.confirmPassword) {
    setFormError((prev: any) => ({
      ...prev,
      confirmPassword: {
        isError: true,
        text: "Passwords must match",
      },
    }));

    return false;
  }

  return true;
};
