import { Stack, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";

import DeletePopup from "../../views/Teams/components/DeletePopup";
import ExpandMore from "../Expand/ExpandMore";
import { TeamContext } from "../../contexts/TeamContext";
import UpdateRoleModal from "../../views/Teams/components/UpdateRoleModal";
import { canRemoveMember } from "../../helpers/canRemoveMember";
import { canUpdateRole } from "../../helpers/canUpdateRole";
import defaultAvatar from "../../assets/images/default-avatar.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useImage } from "../../services/getImages";

type PlayerCardProps = {
  player: {
    user_id: string;
    role_name: string;
    user_name: string;
    user_avatar: string;
    id: string;
    is_roster: boolean;
    role_id: string;
    username?: string;
    avatar?: string;
  };
  type?: string;
  onContainerClick?: (player: any) => void;
  canGoToProfile?: boolean;
};

export default function PlayerCard({
  player,
  type,
  onContainerClick,
  canGoToProfile,
}: PlayerCardProps) {
  const [isExpandOpen, setIsExpandOpen] = useState<{ status: string }>({
    status: "default",
  });
  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);
  const [isUpdateRoleOpen, setIsUpdateRoleOpen] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user);
  const { teamData } = useContext(TeamContext);
  const navigate = useNavigate();

  const { data: playerAvatar } = useImage(
    player?.user_avatar ?? player?.avatar,
  );

  const isSimpleMember = useMemo(() => {
    return (
      teamData?.data?.team_member_info?.role_name == "Member" ||
      !teamData?.data?.team_member_info?.role_name
    );
  }, [teamData]);

  const playerMenuOptions = [
    {
      id: "1",
      name: "Update Role",
      onClick: () => {
        setIsUpdateRoleOpen(true);
      },
      canManage: canUpdateRole(player?.role_name),
    },
    {
      id: "2",
      name: "Remove",
      onClick: () => {
        setIsRemoveOpen(true);
      },
      canManage: canRemoveMember(player?.role_name),
    },
  ];

  const menuOptions: {
    id: string;
    name: string;
    onClick: () => void;
    canManage: boolean;
  }[] = useMemo(() => {
    const options: {
      id: string;
      name: string;
      onClick: () => void;
      canManage: boolean;
    }[] = [];

    playerMenuOptions.forEach((option) => {
      if (option.canManage) {
        options.push(option);
      }
    });

    return options;
  }, [player?.id, teamData?.data?.team_member_info?.id]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      onClick={() => {
        onContainerClick && onContainerClick(player);
      }}
      sx={
        onContainerClick && {
          cursor: "pointer",
        }
      }
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {/* <Link
          to={
            user.id === player?.user_id
              ? "/app/dashboard/home"
              : `/app/user/${player?.user_id}/posts`
          }
        > */}
        <img
          loading="lazy"
          width={40}
          height={40}
          style={{
            borderRadius: "100%",
            cursor: canGoToProfile ? "pointer" : "default",
          }}
          src={
            player?.user_avatar || player?.avatar ? playerAvatar : defaultAvatar
          }
          onClick={() => {
            if (canGoToProfile) {
              navigate(
                user.id === player?.user_id
                  ? "/app/dashboard/home"
                  : `/app/user/${player?.user_id}/posts`,
              );
            }
          }}
        />
        {/* </Link> */}

        <Stack direction="column" gap={0}>
          {/* <Link
            to={
              user.id === player?.user_id
                ? "/app/dashboard/home"
                : `/app/user/${player?.user_id}/posts`
            }
          > */}
          <Typography
            color="white"
            fontWeight={600}
            fontSize="14px"
            sx={{
              cursor: canGoToProfile ? "pointer" : "default",
            }}
            onClick={() => {
              if (canGoToProfile) {
                navigate(
                  user.id === player?.user_id
                    ? "/app/dashboard/home"
                    : `/app/user/${player?.user_id}/posts`,
                );
              }
            }}
          >
            {player?.user_name ?? player?.username}
          </Typography>
          {/* </Link> */}
          <Typography color="#8C8D94" fontSize="13px">
            {player?.role_name}
          </Typography>
        </Stack>
      </Stack>
      {!isSimpleMember &&
        player?.role_name != "Owner" &&
        teamData?.data?.team_member_info?.id !== player?.id &&
        menuOptions?.filter((option) => option.canManage).length > 0 &&
        type != "search" && (
          <ExpandMore
            options={menuOptions}
            isExpandOpen={isExpandOpen}
            setIsExpandOpen={setIsExpandOpen}
          />
        )}
      <UpdateRoleModal
        id={player?.id}
        roleId={player?.role_id}
        currRole={player?.role_name}
        currRoster={player?.is_roster}
        isOpen={isUpdateRoleOpen}
        setIsExpandOpen={setIsExpandOpen}
        onClose={() => {
          setIsUpdateRoleOpen(false);
        }}
      />
      <DeletePopup
        id={player?.id}
        isOpen={isRemoveOpen}
        setIsOpen={setIsRemoveOpen}
        title="Remove Player"
        content={`Are you sure you would like to remove ${player?.username} from the team?`}
        permissionPath="team/member/kick"
        buttonLabel="Remove"
      />
    </Stack>
  );
}
