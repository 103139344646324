import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import CreateTeam from "../components/CreateTeam";
import GameCommunitySelect from "../../../components/Select/GameCommunitySelect";
import TeamsTable from "../components/TeamsTable";
import { client } from "../../../services/config";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";

const LIMIT = 10;

export default function TeamsContainer() {
  const [filterByGameCommunity, setFilterByGameCommunity] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { ref, inView } = useInView();

  const getTeams = async (gameId: string, limit: number, page: number) => {
    return await client.get(
      `/v1/team?game_id=${
        gameId == "all" ? "" : gameId
      }&limit=${limit}&page=${page}`,
    );
  };

  const {
    data: teams,
    refetch: reFetchTeams,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery(
    ["all-teams"],
    ({ pageParam = 1 }) => getTeams(filterByGameCommunity, LIMIT, pageParam),
    {
      keepPreviousData: false,
      getNextPageParam: (lastPage: any, allPages: any) => {
        const nextPage =
          lastPage?.data?.teams?.length === LIMIT
            ? allPages.length + 1
            : undefined;
        return nextPage;
      },
    },
  );

  const allTeams = teams?.pages.flatMap((page) => page?.data?.teams) ?? [];

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (filterByGameCommunity) {
      if (filterByGameCommunity === "all") {
        setFilterByGameCommunity("");
      }
      reFetchTeams();
    }
  }, [filterByGameCommunity]);

  return (
    <Stack p={3} spacing={2}>
      <Typography fontSize="24px" fontWeight={600} color="white">
        Teams
      </Typography>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography fontSize="15px" fontWeight={500} color="white">
            Filter:
          </Typography>
          <GameCommunitySelect
            selectedGame={filterByGameCommunity}
            setSelectedGame={setFilterByGameCommunity}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ background: "#4655F4", cursor: "pointer" }}
          borderRadius="8px"
          px={2}
          onClick={() => setIsOpen(true)}
        >
          <Typography fontSize="14px" fontWeight={600} color="white">
            Create a Team
          </Typography>
        </Stack>
      </Stack>
      <Typography fontSize="14px" color="grey">
        {teams?.pages[0]?.data?.count}+ Teams
      </Typography>

      <TeamsTable teams={allTeams} isFetching={isFetching} />

      {true && (
        <Typography ref={ref} color="white">
          {isFetchingNextPage && "Loading..."}
        </Typography>
      )}

      <CreateTeam
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        reFetchTeams={reFetchTeams}
      />
    </Stack>
  );
}
