import { useEffect, useState } from "react";
import News from "../../components/News";
import { useAllNews } from "../../services/user";
import styles from "./style.module.scss";
import Loader from "../../components/Loader";

const AllNews = () => {
  const { data, isLoading } = useAllNews();
  const [topNews, setTopNews] = useState<any[]>([]);
  const [news, setNews] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const response = [...data.news];
      setTopNews(response.splice(0, 2));
      setNews(response);
    }
  }, [data]);

  return isLoading ? (
    <Loader text="Loading news..." />
  ) : topNews?.length || news?.length ? (
    <div className={styles.news_page_wrapper}>
      <div className={styles.top_news_wrapper}>
        {!!topNews?.length &&
          topNews?.map((e: any, i: number) => (
            <News key={`${e?.url}${i}`} news={e} isTopNews={true} />
          ))}
      </div>

      <div className={styles.news_wrapper}>
        {!!news?.length &&
          news?.map((e: any, i: number) => (
            <News key={`${e?.url}${i}`} news={e} />
          ))}
      </div>
    </div>
  ) : (
    <p className={styles.no_news}>There are no news yet</p>
  );
};

export default AllNews;
