import toast from "react-hot-toast";
import { client } from "./config/index";
import { ICreateUser, IGetRiot, IUpdateUser } from "./types";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

export const useCreateUserMutation = () =>
  useMutation({
    mutationFn: (data: ICreateUser) => client.post("/v1/user", data),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "user-create-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "user-create-err",
        });
      }
    },
  });

export const useUpdateUserMutation = () =>
  useMutation({
    mutationFn: (data: IUpdateUser) => client.put(`/v1/user/${data.id}`, data),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "user-change-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "user-change-err",
        });
      }
    },
  });

export const useDeleteUserMutation = () =>
  useMutation({
    mutationFn: (user_id: string) => client.delete(`/v1/user/${user_id}`),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "user-delete-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "user-delete-err",
        });
      }
    },
  });

export const getCurrentUserQuery = () => client.get("/v1/user/profile");

export const useGetUserQuery = (user_id: string) =>
  useQuery({
    queryKey: ["user-by-id"],
    queryFn: () => client.get(`/v1/user/${user_id}`),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "user-get-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "user-get-err",
        });
      }
    },
    enabled: false,
  });

export const useUpdatePasswordMutation = () =>
  useMutation({
    mutationFn: ({
      id,
      password,
      captcha_token,
    }: {
      id: string;
      password: string;
      captcha_token: string;
    }) =>
      client.put("/v1/user/update-password", { id, password, captcha_token }),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "password-change-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "password-change-err",
        });
      }
    },
  });

export const useUpdateUsernameMutation = () =>
  useMutation({
    mutationFn: ({ id, username }: { id: string; username: string }) =>
      client.put("/v1/user/update-username", { id, username }),
    onError: (err: any) => {
      if (err.response?.status === 500) {
        toast.error("Error: could not update username");
      }
    },
  });

export const useSteamConnectMutation = () =>
  useMutation({
    mutationFn: (data: object) => client.post("/v1/auth/steam/connect", data),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "steam-connect-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "steam-connect-err",
        });
      }
    },
  });

// Get user's riot account by summoner name
export const useGetRiotMutation = () =>
  useMutation({
    mutationFn: (data: IGetRiot) =>
      client.post("/v1/user/get-riot-account-by-id", data),

    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "riot-get-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "riot-get-err",
        });
      }
    },
  });

export const getByUsername = ({
  username,
  usertype,
}: {
  username: string;
  usertype: string;
}) => {
  return client.get("/v1/user/get-by-username", {
    params: { username, usertype },
  });
};

export const getGlobalSearch = (data: { search: string; limit: number }) =>
  useInfiniteQuery(
    ["global-search"],
    ({ pageParam = 1 }) =>
      client
        .get("/v1/user/search", {
          params: { ...data, page: pageParam },
        })
        .catch((err: any) => {
          if (err.response?.status === 500) {
            toast.error("Error: " + err?.response?.statusText);
          } else {
            if (err.response?.data?.validations?.length) {
              toast.error(
                "Error: " + err.response?.data?.validations[0]?.error,
                {
                  id: "global-search-err",
                },
              );
            } else {
              toast.error("Error: " + err.response?.data?.error_message, {
                id: "global-search-err",
              });
            }
          }
        }),
    {
      enabled: !!data.search,
      getNextPageParam: (lastPage: any, allPages: any) => {
        const nextPage =
          lastPage?.data?.response?.length == data?.limit
            ? allPages?.length + 1
            : undefined;

        return nextPage;
      },
    },
  );

export const getMyNews = () => {
  return client.get("/v1/news/my-news").catch((err: any) => {
    if (err.response?.data?.validations?.length) {
      toast.error("Error: " + err.response?.data?.validations[0]?.error, {
        id: "my-news-err",
      });
    } else {
      toast.error("Error: " + err.response?.data?.error_message, {
        id: "my-news-err",
      });
    }
  });
};

const fetchAllNews = async () => {
  try {
    const response = await client.get("/v1/news");
    return response.data;
  } catch (err: any) {
    const errorMessage =
      err.response?.data?.validations?.[0]?.error ||
      err.response?.data?.error_message ||
      "Unknown error";
    toast.error("Error: " + errorMessage, {
      id: "steam-connect-err",
    });
    throw new Error(errorMessage);
  }
};

export const useAllNews = () => {
  return useQuery({
    queryKey: ["allNews"],
    queryFn: fetchAllNews,
    staleTime: 1 * 60 * 1000, // 1 минут данные считаются актуальными
    cacheTime: 30 * 60 * 1000, // 30 минут данные остаются в кеше
    retry: 0,
    refetchOnWindowFocus: false, // Не обновлять при возврате на вкладку
  });
};
