import { IButton } from "../types";
import React from "react";
import styles from "./style.module.scss";

const Button: React.FC<IButton> = ({
  type,
  icon,
  text,
  onClick,
  disabled,
  className,
  isCancelButton,
  customStyles,
  textCustomStyle,
  iconRight,
}) => {
  return (
    <button
      className={`${styles.button} ${
        isCancelButton && styles.cancelButton
      } ${className} ${disabled && styles.disabled}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={customStyles}
    >
      {iconRight ? null : icon}

      <p className={styles.button_text} style={textCustomStyle}>
        {text}
      </p>

      {iconRight ? icon : null}
    </button>
  );
};

export default Button;
