import { PieChart } from "react-minimal-pie-chart";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import styles from "./style.module.scss";
import { useImage } from "../../../../../../services/getImages";

interface ICS2Table {
  headings: string[];
  isMapsTable?: boolean;
  rows: (string | number)[][];
}

const CS2Table: React.FC<ICS2Table> = ({ headings, rows, isMapsTable }) => {
  return (
    <div className={styles.cs2_table_wrapper}>
      <div className={styles.table_headings_wrapper}>
        {headings.map((heading: string) => (
          <p key={heading} className={styles.table_heading}>
            {heading}
          </p>
        ))}
      </div>

      <div className={styles.table_body_wrapper}>
        {rows?.map((row) => {
          const percentage = typeof row[2] === "number" ? row[2] : 0;
          const { data: gunPhoto } = useImage(row[0].toString().toLowerCase());
          return (
            <div key={row[0]} className={styles.table_row}>
              <div className={styles.table_cell}>
                {" "}
                {!isMapsTable ? (
                  <div className={styles.gun_image_wrapper}>
                    <img
                      loading="lazy"
                      alt="Gun Photo"
                      className={styles.gun_image}
                      src={gunPhoto}
                    />
                  </div>
                ) : (
                  <MapRoundedIcon style={{ color: "#EFEFEF" }} />
                )}{" "}
                {row[0]}
              </div>
              <div className={styles.table_cell}>{row[1]}</div>
              <div className={styles.table_cell}>
                {!isMapsTable && (
                  <div className={styles.pie_chart_wrapper}>
                    <PieChart
                      data={[
                        { title: "One", value: percentage, color: "#4655f4" },
                        {
                          title: "Two",
                          value: 100 - percentage,
                          color: "#1d1d23",
                        },
                      ]}
                      startAngle={270}
                    />
                  </div>
                )}
                {Math.round(percentage)}%
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CS2Table;
