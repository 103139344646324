import toast from "react-hot-toast";
import { client } from "./config/index";
import { useMutation } from "@tanstack/react-query";

// --------------------- GET ALL USER'S STATS ---------------------
interface IGetUserStats {
  type: string;
  game_id: string;
  user_id: string;
  additional_data?: any;
}

export const useGetUserStatsMutation = () =>
  useMutation({
    mutationFn: (data: IGetUserStats) => client.post("/v1/user-stats", data),
    onError: (err: any) => {
      if (err.response.status !== 404 && err.response.status !== 500) {
        toast.error("Error: " + err.response?.data?.error_message);
      }
    },
  });
