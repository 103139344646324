const UPDATE_NOTIF = "UPDATE_NOTIF";

export interface INOTIF {
	riot: boolean;
	steam: boolean;
}

const defaultState = {
	riot: false,
	steam: false,
};

export function notification(state = defaultState, action: any) {
	const data = action.payload;

	switch (action.type) {
		case UPDATE_NOTIF:
			return { ...state, ...data };
		default:
			return state;
	}
}

export const updateNotificationAction = (payload: INOTIF) => ({
	type: UPDATE_NOTIF,
	payload,
});
