export const Riot = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2134_8639)">
      <path
        d="M12.1371 18.4914L11.4057 16.6171L18.4457 16.9829L18.1257 20L12.1371 18.4914ZM13.8286 4L4 8.66286L5.50857 15.6571H7.52L7.2 10.6286L7.47429 10.5371L8.70857 15.6571H10.7657L10.72 9.53143L10.9943 9.44L12.0457 15.6571H14.2857L14.7886 8.29714L15.1086 8.16L15.6571 15.6571H18.5371L19.5886 5.41714L13.8286 4Z"
        fill="#DB3837"
      />
    </g>
    <defs>
      <clipPath id="clip0_2134_8639">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
