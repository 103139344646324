import HiringStatusCheck from "./HiringStatusCheck";
import HiringStatusLabel from "./HiringStatusLabel";
import { TeamContext } from "../../../contexts/TeamContext";
import { useContext } from "react";

interface HiringStatusProps {
  canEdit: boolean;
}

export default function HiringStatus({ canEdit }: HiringStatusProps) {
  const { teamData } = useContext(TeamContext);

  if (canEdit) {
    return <HiringStatusCheck />;
  }
  return <HiringStatusLabel isHiring={teamData?.data?.is_hiring} />;
}
