import Button from "../../../components/Button";
import { TeamContext } from "../../../contexts/TeamContext";
import { useContext } from "react";
import usePermission from "../../../hooks/usePermission";

export default function TeamPost() {
  const { checkPermission } = usePermission();
  const { setIsPostCreateOpen } = useContext(TeamContext);

  if (!checkPermission("team/post/create")) return null;

  return (
    <Button
      text="Create Post"
      customStyles={{
        width: "150px",
      }}
      onClick={() => setIsPostCreateOpen && setIsPostCreateOpen(true)}
    />
  );
}
