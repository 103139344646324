import moment from "moment";

export const formatRelativeTime = (date: string) => {
  const res = moment(date).startOf("hour").fromNow();

  return res;
};

export const time2TimeAgo = (ts: number) => {
  const d = new Date();
  const nowTs = Math.floor(d.getTime() / 1000);
  const seconds = nowTs - ts;

  if (seconds > 24 * 3600 * 365) {
    const year = Math.floor(seconds / 3600 / 24 / 365);
    return `${year} year${year > 1 ? "s" : ""} ago`;
  }
  if (seconds > 24 * 3600) {
    const day = Math.floor(seconds / 3600 / 24);
    return `${day} day${day > 1 ? "s" : ""} ago`;
  }
  if (seconds > 3600) {
    const hour = Math.floor(seconds / 3600);
    return `${hour} hour${hour > 1 ? "s" : ""} ago`;
  }
  if (seconds > 1800) {
    return "30 min ago";
  }
  if (seconds > 60) {
    return `${Math.floor(seconds / 60)} min ago`;
  }

  return `${seconds} sec ago`;
};
