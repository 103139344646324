export const Steam = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="20"
      viewBox="0 0 67 20"
      fill="none"
    >
      <path
        d="M10.7712 0.0962524C5.58781 0.0962524 1.3415 4.10776 0.937988 9.20576L6.22664 11.4005C6.67476 11.0929 7.21612 10.9126 7.79851 10.9126C7.85071 10.9126 7.90268 10.9144 7.95421 10.9173L10.3061 7.49576C10.3061 7.47941 10.3059 7.46352 10.3059 7.4474C10.3059 5.38791 11.975 3.71239 14.0271 3.71239C16.079 3.71239 17.7481 5.38791 17.7481 7.4474C17.7481 9.50688 16.079 11.1826 14.0271 11.1826C13.9988 11.1826 13.9707 11.182 13.9426 11.1813L10.5883 13.5835C10.5901 13.6272 10.5916 13.6718 10.5916 13.7161C10.5916 15.2622 9.33871 16.5195 7.79851 16.5195C6.44657 16.5195 5.31613 15.551 5.06006 14.2684L1.27793 12.699C2.44897 16.856 6.25408 19.9037 10.7712 19.9037C16.2209 19.9037 20.6385 15.4695 20.6385 10.0003C20.6385 4.53022 16.2207 0.0962524 10.7712 0.0962524Z"
        fill="white"
      />
      <path
        d="M7.12126 15.1236L5.90918 14.621C6.12398 15.0699 6.49559 15.4458 6.98899 15.6522C8.05564 16.0982 9.28535 15.5902 9.7299 14.5187C9.94515 14.0006 9.94649 13.4286 9.73302 12.9092C9.52001 12.3896 9.11806 11.9843 8.60124 11.7681C8.08843 11.5538 7.53905 11.5617 7.05636 11.7446L8.30837 12.2642C9.09508 12.5933 9.46714 13.5 9.13925 14.2897C8.81203 15.0795 7.90798 15.4529 7.12126 15.1236Z"
        fill="white"
      />
      <path
        d="M16.5063 7.44741C16.5063 6.07524 15.3942 4.95874 14.0269 4.95874C12.6597 4.95874 11.5474 6.07524 11.5474 7.44741C11.5474 8.8198 12.6597 9.93585 14.0269 9.93585C15.3942 9.93562 16.5063 8.81958 16.5063 7.44741ZM12.1684 7.44315C12.1684 6.41061 13.0024 5.57374 14.0311 5.57374C15.0598 5.57374 15.8938 6.41061 15.8938 7.44315C15.8938 8.47569 15.0598 9.31256 14.0311 9.31256C13.0024 9.31256 12.1684 8.47547 12.1684 7.44315Z"
        fill="white"
      />
      <path
        d="M31.1761 6.87247L30.515 8.03889C30.0058 7.6818 29.3156 7.46688 28.7132 7.46688C28.0246 7.46688 27.5986 7.753 27.5986 8.26546C27.5986 8.88808 28.3554 9.03293 29.4803 9.43815C30.6894 9.86733 31.3845 10.3717 31.3845 11.4833C31.3845 13.0041 30.1929 13.8582 28.4803 13.8582C27.6456 13.8582 26.6387 13.642 25.8647 13.1694L26.347 11.8762C26.9758 12.2096 27.7279 12.4073 28.3984 12.4073C29.3023 12.4073 29.7319 12.0726 29.7319 11.5778C29.7319 11.0116 29.0767 10.8414 28.0192 10.4899C26.8141 10.0861 25.979 9.55614 25.979 8.32591C25.979 6.93852 27.086 6.14172 28.6788 6.14172C29.789 6.14172 30.6812 6.49478 31.1761 6.87247Z"
        fill="white"
      />
      <path
        d="M36.3443 7.60972V13.7304H34.7876V7.60972H32.5281V6.26956H38.5972V7.60972H36.3443Z"
        fill="white"
      />
      <path
        d="M41.8788 7.60569V9.28681H44.8579V10.627H41.8788V12.3779H45.3352V13.7304H40.3225V6.26956H45.3352V7.60569H41.8788Z"
        fill="white"
      />
      <path
        d="M48.81 12.2819L48.3179 13.7306H46.686L49.4731 6.26978H51.0401L53.9059 13.7304H52.2189L51.7162 12.2817L48.81 12.2819ZM50.2478 8.04941L49.2327 11.0367H51.2839L50.2478 8.04941Z"
        fill="white"
      />
      <path
        d="M61.8951 9.15338L59.8608 13.513H58.9824L56.9812 9.19233V13.7304H55.4905V6.26956H56.9778L59.476 11.6553L61.8855 6.26956H63.3858V13.7304H61.8953L61.8951 9.15338Z"
        fill="white"
      />
      <path
        d="M66.3952 7.21075C66.3952 7.85195 65.9165 8.25158 65.3671 8.25158C64.8157 8.25158 64.3359 7.85195 64.3359 7.21075C64.3359 6.56978 64.8157 6.17284 65.3671 6.17284C65.9167 6.17284 66.3952 6.56978 66.3952 7.21075ZM64.5088 7.21075C64.5088 7.74717 64.8938 8.08411 65.3671 8.08411C65.8387 8.08411 66.2221 7.74717 66.2221 7.21075C66.2221 6.67276 65.8387 6.34097 65.3671 6.34097C64.8951 6.34097 64.5088 6.67679 64.5088 7.21075ZM65.3814 6.68038C65.648 6.68038 65.7376 6.82187 65.7376 6.97478C65.7376 7.11493 65.6549 7.20896 65.554 7.25687L65.7929 7.7062H65.5973L65.3959 7.30792H65.1882V7.7062H65.0254V6.68038H65.3814ZM65.1898 7.15769H65.3714C65.4889 7.15769 65.5583 7.08359 65.5583 6.99045C65.5583 6.89642 65.5085 6.83597 65.3709 6.83597H65.1896V7.15769H65.1898Z"
        fill="white"
      />
    </svg>
  );
};
