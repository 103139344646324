import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import Dropzone from "../../../../components/Dropzone";
import { IPostCreate } from "../types";
import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import Textarea from "../../../../components/Textarea";
import { checkTextarea } from "../../../helper/validation";
import { createPostAction } from "../../../../store/postReducer";
import { setBinaryImage } from "../../../../helpers/setBinaryImage";
import styles from "./style.module.scss";
import { useCreatePostMutation } from "../../../../services/post";
import { useGetAllGamesQuery } from "../../../../services/game";

const PostCreate: React.FC<IPostCreate> = ({
  open,
  handleClose,
  teamId,
  gameId,
  type,
}) => {
  const dispatch = useDispatch();
  const getGames = type == "team" ? null : useGetAllGamesQuery({});
  const createPost = useCreatePostMutation();
  const user = useSelector((state: any) => state.user);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [games, setGames] = useState([{ id: "", title: "", logo: "" }]);
  const [image, setImage] = useState<{ id: string; img: any }>({
    id: "",
    img: null,
  });
  const [post, setPost] = useState({
    game: "",
    title: "",
    image: "",
    description: "",
  });

  const handleSelectImage = useCallback(async (acceptedImg: any) => {
    const imgId = await setBinaryImage(acceptedImg[0]);

    setImage({
      id: imgId || "",
      img: URL.createObjectURL(acceptedImg[0]),
    });
  }, []);

  const handleCancel = () => {
    setPost({
      game: "",
      title: "",
      image: "",
      description: "",
    });
    handleClose();
  };

  const handlePost = () => {
    createPost.mutate(
      {
        media: image.id,
        user_id: user.id,
        title: post.title,
        game_id: gameId ? gameId : post.game,
        content: post.description,
        team_id: teamId || "",
      },
      {
        onSuccess: (res: any) => {
          dispatch(createPostAction(res.data));
          handleCancel();
        },
        onError: (err: any) => {
          console.log("ERR", err);
        },
      },
    );
  };

  useEffect(() => {
    if (teamId) return;

    if (getGames?.data?.data?.games?.length) {
      setGames([
        ...getGames.data.data.games,
        { id: "0", title: "None", logo: "" },
      ]);
    }
  }, [getGames?.data]);

  useEffect(() => {
    if (teamId) {
      if (teamId && post.title && checkTextarea(post.description)) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      const titleLength = post.title.split(" ").join("").length;
      const desc = post.description.split(" ").join("");
      if (
        post.game &&
        post.title &&
        titleLength &&
        desc.length &&
        desc != "<p></p>" &&
        desc != "<p><br></p>"
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  }, [post]);

  return (
    <Modal
      open={open}
      width="610px"
      withFooter={false}
      close={handleCancel}
      title="Create a Post"
    >
      <div className={styles.post_create_wrapper}>
        <div className={styles.inputs_wrapper}>
          {teamId ? null : (
            <Dropdown
              withImage={true}
              options={games}
              className={styles.dropdown}
              label="Select Game Community"
              onGameSelect={(e: any) =>
                setPost((prev) => ({ ...prev, game: e }))
              }
            />
          )}
          <Input
            value={post.title}
            placeholder="Title"
            onChange={(e: any) =>
              setPost((prev) => ({ ...prev, title: e.target.value }))
            }
          />
          <Textarea
            isTop={true}
            value={post.description}
            placeholder="What’s on your mind, gamer?"
            onChange={(e: any) =>
              setPost((prev) => ({ ...prev, description: e }))
            }
          />

          <div className={styles.drag_area}>
            <img
              loading="lazy"
              src={image.img}
              className={styles.drag_area_image}
            />
            <div className={styles.dropzone_wrapper}>
              <Dropzone onDrop={handleSelectImage} />
            </div>
          </div>
        </div>

        <div className={styles.buttons_wrapper}>
          <Button
            text="Cancel"
            onClick={handleCancel}
            className={styles.cancel_button}
          />
          <Button
            text="Post"
            onClick={handlePost}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PostCreate;
