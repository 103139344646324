import axios from "axios";
import store from "../../store";
import { updateAuthAction } from "../../store/authReducer";

export const authClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// TO TAKE EVERYTIME UP TO DATE ACCESS_TOKEN
client.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(
      localStorage.getItem("auth") || "{}",
    ).access_token;

    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.data?.error_message === "expired jwt token") {
      const refresh_token = JSON.parse(
        localStorage.getItem("auth") || "{}",
      ).refresh_token;

      authClient
        .post("/v1/auth/refresh", { token: refresh_token })
        .then((res: any) => {
          store.dispatch(updateAuthAction(res.data));
          localStorage.setItem("auth", JSON.stringify(res.data));
        })
        .catch(() => {
          window.location.pathname = "/auth/sign-in";
        });

      return;
    }

    if (
      error?.response?.data?.error_message === "unathorized" ||
      error?.response?.data?.error_message === "invalid jwt token"
    ) {
      window.location.pathname = "/auth/sign-in";
    }
    return Promise.reject(error);
  },
);
