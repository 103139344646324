import React from "react";
import ReactQuill from "react-quill";
import { ITextarea } from "../types";
import "react-quill/dist/quill.snow.css";
import Button from "../../components/Button";
import EditorToolbar, { modules, formats } from "../Toolbar";
import styles from "./style.module.scss";
import "./styles.scss";

export const Editor: React.FC<ITextarea> = ({
	buttonDisabled,
	placeholder,
	buttonText,
	onChange,
	onClick,
	value,
	isTop,
}) => {
	return (
		<div className={styles.text_editor}>
			{isTop && (
				<div className={`${styles.toolbar_wrapper} ${styles.top_toolbar}`}>
					<EditorToolbar />
				</div>
			)}

			<div className={isTop ? styles.editor_bottom : styles.editor_top}>
				<ReactQuill
					value={value}
					modules={modules}
					formats={formats}
					onChange={onChange}
					placeholder={placeholder}
				/>
			</div>

			{!isTop && (
				<div className={`${styles.toolbar_wrapper} ${styles.bottom_toolbar}`}>
					<EditorToolbar />

					<Button
						text={buttonText}
						onClick={onClick}
						className={styles.textarea_button}
						disabled={buttonDisabled}
					/>
				</div>
			)}
		</div>
	);
};

export default Editor;

// import React, { useState, useEffect } from "react";
// import { ITextarea } from "../types";
// import { EditorState, Modifier } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import SendRoundedIcon from "@mui/icons-material/SendRounded";
// import styles from "./style.module.scss";

// const Textarea: React.FC<ITextarea> = ({
// 	rows,
// 	value,
// 	isTop,
// 	onChange,
// 	className,
// 	placeholder,
// }) => {
// 	const [editorState, setEditorState] = useState(() =>
// 		EditorState.createEmpty()
// 	);

// 	useEffect(() => {
// 		onChange(editorState);
// 	}, [editorState]);

// 	const handleChange = (e: any) => {
// 		// e.target.style.height = "auto";
// 		// e.target.style.height = e.target.scrollHeight + "px";
// 		// setEditorState(e.target.value);
// 		console.log("E", e);
// 	};

// 	return (
// 		<form className={`${styles.textarea_wrapper} ${className}`}>
// 			<Editor
// 				editorState={editorState}
// 				onEditorStateChange={setEditorState}
// 				onChange={(e: any) => onChange(e.blocks[0].text)}
// 				editorClassName={`${styles.editor} ${!isTop && styles.down_editor}`}
// 				toolbarClassName={`${styles.toolbar} ${!isTop && styles.down_toolbar}`}
// 				wrapperClassName={`${styles.editor_wrapper} ${
// 					!isTop && styles.reverse
// 				}`}
// 				toolbar={{
// 					options: ["history", "inline", "list", "textAlign"],
// 					inline: {
// 						options: ["bold", "italic", "underline", "strikethrough"],
// 						bold: {
// 							className: styles.toolbar_button,
// 						},
// 					},
// 					list: {
// 						options: ["unordered", "ordered"],
// 					},
// 				}}
// 			/>
// 		</form>
// 	);
// };

// export default Textarea;
