import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import { navItems } from "../helper/demo";
import Button from "../../../components/Button";
import logo from "../../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCurrentUserQuery } from "../../../services/user";
import { getUserAction } from "../../../store/userReducer";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(location.pathname.split("/")[2]);
  }, [location.pathname]);

  const handleDashboardClick = () => {
    if (!location.pathname.includes("/app/dashboard/home")) {
      getCurrentUserQuery()
        .then((res: any) => {
          dispatch(getUserAction(res.data));
        })
        .catch((err: any) => {
          if (err?.response?.status === 500) {
            toast.error(
              "Sorry, but something bad happened on our side\nYou will be redirected to login page",
              { duration: 7000 },
            );
            setTimeout(() => {
              localStorage.setItem("auth", "{}");
              navigate("/auth/sign-in");
            }, 7000);
          } else if (
            err?.response?.data?.error_message === "Not Found" ||
            err?.response?.data?.error_code === 404
          ) {
            navigate("/auth/sign-in");
          }
        });
    }
  };

  return (
    <aside className={styles.sidebar}>
      <div className={styles.sidebar_inner}>
        <nav className={styles.nav}>
          <div className={styles.logo_wrapper}>
            <Link to="/app/dashboard/home">
              <img loading="lazy" src={logo} alt="Logo" />
            </Link>
          </div>

          <div className={styles.nav_list}>
            {navItems.map((e) => {
              const path = e.text.toLocaleLowerCase().replaceAll(" ", "-");
              const isActive = active === path;

              return (
                <Link
                  to={`/app/${path === "dashboard" ? "dashboard/home/" : path}`}
                  key={e.id}
                  className={styles.nav_link}
                >
                  <Button
                    text={e.text}
                    icon={e.icon(styles.nav_icon)}
                    onClick={path === "dashboard" ? handleDashboardClick : null}
                    className={`${styles.nav_item} ${
                      isActive && styles.active_item
                    }`}
                  />
                </Link>
              );
            })}
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
