import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../store/userReducer";
import { getCurrentUserQuery } from "../../services/user";

const Wrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    const access_token = JSON.parse(localStorage.getItem("auth") || "{}")
      ?.access_token;
    if (!access_token) {
      navigate("/auth/sign-in");
    }
  }, []);

  useEffect(() => {
    getCurrentUserQuery()
      .then((res: any) => {
        dispatch(getUserAction(res.data));

        if (window.location.pathname === "/") {
          navigate("/app/dashboard/home");
        }
      })
      .catch((err: any) => {
        if (err?.response?.status === 500) {
          toast.error(
            "Sorry, but something bad happened on our side\nYou will be redirected to login page",
            { duration: 7000 },
          );
          setTimeout(() => {
            localStorage.setItem("auth", "{}");
            navigate("/auth/sign-in");
          }, 7000);
        } else if (
          err?.response?.data?.error_message === "Not Found" ||
          err?.response?.data?.error_code === 404
        ) {
          navigate("/auth/sign-in");
        }
      });
  }, [auth.access_token]);

  return (
    <div>
      <Toaster position="top-right" />
      <Outlet />
    </div>
  );
};

export default Wrapper;
