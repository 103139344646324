import usePermission from "../hooks/usePermission";

export const canUpdateRole = (role: string) => {
  const { checkPermission } = usePermission();

  if (role == "Member") {
    return (
      checkPermission("team/member/role/set") ||
      checkPermission("team/member/assign/owner") ||
      checkPermission("team/member/assign/captain") ||
      checkPermission("team/member/assign/coach")
    );
  } else if (role == "Captain") {
    return checkPermission("team/member/revoke/captain");
  } else if (role == "Coach") {
    return checkPermission("team/member/revoke/coach");
  }
};
