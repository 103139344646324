import { Link, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import GameCard from "../../../../components/GameCard";
import GameTableRow from "../../../../components/TableRow/GameTableRow";
import { useMyGamesQuery } from "../../../../services/myGames";
import styles from "./myGameContainer.module.scss";

const UserGames = () => {
  const { userId, steamId } = useParams();

  const { data: games } = useMyGamesQuery({
    params: {
      id: userId || "",
      steam_id: steamId || "",
    },
    querySettings: {
      enabled: !!userId || !!steamId,
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        {games?.data?.core_games?.map(
          (coreGame: {
            id: string;
            game_id: string;
            game_logo: string;
            game_title: string;
            game_data: {
              playtime_2weeks: number;
              playtime_forever: number;
            };
          }) => (
            <Link
              key={coreGame?.id}
              to={`/app/dashboard/game/${coreGame?.game_id}`}
            >
              <GameCard
                game_title={coreGame?.game_title}
                bgImg={coreGame?.game_logo || ""}
                playtime_2weeks={coreGame?.game_data?.playtime_2weeks || 0}
                playtime_forever={coreGame?.game_data?.playtime_forever || 0}
              />
            </Link>
          ),
        )}
      </div>

      <div className={styles.centerContainer}>
        <Typography color="grey">
          {games?.data?.other_games?.length &&
            `${games?.data?.other_games?.length} Games`}
        </Typography>
      </div>
      <div className={styles.bottomContainer}>
        {games?.data?.other_games?.map(
          (
            otherGame: {
              name: string;
              appid: string;
              img_icon_url: string;
              playtime_2weeks: number;
              playtime_forever: number;
            },
            i: number,
          ) => (
            <GameTableRow
              rowNumber={i + 1}
              key={otherGame?.appid}
              name={otherGame?.name}
              totalTime={otherGame?.playtime_forever / 60}
              lastTwoWeeks={otherGame?.playtime_2weeks / 60}
              img={`http://media.steampowered.com/steamcommunity/public/images/apps/${otherGame?.appid}/${otherGame?.img_icon_url}.jpg`}
            />
          ),
        )}
      </div>

      <div className={styles.disclaimer_wrapper}>
        <p className={styles.disclaimer_title}>Disclaimer</p>
        <p className={styles.disclaimer_text}>
          GameDrive is not endorsed by Riot Games and does not reflect the views
          or opinions of Riot Games or anyone officially involved in producing
          or managing Riot Games properties. Riot Games and all associated
          properties are trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div>
    </div>
  );
};

export default UserGames;
