import toast from "react-hot-toast";
import { ILogin, ISignUp } from "./types";
import { authClient } from "./config/index";
import { useMutation } from "@tanstack/react-query";

export const useSignUpMutation = () =>
  useMutation({
    mutationFn: (data: ISignUp) => {
      return authClient.post("/v1/auth/register", data);
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "login-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "login-err",
        });
      }
    },
  });

export const useSteamLoginMutation = () =>
  useMutation({
    mutationFn: (data: object) => authClient.post("/v1/auth/steam/login", data),
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "login-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "login-err",
        });
      }
    },
  });

export const useLoginMutation = () =>
  useMutation({
    mutationFn: (data: ILogin) => {
      return authClient.post("/v1/auth/login", data);
    },
    onError: (err: any) => {
      if (err.response?.data?.validations?.length) {
        toast.error("Error: " + err.response?.data?.validations[0]?.error, {
          id: "login-err",
        });
      } else {
        toast.error("Error: " + err.response?.data?.error_message, {
          id: "login-err",
        });
      }
    },
  });
