import { IDropdown, IOption } from "../types";
import React, { useEffect, useState } from "react";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Option from "../Option";
import styles from "./style.module.scss";
import { useImage } from "../../services/getImages";

const Dropdown: React.FC<IDropdown> = ({
  onGameSelect,
  className,
  withImage,
  onSelect,
  options,
  label,
  width,
  isDarkColored,
  textCustomStyle,
  customIsOpen,
  setCustomIsOpen,
  parentControl,
}) => {
  const [val, setVal] = useState("");
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);

  const { data: userImage } = useImage(image);

  const handleOpen = (e: any) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleOutsideClick = () => setOpen(false);

  const handleSelect = ({ id, img = "", text }: IOption) => {
    if (onSelect) {
      onSelect(text);
    } else {
      onGameSelect(id);
    }

    setVal(text);
    setImage(img);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={`${styles.dropdown_wrapper} ${className}`}
      onClick={(e) => {
        if (parentControl) {
          setCustomIsOpen((prev: boolean) => !prev);
          return;
        }
        handleOpen(e);
      }}
      style={{
        width: width ?? "",
        backgroundColor: isDarkColored ? "#1D1D23" : "",
      }}
    >
      {withImage && (
        <div className={styles.image_wrapper}>
          {image && (
            <img
              loading="lazy"
              className={styles.image}
              src={userImage}
              alt="game"
            ></img>
          )}
        </div>
      )}

      <div className={styles.dropdown}>
        <span
          className={`${styles.dropdown_label} ${
            ((parentControl ? customIsOpen : open) || val) && styles.label_top
          }`}
        >
          {label}
        </span>

        {val && (
          <span style={textCustomStyle} className={styles.dropdown_value}>
            {val}
          </span>
        )}

        <KeyboardArrowDownRoundedIcon className={styles.dropdown_icon} />
      </div>

      {(parentControl ? customIsOpen : open) ? (
        <div className={styles.menu_wrapper}>
          <ul className={styles.menu}>
            {options.map((e: any) => (
              <Option
                id={e.id}
                key={e.id}
                img={e.logo}
                text={e.title}
                handleSelect={handleSelect}
              />
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
