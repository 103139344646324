import { createContext } from "react";

interface TeamContextType {
  teamDescription: string;
  setTeamDescription: React.Dispatch<React.SetStateAction<string>>;
  handleDescriptionSave: () => void;
  savedTeamData: any;
  joinTeamText: string;
  setJoinTeamText?: React.Dispatch<React.SetStateAction<string>>;
  deleteMemberMutate: any;
  teamData: any;
  permissions: any;
  setBannerId: React.Dispatch<React.SetStateAction<string>>;
  setLogoId: React.Dispatch<React.SetStateAction<string>>;
  setIsPostCreateOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchTeamMembers?: () => void;
  refetchRosters?: () => void;
  setIsHiring: React.Dispatch<
    React.SetStateAction<{
      isHiring: boolean;
      status: string;
    }>
  >;
  isHiring: {
    isHiring: boolean;
    status: string;
  };
  refetchPosts: () => void;
  fetchNextMembers: () => void;
}

export const TeamContext = createContext<TeamContextType>({
  teamDescription: "",
  setTeamDescription: () => {},
  handleDescriptionSave: () => {},
  savedTeamData: {},
  joinTeamText: "",
  deleteMemberMutate: () => {},
  teamData: {},
  permissions: new Map(),
  setBannerId: () => {},
  setLogoId: () => {},
  setIsPostCreateOpen: () => {},
  refetchTeamMembers: () => {},
  refetchRosters: () => {},
  setIsHiring: () => {},
  isHiring: {
    isHiring: false,
    status: "",
  },
  refetchPosts: () => {},
  fetchNextMembers: () => {},
});
