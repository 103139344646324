import { useEffect, useState } from "react";
import AuthBlock from "../../Block";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import { useGoogleLogin } from "@react-oauth/google";
import { Google } from "../../../../assets/icons/Google";
import { Steam } from "../../../../assets/icons/SteamAuth";
import { updateAuthAction } from "../../../../store/authReducer";
import { isPasswordCorrect, isUsernameCorrect } from "../../utils/helper";
import {
  useLoginMutation,
  useSteamLoginMutation,
} from "../../../../services/auth";
import styles from "../style.module.scss";
import toast from "react-hot-toast";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postLogin = useLoginMutation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const getSteamLogin = useSteamLoginMutation();
  const [recaptcha, setRecaptcha] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [formError, setFormError] = useState({
    usernameError: {
      text: "",
      isError: false,
    },
    passwordError: {
      text: "",
      isError: false,
    },
  });

  useEffect(() => {
    if (username && password && recaptcha) {
      setBtnDisabled(
        isUsernameCorrect(username) && isPasswordCorrect(password),
      );
    }
  }, [username, password, recaptcha]);

  const handleInputChange = () => {
    setFormError({
      usernameError: {
        text: "",
        isError: false,
      },
      passwordError: {
        text: "",
        isError: false,
      },
    });
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    setBtnDisabled(false);
    if (
      isUsernameCorrect(username, setFormError) &&
      isPasswordCorrect(password, setFormError)
    ) {
      const data = {
        type: "username",
        captcha_token: recaptcha ?? "",
        steamId: "",
        username,
        password,
      };

      postLogin.mutate(data, {
        onSuccess: (res) => {
          const auth = {
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          };

          localStorage.setItem("auth", JSON.stringify(auth));
          dispatch(updateAuthAction(auth));
          navigate("/app/dashboard/home");
        },
      });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (res) => {
      const data = {
        steam_id: "",
        username: "",
        password: "",
        type: "google",
        google_token: res.access_token,
      };

      sessionStorage.setItem("google_token", res.access_token);
      sessionStorage.setItem("type", "google");

      postLogin.mutate(data, {
        onSuccess: (res) => {
          const auth = {
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          };

          localStorage.setItem("auth", JSON.stringify(auth));
          dispatch(updateAuthAction(auth));
          navigate("/app/dashboard/home");
        },
        onError: (err: any) => {
          if (err.response?.data?.validations[0]?.error === "user not exists") {
            toast.error(
              "No Game Drive users are currently linked to this Google account. Please create an account or try a different login method.",
              {
                id: "login-err",
                duration: 7000,
              },
            );
            navigate("/auth/sign-up/info");
          }
        },
      });
    },
  });

  const steamLogin = () => {
    getSteamLogin.mutate(
      {},
      {
        onSuccess: (res) => {
          sessionStorage.setItem("type", "steam");
          window.location.href = res.data;
        },
      },
    );
  };

  return (
    <AuthBlock isSignIn={true} title="Log In">
      <form className={styles.content_wrapper}>
        <div className={styles.login_wrapper}>
          <div className={styles.inputs_wrapper}>
            <Input
              value={username}
              placeholder="Username"
              isError={formError.usernameError.isError}
              errorMessage={formError.usernameError.text}
              onBlur={() => isUsernameCorrect(username, setFormError)}
              onChange={(e: any) => {
                handleInputChange();
                setUsername(e.target.value);
              }}
            />

            <Input
              type="password"
              value={password}
              placeholder="Password"
              isError={formError.passwordError.isError}
              errorMessage={formError.passwordError.text}
              onBlur={() => isPasswordCorrect(password, setFormError)}
              onChange={(e: any) => {
                handleInputChange();
                setPassword(e.target.value);
              }}
            />

            <ReCAPTCHA
              theme="dark"
              style={{ margin: "0 auto" }}
              onChange={(e: any) => setRecaptcha(e)}
              sitekey={process.env.REACT_APP_RECAPCHA_KEY!}
            />
          </div>

          <div className={styles.login_options_wrapper}>
            <p className={styles.login_options_heading}>Continue with</p>

            <div className={styles.login_options_inner}>
              <button
                type="button"
                onClick={() => googleLogin()}
                className={styles.login_option}
              >
                <Google />
              </button>

              <button
                type="button"
                onClick={steamLogin}
                className={styles.login_option}
              >
                <Steam />
              </button>
            </div>
          </div>
        </div>

        <div className={styles.button_wrapper}>
          <Button
            type="submit"
            text="Log In"
            disabled={!btnDisabled}
            onClick={handleLogin}
          />
        </div>
      </form>
    </AuthBlock>
  );
};

export default SignIn;
