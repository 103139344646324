import { useNavigate } from "react-router-dom";
import AuthBlock from "../../Block";
import { useGoogleLogin } from "@react-oauth/google";
import { Steam } from "../../../../assets/icons/SteamAuth";
import { Google } from "../../../../assets/icons/Google";
import {
  useLoginMutation,
  useSteamLoginMutation,
} from "../../../../services/auth";
import styles from "../style.module.scss";
import { updateAuthAction } from "../../../../store/authReducer";
import { useDispatch } from "react-redux";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const postLogin = useLoginMutation();
  const getSteamLogin = useSteamLoginMutation();

  const googleSignUp = useGoogleLogin({
    onSuccess: (res) => {
      const data = {
        steam_id: "",
        username: "",
        password: "",
        type: "google",
        google_token: res.access_token,
      };

      sessionStorage.setItem("google_token", res.access_token);
      sessionStorage.setItem("type", "google");

      postLogin.mutate(data, {
        onSuccess: (res: any) => {
          const auth = {
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          };

          localStorage.setItem("auth", JSON.stringify(auth));
          dispatch(updateAuthAction(auth));
          navigate("/app/dashboard/home");
        },
        onError: (err: any) => {
          if (err.response?.data?.validations[0]?.error === "user not exists") {
            navigate("/auth/sign-up/info");
          }
        },
      });
    },
  });

  const steamSignUp = () => {
    const data = {};

    getSteamLogin.mutate(data, {
      onSuccess: (res: any) => {
        sessionStorage.setItem("type", "steam");
        window.location.href = res.data;
      },
    });
  };

  return (
    <AuthBlock title="Sign Up">
      <div className={styles.options_wrapper}>
        <div className={styles.options_inner}>
          <button className={styles.option} onClick={() => googleSignUp()}>
            <Google />
          </button>

          <button className={styles.option} onClick={steamSignUp}>
            <Steam />
          </button>
        </div>

        <p className={styles.options_text}>
          For security purposes, all Game Drive accounts must be linked to
          either a Google or Steam account.
        </p>
      </div>
    </AuthBlock>
  );
};

export default SignUp;
