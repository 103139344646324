import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import { client } from "./config";

const teamService = {
  setTeam: async (data: object) => {
    return await client.post("/v1/team", data);
  },
  getTeams: async (limit: number, gameId?: string, user_id?: string) => {
    return await client.get(
      `/v1/team?game_id=${gameId}&limit=${limit}&user_id=${user_id}`,
    );
  },
  getSingleTeam: async (id: string) => {
    return await client.get(`/v1/team/${id}`);
  },
  updateTeam: async (id: string, data: object) => {
    return await client.put(`/v1/team/${id}`, data);
  },
  getTeamPosts: async (params: { teamId?: string; gameId?: string }) => {
    return await client.get(
      `/v1/post?team_id=${params?.teamId ?? ""}&limit=100&game_id=${
        params?.gameId || ""
      }`,
    );
  },
  getTeamMembers: async (
    teamId: string,
    isRoster?: number,
    search?: string,
    limit?: number,
    page?: number,
  ) => {
    return await client.get(
      `/v1/team-member?team_id=${teamId}${search ? `&search=${search}` : ""}${
        limit ? `&limit=${limit}` : ""
      }${page ? `&page=${page}` : ""}${
        isRoster == undefined ? "" : `&is_roster=${isRoster}`
      }`,
    );
  },
  getTeamRoles: async (id: string) => {
    return await client.get(`/v1/team-role?team_id=${id}`);
  },
  setJoinTeam: async (data: object) => {
    return await client.post(`/v1/team-member/team-request`, data);
  },
  getJoinRequests: async (
    teamId: string,
    limit: number,
    page: number,
    path: string,
  ) => {
    return await client.get(
      `/v1/team-member/team-request?team_id=${teamId}&limit=${limit}&page=${page}&path=${path}`,
    );
  },
  updateJoinRequest: async (id: string, data: object) => {
    return await client.put(`/v1/team-member/team-request/${id}`, data);
  },
  deleteTeamMember: async (
    id: string,
    data: {
      teamId: string;
      path?: string;
      id: string;
    },
  ) => {
    return await client.delete(`/v1/team-member/${id}`, {
      data: {
        team_id: data?.teamId,
        path: data?.path,
      },
    });
  },
  updateRole: async (id: string, data: object) => {
    return await client.put(`/v1/team-member/${id}`, data);
  },
  getAllUsers: async (search?: string) => {
    return await client.get(`/v1/user?search=${search}`);
  },
  setNewTeamMember: async (data: {
    user_id: string;
    team_id: string;
    role_id: string;
    path: string;
  }) => {
    return await client.post(`/v1/team-member`, data);
  },
};

export const getAllUsersQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useQuery(
    ["users"],
    () => {
      return teamService.getAllUsers(queryParams?.search);
    },
    querySettings,
  );
};

export const setNewTeamMemberMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation(
    (data: {
      user_id: string;
      team_id: string;
      role_id: string;
      path: string;
    }) => {
      return teamService.setNewTeamMember(data);
    },
    mutationSettings,
  );
};

export const setTeamMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation((data: object) => {
    return teamService.setTeam(data);
  }, mutationSettings);
};

export const getTeamsQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useQuery(
    ["teams"],
    () => {
      return teamService.getTeams(
        queryParams.limit,
        queryParams.gameId,
        queryParams.user_id,
      );
    },
    querySettings,
  );
};

export const getSingleTeamQuery = ({
  querySettings,
  id,
}: {
  querySettings?: any;
  id: string;
}) => {
  return useQuery(
    ["team", id],
    () => teamService.getSingleTeam(id),
    querySettings,
  );
};

export const updateTeamMutation = ({
  id,
  mutationSettings,
}: {
  mutationSettings?: any;
  id: string;
}) => {
  return useMutation((data: object) => {
    return teamService.updateTeam(id, data);
  }, mutationSettings);
};

export const getTeamPostsQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useQuery(
    ["teamPosts"],
    () => teamService.getTeamPosts(queryParams),
    querySettings,
  );
};

export const getTeamMembersQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useInfiniteQuery(
    ["teamMembers", queryParams?.teamId],
    ({ pageParam = 1 }) =>
      teamService.getTeamMembers(
        queryParams?.teamId,
        queryParams?.isRoster,
        queryParams?.search,
        queryParams?.limit,
        pageParam,
      ),
    {
      ...querySettings,
      getNextPageParam: (lastPage: any, allPages: any) => {
        const nextPage =
          lastPage?.data?.team_members?.length == queryParams?.limit
            ? allPages?.length + 1
            : undefined;

        return nextPage;
      },
    },
  );
};

export const getTeamRostersQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useInfiniteQuery(
    ["rosters", queryParams?.teamId],
    ({ pageParam = 1 }) =>
      teamService.getTeamMembers(
        queryParams?.teamId,
        queryParams?.isRoster,
        queryParams?.search,
        queryParams?.limit,
        pageParam,
      ),
    {
      ...querySettings,
      getNextPageParam: (lastPage: any, allPages: any) => {
        const nextPage =
          lastPage?.data?.team_members?.length == queryParams?.limit
            ? allPages?.length + 1
            : undefined;

        return nextPage;
      },
    },
  );
};

export const getMembersSearchQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useQuery(
    ["memberSearch", queryParams?.teamId],
    () =>
      teamService.getTeamMembers(
        queryParams?.teamId,
        queryParams?.isRoster,
        queryParams?.search,
      ),

    querySettings,
  );
};

export const getTeamRolesQuery = ({
  queryParams,
  querySettings,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useQuery(
    ["teamRoles"],
    () => teamService.getTeamRoles(queryParams?.id),
    querySettings,
  );
};

export const setJoinTeamMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation((data: object) => {
    return teamService.setJoinTeam(data);
  }, mutationSettings);
};

export const getJoinRequestsQuery = ({
  querySettings,
  queryParams,
}: {
  querySettings?: any;
  queryParams?: any;
}) => {
  return useQuery(
    ["joinRequests", queryParams?.teamId],
    () =>
      teamService.getJoinRequests(
        queryParams?.teamId,
        queryParams?.limit,
        queryParams?.page,
        queryParams?.path,
      ),
    querySettings,
  );
};

export const updateJoinRequestMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation(
    (data: {
      id: string;
      is_rejected: boolean;
      team_id: string;
      user_id: string;
      path?: string;
    }) => {
      return teamService.updateJoinRequest(data?.id, data);
    },
    mutationSettings,
  );
};

export const deleteTeamMemberMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation((data: { id: string; teamId: string; path?: string }) => {
    return teamService.deleteTeamMember(data?.id, data);
  }, mutationSettings);
};

export const updateRoleMutation = ({
  mutationSettings,
}: {
  mutationSettings?: any;
}) => {
  return useMutation(
    (data: {
      path: string;
      role_id: string;
      team_id: string;
      id: string;
      is_roster: boolean;
    }) => {
      return teamService.updateRole(data?.id, data);
    },
    mutationSettings,
  );
};
