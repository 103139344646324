const SHOW_SIDEBAR = "SHOW_SIDEBAR";

export function showSidebar(state = false, action: any) {
	switch (action.type) {
		case SHOW_SIDEBAR:
			return action.payload;
		default:
			return state;
	}
}

export const showSidebarAction = (payload: boolean) => ({
	type: SHOW_SIDEBAR,
	payload,
});
