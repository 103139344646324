import styles from "./style.module.scss";

interface LoaderProps {
  text?: string;
}

export default function Loader(props: LoaderProps) {
  const { text } = props;
  return (
    <div className={styles.no_news}>
      <div className={styles.dotted_loader}></div>
      <p className={styles.news_load}>{text}</p>
    </div>
  );
}
