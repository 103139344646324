import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Badge from "../../../components/Badge/Badge";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InvitePlayerModal from "./InvitePlayerModal";
import JoinRequestModal from "./JoinRequestModal";
import PlayerCard from "../../../components/Cards/PlayerCard";
import ScrollableBox from "../../../components/ScrollableBox/ScrollableBox";
import TeamMemberSearch from "./TeamMemberSearch";
import { getMembersSearchQuery } from "../../../services/team";
import styles from "./style.module.scss";
import { useParams } from "react-router-dom";
import usePermission from "../../../hooks/usePermission";

type TeamPlayersTabProps = {
  teamMembers: any;
  teamRosters: any;
  overallMembersCount: number;
  overallRostersCount: number;
  fetchNextMembers: any;
  fetchNextRosters: any;
};

export default function TeamPlayersTab({
  teamMembers,
  teamRosters,
  overallMembersCount,
  overallRostersCount,
  fetchNextMembers,
  fetchNextRosters,
}: TeamPlayersTabProps) {
  const { checkPermission } = usePermission();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isJoinRequestModalOpen, setIsJoinRequestModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [requestQuantity, setRequestQuantity] = useState(0);
  const {
    data: searchResults,
    refetch: refetchSearchResults,
    isFetching: isSearching,
  } = getMembersSearchQuery({
    queryParams: {
      teamId: id,
      search: searchValue,
    },
    querySettings: {
      enabled: !!id && searchValue.length > 0,
    },
  });

  useEffect(() => {
    if (searchValue.length === 0) {
      setIsSearchFocused(false);
      return;
    }

    const getAgain = setTimeout(() => {
      refetchSearchResults();
      setIsSearchFocused(true);
    }, 500);

    return () => {
      clearTimeout(getAgain);
    };
  }, [searchValue]);

  return (
    <Stack direction="column" width="100%" gap={2} position="relative">
      <div className={styles.search_wrapper} tabIndex={0}>
        <Input
          type="search"
          value={searchValue}
          placeholder="Search for users"
          onChange={(e: any) => setSearchValue(e.target.value)}
          onFocus={() => {
            if (searchValue?.length) {
              setIsSearchFocused(true);
            } else {
              setIsSearchFocused(false);
            }
          }}
        />

        {isSearchFocused && (
          <div className={styles.search_results_wrapper}>
            <TeamMemberSearch
              isSearching={isSearching}
              canGoToProfile
              searchResults={
                searchValue?.length == 0
                  ? null
                  : searchResults?.data?.team_members
              }
            />
          </div>
        )}
      </div>
      <JoinRequestModal
        isOpen={isJoinRequestModalOpen}
        setRequestQuantity={setRequestQuantity}
        onClose={() => {
          setIsJoinRequestModalOpen(false);
        }}
      />

      <InvitePlayerModal
        isOpen={isInviteModalOpen}
        onClose={() => {
          setIsInviteModalOpen(false);
        }}
      />

      <Stack direction="row" gap={2} alignItems="center">
        <Typography color="#8C8D94" fontSize="14px">
          Rosters
        </Typography>

        {(teamRosters.length == 0 || !teamRosters?.[0]) && (
          <Typography fontSize="13px" color="#8C8D94" sx={{ opacity: 0.5 }}>
            No members found
          </Typography>
        )}
      </Stack>

      {teamRosters && teamRosters?.[0] && (
        <ScrollableBox
          fetchData={fetchNextRosters}
          dataLength={teamRosters?.length}
          height={teamRosters?.length <= 9 ? "fit-content" : 580}
          items={
            <>
              {teamRosters?.map((member: any) => {
                if (!member) return null;
                return (
                  <PlayerCard player={member} key={member?.id} canGoToProfile />
                );
              })}
            </>
          }
          hasMore={overallRostersCount > teamRosters?.length}
        />
      )}

      <Stack gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Typography color="#8C8D94" fontSize="14px">
              Other players
            </Typography>

            {(teamMembers?.length == 0 || !teamMembers) && (
              <Typography fontSize="13px" color="#8C8D94" sx={{ opacity: 0.5 }}>
                No members found
              </Typography>
            )}
          </Stack>

          <Stack direction="row" gap={2}>
            {checkPermission("team/member/request/see") && (
              <Button
                isCancelButton
                text={`${requestQuantity < 2 ? "Request" : "Requests"}`}
                icon={<Badge label={requestQuantity} />}
                iconRight
                customStyles={{
                  width: "150px",
                  padding: "0 10px",
                }}
                onClick={() => {
                  setIsJoinRequestModalOpen(true);
                }}
              />
            )}
            {checkPermission("team/member/invite") && (
              <Button
                isCancelButton
                text="Invite Player"
                customStyles={{
                  width: "150px",
                }}
                onClick={() => {
                  setIsInviteModalOpen(true);
                }}
              />
            )}
          </Stack>
        </Stack>

        {teamMembers?.length > 0 ? (
          <ScrollableBox
            fetchData={fetchNextMembers}
            dataLength={teamMembers?.length}
            height={teamMembers?.length <= 9 ? "fit-content" : 580}
            items={
              <>
                {teamMembers?.map((member: any) => {
                  return (
                    <PlayerCard
                      key={member?.id}
                      player={member}
                      canGoToProfile
                    />
                  );
                })}
              </>
            }
            hasMore={overallMembersCount > teamMembers?.length}
          />
        ) : (
          <Stack
            width="100%"
            direction="column"
            gap={1}
            alignItems="center"
            py={10}
          >
            <Typography color="#EFEFEF" fontWeight={700}>
              There are no team members
            </Typography>
            <Typography color="#8C8D94" fontSize="14px">
              Team has not added any members yet.
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
