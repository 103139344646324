import {
  getAllUsersQuery,
  setNewTeamMemberMutation,
} from "../../../services/team";
import { useEffect, useState } from "react";

import Button from "../../../components/Button";
import CustomModal from "../../../components/Modal/CustomModal";
import Input from "../../../components/Input";
import { Stack } from "@mui/material";
import { TeamContext } from "../../../contexts/TeamContext";
import TeamMemberSearch from "./TeamMemberSearch";
import toast from "react-hot-toast";
import { useContext } from "react";
import { useParams } from "react-router-dom";

interface InvitePlayerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function InvitePlayerModal({
  isOpen,
  onClose,
}: InvitePlayerModalProps) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedUser, setSelectedUser] = useState<{ id: string }>({ id: "" });
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const { id: teamId } = useParams();
  const { refetchTeamMembers } = useContext(TeamContext);
  const { mutate: newTeamMemberMutate } = setNewTeamMemberMutation({
    mutationSettings: {
      onSuccess: () => {
        onClose();
        setSearchValue("");
        setSelectedUser({ id: "" });
        refetchTeamMembers && refetchTeamMembers();
      },
      onError: () => {
        toast.error(
          "Something went wrong. Please check if user is already in the team.",
        );
        setSearchValue("");
        setSelectedUser({ id: "" });
      },
    },
  });
  const {
    data: searchResults,
    refetch: refetchSearchResults,
    isFetching: isSearching,
  } = getAllUsersQuery({
    queryParams: {
      search: searchValue,
    },
    querySettings: {
      enabled: searchValue.length > 0,
    },
  });

  function handleUserClick(user: any) {
    setIsSearchFocused(false);
    setSearchValue(user?.username);
    setSelectedUser(user);
  }

  function handleAddUser() {
    newTeamMemberMutate({
      team_id: teamId as string,
      user_id: selectedUser?.id,
      role_id: "550e8400-e29b-41d4-a716-446655440002",
      path: "team/member/invite",
    });
  }

  function handleInputFocus() {
    if (searchValue.length > 0) {
      setIsSearchFocused(true);
    } else {
      setIsSearchFocused(false);
    }
  }

  useEffect(() => {
    if (searchValue.length === 0) {
      setIsSearchFocused(false);
      return;
    }

    const getAgain = setTimeout(() => {
      refetchSearchResults();
      if (!selectedUser?.id) {
        setIsSearchFocused(true);
      }
    }, 500);

    return () => {
      clearTimeout(getAgain);
    };
  }, [searchValue]);

  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose();
        setSearchValue("");
        setSelectedUser({ id: "" });
      }}
      title="Invite Player"
      height="max-content"
    >
      <Stack direction="column" py={2} px={3} position="relative">
        <Input
          type="search"
          value={searchValue}
          placeholder="Enter username"
          onChange={(e: any) => {
            setSearchValue(e.target.value);
            setSelectedUser({ id: "" });
          }}
          onFocus={handleInputFocus}
        />

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          pt={2}
        >
          <Button
            text="Cancel"
            isCancelButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
          <Button
            text="Invite"
            onClick={handleAddUser}
            disabled={!selectedUser?.id}
          />
        </Stack>

        {isSearchFocused && (
          <TeamMemberSearch
            isSearching={isSearching}
            searchResults={
              searchValue?.length == 0 ? null : searchResults?.data?.users
            }
            customStyles={{
              top: 74,
              width: 562,
            }}
            onContainerClick={handleUserClick}
          />
        )}
      </Stack>
    </CustomModal>
  );
}
