import Auth from "./layouts/auth";
import Dashboard from "./views/dashboard";
import Game from "./views/games/game";
import GamesContainer from "./views/games/GamesContainer";
import Home from "./views/dashboard/pages/home";
import Info from "./views/auth/pages/info";
import Layout from "./layouts/main";
import MyGames from "./views/dashboard/pages/myGames";
import MyStatistics from "./views/dashboard/pages/myStatistics";
import News from "./views/news";
import ProfileEdit from "./views/profileEdit";
import Publication from "./views/publication";
import Redirect from "./views/redirect";
import SignIn from "./views/auth/pages/sign-in";
import SignUp from "./views/auth/pages/sign-up";
import SingleTeam from "./views/Teams/pages/SingleTeam";
import Stats from "./views/statistics/Stats";
import TeamsContainer from "./views/Teams/pages/TeamsContainer";
import Wrapper from "./layouts/wrapper";
import Statistics from "./views/statistics";
import MyNews from "./views/dashboard/pages/myNews";
import OtherUser from "./views/otherUser";
import { createBrowserRouter } from "react-router-dom";
import UserPosts from "./views/otherUser/pages/posts";
import UserGames from "./views/otherUser/pages/userGames";
import UserStatistics from "./views/otherUser/pages/userStatistics";
import UserStats from "./views/otherUser/pages/userStatistics/Stats";
import Riot from "./views/riot";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Wrapper />,
    children: [
      {
        path: "/app",
        element: <Layout />,
        children: [
          {
            path: "/app/dashboard",
            element: <Dashboard />,
            children: [
              {
                path: "/app/dashboard/home",
                element: <Home />,
              },
              {
                path: "/app/dashboard/my-games",
                element: <MyGames />,
              },
              {
                path: "/app/dashboard/my-statistics",
                element: <MyStatistics />,
              },
              {
                path: "/app/dashboard/my-news",
                element: <MyNews />,
              },
            ],
          },
          {
            path: "/app/dashboard/stats/:game/:id/:matchId?",
            element: <Stats />,
          },
          {
            path: "/app/:nav/game/:id",
            element: <Game />,
          },
          {
            path: "/app/:page/publication/:id",
            element: <Publication />,
          },
          {
            path: "/app/statistics/stats/:game/:id/:matchId?",
            element: <Stats />,
          },
          {
            path: "/app/games",
            element: <GamesContainer />,
          },
          {
            path: "/app/teams",
            element: <TeamsContainer />,
          },
          {
            path: "/app/teams/:id",
            element: <SingleTeam />,
          },
          {
            path: "/app/statistics",
            element: <Statistics />,
          },
          {
            path: "/app/news",
            element: <News />,
          },
          {
            path: "/app/profile-edit",
            element: <ProfileEdit />,
          },
          {
            path: "/app/redirect",
            element: <Redirect />,
          },
          {
            path: "/app/user/:userId",
            element: <OtherUser />,
            children: [
              {
                path: "/app/user/:userId/posts",
                element: <UserPosts />,
              },
              {
                path: "/app/user/:userId/games/:steamId?",
                element: <UserGames />,
              },
              {
                path: "/app/user/:userId/statistics/:steamId?",
                element: <UserStatistics />,
              },
            ],
          },
          {
            path: "/app/user/:userId/stats/:game/:id/:matchId?",
            element: <UserStats />,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "/auth/sign-in",
        element: <SignIn />,
      },
      {
        path: "/auth/sign-in/riot.txt",
        element: <Riot />,
      },
      {
        path: "/auth/sign-up",
        element: <SignUp />,
      },
      {
        path: "/auth/sign-up/info",
        element: <Info />,
      },
      {
        path: "/auth/redirect",
        element: <Redirect />,
      },
    ],
  },
]);

export default router;
