import { Skeleton, Stack } from "@mui/material";

import TeamTableRow from "../../../components/TableRow/TeamTableRow";
import { useNavigate } from "react-router-dom";

export default function TeamsTable({
  teams,
  isFetching,
}: {
  teams: any;
  isFetching?: boolean;
}) {
  const navigate = useNavigate();

  return (
    <Stack gap={1.5}>
      {teams?.map((team: any) => {
        if (!team) return null;
        return (
          <TeamTableRow
            key={team?.id}
            team={team}
            onClick={() => {
              navigate(`/app/teams/${team?.id}`);
            }}
          />
        );
      })}

      {isFetching ? (
        <>
          {[...Array(5)].map((_, i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              height={64}
              width="100%"
              sx={{
                borderRadius: "12px",
                backgroundColor: "#1D1D23",
              }}
            />
          ))}
        </>
      ) : null}
    </Stack>
  );
}
