import { Box, Stack, Typography } from "@mui/material";

import Button from "../Button";
import ChatIcon from "../../assets/icons/ChatIcon";
import CloseIcon from "@mui/icons-material/Close";
import CustomTooltip from "../Tooltip/CustomTooltip";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import usePermission from "../../hooks/usePermission";
import { useImage } from "../../services/getImages";

interface RequestCardProps {
  requestData: any;
  handleAcceptRequest: (requestId: string, requestData: any) => void;
  handleDeclineRequest: (requestId: string, requestData: any) => void;
}

export default function RequestCard({
  requestData,
  handleAcceptRequest,
  handleDeclineRequest,
}: RequestCardProps) {
  const { checkPermission, checkComponentPermission } = usePermission();

  const { data: userAvatar } = useImage(requestData?.user_avatar);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {requestData?.user_avatar ? (
          <Link to={`/app/user/${requestData?.user_id}/posts`}>
            <img
              loading="lazy"
              width={50}
              height={50}
              style={{
                borderRadius: "8px",
              }}
              src={userAvatar}
            />
          </Link>
        ) : (
          <Box width={50} height={50} bgcolor="#1D1D23" borderRadius="8px" />
        )}
        <Link to={`/app/user/${requestData?.user_id}/posts`}>
          <Typography color="white" fontSize="14px" fontWeight={600}>
            {requestData?.username}
          </Typography>
        </Link>
        <CustomTooltip content={requestData?.message} icon={<ChatIcon />} />
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        {checkPermission("team/member/approve") && (
          <Button
            text="Accept"
            icon={<DoneIcon sx={{ width: "20px" }} />}
            customStyles={{
              gap: "0.5rem",
              height: "2.5rem",
              padding: "0 1rem",
            }}
            onClick={() => handleAcceptRequest(requestData?.id, requestData)}
          />
        )}

        {checkComponentPermission(
          "team/member/reject",
          <Button
            isCancelButton
            text="Decline"
            customStyles={{
              gap: "0.5rem",
              height: "2.5rem",
              padding: "0 1rem",
            }}
            icon={<CloseIcon sx={{ width: "20px" }} />}
            onClick={() => handleDeclineRequest(requestData?.id, requestData)}
          />,
        )}
      </Stack>
    </Stack>
  );
}
