import { useEffect, useState } from "react";

import Button from "../../../components/Button";
import CustomModal from "../../../components/Modal/CustomModal";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import { Stack } from "@mui/material";
import { client } from "../../../services/config";
import { setTeamMutation } from "../../../services/team";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

export default function CreateTeam({
  isOpen,
  setIsOpen,
  reFetchTeams,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reFetchTeams: () => void;
}) {
  const [teamName, setTeamName] = useState("");
  const [isGamesOpen, setGamesOpen] = useState(false);
  const [games, setGames] = useState([{ id: "", title: "", logo: "" }]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedGame, setSelectedGame] = useState({
    gameId: "",
    title: "",
    image: "",
    description: "",
  });

  const { mutate: createTeamMutate } = setTeamMutation({
    mutationSettings: {
      onSuccess: () => {
        setIsOpen(false);
        clearFields();
        reFetchTeams();
      },
      onError: (err: any) => {
        clearFields();
        setIsOpen(false);
        toast.error(err?.response?.data?.error_message);
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: allGames } = useQuery(
    ["all-games"],
    () => {
      return client.get("/v1/game");
    },
    {
      onSuccess: (res) => {
        const gamesList: { id: ""; title: ""; logo: "" }[] = [];
        res?.data?.games?.forEach((game: any) => {
          gamesList.push({
            id: game.id,
            title: game.title,
            logo: game.logo,
          });
        });
        setGames(gamesList);
      },
      refetchOnMount: true,
    },
  );

  function handleTeamCreate() {
    if (isButtonDisabled) return;
    createTeamMutate({
      name: teamName.trimEnd(),
      game_id: selectedGame.gameId,
      creator_id: "1",
    });
    clearFields();
  }

  function clearFields() {
    setTeamName("");
    setSelectedGame({ gameId: "", title: "", image: "", description: "" });
  }

  useEffect(() => {
    if (teamName.length > 0 && selectedGame?.gameId) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [teamName, selectedGame]);

  return (
    <CustomModal
      title="Create a team"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setGamesOpen(false);
        clearFields();
      }}
      height="max-content"
    >
      <Stack
        direction="column"
        px={3}
        py={2}
        gap={1.5}
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Input
          placeholder="Team Name"
          value={teamName}
          onChange={(e: any) => {
            setGamesOpen(false);
            if (e.target.value.length > 24) return;
            setTeamName(e.target.value.trimStart());
          }}
          onFocus={() => {
            setGamesOpen(false);
          }}
          isDarkColored
        />
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Dropdown
            width="100%"
            isDarkColored
            withImage={true}
            options={games}
            label="Select Game Community"
            onGameSelect={(gameId: string) => {
              setSelectedGame((prev) => ({ ...prev, gameId }));
            }}
            parentControl
            customIsOpen={isGamesOpen}
            setCustomIsOpen={setGamesOpen}
          />
        </Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Button
            text="Cancel"
            isCancelButton
            onClick={() => {
              setIsOpen(false);
              setGamesOpen(false);
              clearFields();
            }}
          />
          <Button
            text="Create"
            disabled={isButtonDisabled}
            onClick={handleTeamCreate}
          />
        </Stack>
      </Stack>
    </CustomModal>
  );
}
