import React, { useEffect, useState } from "react";
import User from "./User";
import Team from "./Team";
import Modal from "../Modal";
import Button from "../Button";
import { getTeamsQuery } from "../../services/team";
import {
  getFollowRequests,
  getFollowers,
  getFollowings,
} from "../../services/follow";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";

interface IFollowModal {
  open: boolean;
  userId: string;
  isOtherUser?: boolean;
  handleClose: () => void;
  isFollowersModal: boolean;
}

const FollowModal: React.FC<IFollowModal> = ({
  isFollowersModal,
  handleClose,
  isOtherUser,
  userId,
  open,
}) => {
  const [teams, setTeams] = useState([]);
  const [followers, setFollowers] = useState<any[]>([]);
  const [following, setFollowing] = useState([]);
  const [isLeftTab, setIsLeftTab] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [followersReq, setFollowersReq] = useState([]);
  const user = useSelector((state: any) => state.user);
  const { data: allTeams } = getTeamsQuery({
    queryParams: { gameId: "", user_id: userId, limit: 100 },
    querySettings: { enabled: !!userId && !isFollowersModal && open },
  });

  const onClose = () => {
    setIsLeftTab(true);
    handleClose();
  };

  useEffect(() => {
    if (userId && isFollowersModal && open) {
      getFollowers(userId).then((res: any) => {
        setFollowers(res.data?.followers);
      });
      getFollowRequests().then((res: any) => {
        setFollowersReq(res.data?.follow_requests);
      });
    }

    if (userId && !isFollowersModal && open && allTeams) {
      getFollowings(userId).then((res: any) => {
        setFollowing(res.data?.following);
      });
      setTeams(allTeams?.data?.teams);
    }
  }, [userId, isFollowersModal, open, allTeams]);

  const acceptDeclineReq = (id: string) => {
    setFollowersReq((prev: any) => prev.filter((e: any) => e?.id !== id));
  };

  const cancelReq = (id: string) => {
    setFollowers((prev: any) =>
      prev.map((e: any) => (e?.id === id ? { ...e, follow_status: 0 } : e)),
    );
  };

  const onFollow = (id: string) => {
    setFollowers((prev: any) =>
      prev.map((e: any) => (e?.id === id ? { ...e, follow_status: 2 } : e)),
    );
  };

  const addFollower = (follower: {
    id: string;
    user_id: string;
    username: string;
    user_avatar: string;
    follow_status?: number;
  }) => {
    setFollowers(() => {
      if (followers?.length) {
        return [follower, ...followers];
      } else {
        return [follower];
      }
    });
  };

  const onUnfollow = (id: string) => {
    setFollowing(following.filter((e: any) => e.id !== id));
  };

  const onRemoveFollower = (id: string) => {
    setFollowers(followers.filter((e: any) => e.id !== id));
  };

  return (
    <Modal
      open={open}
      close={onClose}
      width={"712px"}
      withFooter={false}
      withBackBtn={false}
      title={isFollowersModal ? "Followers" : "Following"}
    >
      <div className={styles.follows_modal_body}>
        <div className={styles.tab_bar}>
          <Button
            text="Players"
            className={`${styles.tab_button} ${isLeftTab && styles.active_tab}`}
            onClick={() => setIsLeftTab(true)}
          />
          {isFollowersModal && isOtherUser ? (
            <></>
          ) : isFollowersModal && !isOtherUser ? (
            <div className={styles.requests_tab_wrapper}>
              <Button
                text="Requests"
                onClick={() => setIsLeftTab(false)}
                className={`${styles.tab_button} ${
                  !isLeftTab && styles.active_tab
                }`}
              />
              {user?.follow_requests ? (
                <span
                  className={styles.follow_requests}
                  style={{
                    padding:
                      user?.follow_requests < 10
                        ? "2px 6px"
                        : user?.follow_requests < 100
                        ? "4px"
                        : user?.follow_requests < 1000
                        ? "8px 4px"
                        : "12px 4px",
                  }}
                >
                  {user?.follow_requests}
                </span>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <Button
              text="Teams"
              onClick={() => setIsLeftTab(false)}
              className={`${styles.tab_button} ${
                !isLeftTab && styles.active_tab
              }`}
            />
          )}
        </div>
        <ul className={styles.follows_wrapper}>
          {isFollowersModal ? (
            <>
              {isLeftTab && followers?.length ? (
                followers?.map((follower: any) => (
                  <User
                    key={follower?.id}
                    follower={follower}
                    onFollow={onFollow}
                    cancelReq={cancelReq}
                    isDisabled={isDisabled}
                    onUnfollow={onUnfollow}
                    closeModal={handleClose}
                    isOtherUser={isOtherUser}
                    setIsDisabled={setIsDisabled}
                    onRemoveFollower={onRemoveFollower}
                    acceptDeclineReq={acceptDeclineReq}
                  />
                ))
              ) : !isLeftTab && followersReq?.length ? (
                followersReq?.map((req: any) => (
                  <User
                    key={req?.id}
                    follower={req}
                    isRequests={true}
                    isDisabled={isDisabled}
                    closeModal={handleClose}
                    addFollower={addFollower}
                    isOtherUser={isOtherUser}
                    setIsDisabled={setIsDisabled}
                    acceptDeclineReq={acceptDeclineReq}
                  />
                ))
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {isLeftTab && following?.length ? (
                following?.map((follower: any) => (
                  <User
                    key={follower?.id}
                    isFollowing={true}
                    follower={follower}
                    onFollow={onFollow}
                    onUnfollow={onUnfollow}
                    isDisabled={isDisabled}
                    closeModal={handleClose}
                    isOtherUser={isOtherUser}
                    setIsDisabled={setIsDisabled}
                    acceptDeclineReq={acceptDeclineReq}
                  />
                ))
              ) : !isLeftTab && teams?.length ? (
                teams?.map((team: any) => (
                  <Team key={team?.id} team={team} closeModal={handleClose} />
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </ul>
      </div>
    </Modal>
  );
};

export default FollowModal;
