import React from "react";
import { INotificationLotify } from "../types";
import { Player } from "@lottiefiles/react-lottie-player";
import styles from "./style.module.scss";

const NotificationLotify: React.FC<INotificationLotify> = ({ src }) => {
	return (
		<div className={styles.notification_wrapper}>
			<Player
				autoplay
				keepLastFrame
				src={src}
				speed={0.7}
				style={{
					width: "600px",
				}}
			></Player>
		</div>
	);
};

export default NotificationLotify;
