export const Headshot = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3736_19682)">
      <path
        d="M31.4944 29.4404C31.5882 23.8987 39.6106 23.6781 37.0626 11.285C34.9593 1.05198 23.9798 -1.83927 15.1501 1.07276C8.38979 3.30151 3.62058 9.50417 3.85558 17.5532C3.8662 17.9017 3.78839 18.2478 3.62909 18.5587L0.332606 24.3227C-0.331144 25.4198 0.379013 26.7407 1.58534 26.9124L2.89784 27.0989C3.35018 27.1629 3.70354 27.5246 3.75847 27.9784C4.74331 36.1138 5.25847 37.2328 6.17214 38.0578C7.06292 38.8622 11.2408 38.0114 14.5815 36.0485C14.8483 35.8916 15.1925 35.9972 15.3189 36.2795C15.592 36.8892 16.0235 38.0684 16.3598 39.9849L33.577 40.0002C32.5256 37.1245 31.4306 33.1875 31.4944 29.4405V29.4404ZM22.9925 23.8292V25.8012C22.9925 26.4891 22.4359 27.046 21.748 27.046C21.061 27.046 20.5035 26.4891 20.5035 25.8012V23.8292C16.6885 23.2914 13.6459 20.3163 13.0078 16.5336H11.2356C10.5478 16.5336 9.99104 15.976 9.99104 15.2891C9.99104 14.6013 10.5478 14.0446 11.2356 14.0446H12.9407C13.3963 10.0395 16.5307 6.83604 20.5035 6.27604V4.77581C20.5035 4.08886 21.061 3.53128 21.748 3.53128C22.4359 3.53128 22.9925 4.08886 22.9925 4.77581V6.27604C26.966 6.83604 30.1007 10.0395 30.555 14.0446H32.261C32.9479 14.0446 33.5055 14.6013 33.5055 15.2892C33.5055 15.976 32.9479 16.5336 32.261 16.5336H30.4879C29.8498 20.3163 26.8084 23.2914 22.9925 23.8292Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M21.748 8.67578C18.2297 8.67578 15.3727 11.5398 15.3727 15.0512C15.3727 18.563 18.2294 21.4266 21.748 21.4266C25.2691 21.4266 28.1234 18.5605 28.1234 15.0512C28.1234 11.5472 25.2731 8.67578 21.748 8.67578ZM21.748 19.4039C19.3579 19.4039 17.3953 17.4606 17.3953 15.0512C17.3953 12.662 19.3387 10.6985 21.748 10.6985C24.1598 10.6985 26.1014 12.6626 26.1014 15.0512C26.1014 17.4459 24.1537 19.4039 21.748 19.4039Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M21.748 13.1875C20.715 13.1875 19.8846 14.031 19.8846 15.0509C19.8846 16.0723 20.7153 16.9143 21.748 16.9143C22.775 16.9143 23.6123 16.0791 23.6123 15.0509C23.6123 14.027 22.7777 13.1875 21.748 13.1875Z"
        fill="white"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_3736_19682">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
