import { Stack, Typography } from "@mui/material";
import {
  getJoinRequestsQuery,
  updateJoinRequestMutation,
} from "../../../services/team";

import CustomModal from "../../../components/Modal/CustomModal";
import RequestCard from "../../../components/Cards/RequestCard";
import { TeamContext } from "../../../contexts/TeamContext";
import toast from "react-hot-toast";
import { useContext } from "react";
import usePermission from "../../../hooks/usePermission";

interface JoinRequestModalProps {
  isOpen: boolean;
  onClose: () => void;
  setRequestQuantity?: (value: number) => void;
}

export default function JoinRequestModal({
  isOpen,
  onClose,
  setRequestQuantity,
}: JoinRequestModalProps) {
  const { refetchTeamMembers, refetchRosters, teamData } =
    useContext(TeamContext);
  const { checkPermission } = usePermission();

  const { data: joinRequests, refetch: refetchJoinRequests } =
    getJoinRequestsQuery({
      querySettings: {
        enabled: teamData && checkPermission("team/member/request/see"),
        onSuccess: (data: any) => {
          setRequestQuantity && setRequestQuantity(data?.data?.count);
        },
      },
      queryParams: {
        teamId: teamData?.data?.id,
        limit: 10,
        page: 1,
        path: "team/member/request/see",
      },
    });

  const { mutate: updateRequestMutation } = updateJoinRequestMutation({
    mutationSettings: {
      onSuccess: () => {
        refetchJoinRequests();
        refetchTeamMembers && refetchTeamMembers();
        refetchRosters && refetchRosters();
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    },
  });

  function handleAcceptRequest(requestId: string, requestData: any) {
    updateRequestMutation({
      id: requestId,
      team_id: requestData.team_id,
      user_id: requestData.user_id,
      is_rejected: false,
      path: "team/member/approve",
    });
  }

  function handleDeclineRequest(requestId: string, requestData: any) {
    updateRequestMutation({
      id: requestId,
      team_id: requestData.team_id,
      user_id: requestData.user_id,
      is_rejected: true,
      path: "team/member/reject",
    });
  }

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      title="Join requests"
      height="26rem"
    >
      <Stack
        direction="column"
        width="100%"
        gap={2}
        px={3}
        py={2}
        style={{
          overflowY: "scroll",
        }}
      >
        {joinRequests?.data?.team_requests?.map((request: any) => (
          <RequestCard
            handleAcceptRequest={handleAcceptRequest}
            handleDeclineRequest={handleDeclineRequest}
            requestData={request}
            key={request.id}
          />
        ))}
        {(!joinRequests || joinRequests?.data?.count == 0) && (
          <Typography fontSize="14px" fontWeight={500} color="#8C8D94">
            No requests
          </Typography>
        )}
      </Stack>
    </CustomModal>
  );
}
