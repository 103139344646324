import { useContext, useMemo } from "react";

import { TeamContext } from "../../contexts/TeamContext";
import defaultImg from "../../assets/images/default-banner.png";
import { useImage } from "../../services/getImages";

type BannerImgProps = {
  imgFile?: string;
  onLoad?: () => void;
  defaultBanner?: string;
};

export default function BannerImg({
  imgFile,
  onLoad,
  defaultBanner,
}: BannerImgProps) {
  const { teamData } = useContext(TeamContext);

  const savedImg = useMemo(() => {
    return teamData?.data?.background_logo;
  }, [teamData?.data?.background_logo]);

  const { data: savedImgs } = useImage(savedImg);
  const { data: defaultBannerImg } = useImage(defaultBanner || "");

  const image = useMemo(() => {
    if (imgFile) {
      return imgFile == savedImg ? savedImgs : imgFile;
    } else if (defaultBanner) {
      return defaultBannerImg;
    } else {
      return defaultImg;
    }
  }, [imgFile, defaultBanner]);
  return (
    <img
      src={image}
      height="296px"
      width="100%"
      style={{ objectFit: "cover" }}
      onLoad={onLoad}
      loading="lazy"
    />
  );
}
