import { Stack, Typography } from "@mui/material";
import { useImage } from "../../services/getImages";

interface LabelProps {
  label: string | number;
  icon?: any;
  img?: any;
}

export default function Label({ label, icon, img }: LabelProps) {
  const { data: imgST } = useImage(img);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Stack direction="row" alignItems="center" gap={1} width="100%">
        {icon ? (
          <Stack
            bgcolor="#FFD54F26"
            width={32}
            height={32}
            borderRadius="6px"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Stack>
        ) : (
          <img
            loading="lazy"
            width={32}
            height={32}
            style={{
              borderRadius: "6px",
            }}
            src={imgST}
          />
        )}

        <Typography fontSize="14px" color="#8C8D94">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
}
