export const checkTextarea = (value: string) => {
  // it should not contain only html tags line <br/> <p></p> etc. or empty string
  const regex = /<[^>]*>/g;
  const result = value.replace(regex, "").trim();
  if (result.length === 0) {
    return false;
  }

  return true;
};
