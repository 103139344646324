import { IGameCard } from "../types";
import React from "react";
import { Stack } from "@mui/material";
import styles from "./style.module.scss";
import { Typography } from "@mui/material";
import { useImage } from "../../services/getImages";

const GameCard: React.FC<IGameCard> = ({
  bgImg,
  game_title,
  playtime_forever,
  playtime_2weeks,
}) => {
  const { data: bgImgST } = useImage(bgImg);

  const style = {
    backgroundImage: `url('${bgImgST}')`,
  };

  const hoverStyle = {
    background:
      "linear-gradient(228.52deg, rgba(5, 24, 57, 0.2) -1.66%, rgba(73, 87, 233, 0.2) 100%)",
    backgroundBlendMode: "overlay",
    border: "2px solid",
    borderImageSource:
      "linear-gradient(228.52deg, rgba(5, 24, 57, 0.2) -1.66%, rgba(73, 87, 233, 0.2) 100%)",
    backdropFilter: "blur(10px)",
  };

  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <div
      className={styles.card}
      style={style}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering && (
        <Stack
          direction="column"
          p={2}
          borderRadius="12px"
          bgcolor="black"
          position="absolute"
          bottom={0}
          width="100%"
          style={hoverStyle}
          spacing={1}
        >
          <>
            <Typography
              color="white"
              fontWeight={700}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {game_title}
            </Typography>
            {playtime_forever >= 0 && (
              <Typography color="white" fontSize="12px">
                Total:{" "}
                {playtime_forever
                  ? `${(playtime_forever / 60)?.toString().slice(0, 4)} Hours`
                  : "-"}{" "}
              </Typography>
            )}

            {playtime_2weeks >= 0 && (
              <Typography color="white" fontSize="12px">
                Last 2 Weeks:{" "}
                {playtime_2weeks
                  ? `${(playtime_2weeks / 60)?.toString().slice(0, 4)} Hours`
                  : "-"}
              </Typography>
            )}
          </>
        </Stack>
      )}
    </div>
  );
};

export default GameCard;
