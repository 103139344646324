import toast from "react-hot-toast";
import { client } from "./config/index";
import { useQuery, useMutation } from "@tanstack/react-query";
import { IGetUserGames, ICreateUserGame, IAddUserSteamGames } from "./types";

// --------------------- GET ALL USER GAMES ---------------------

export const getAllUserGamesQuery = (data: IGetUserGames) =>
  client
    .get("/v1/user-games", {
      params: data,
    })
    .catch((err: any) => {
      toast.error("Error: " + err.response?.data?.error_message);
    });

// --------------------- GET USER GAME BY ID ---------------------

export const useGetUserGameQuery = (id: string) =>
  useQuery({
    queryKey: ["user-game"],
    queryFn: () => client.get(`/v1/user-games/${id}`),
    onError: (err: any) => {
      toast.error("Error: " + err.response?.data?.error_message);
    },
  });

// --------------------- CREATE NEW USER GAME ---------------------

export const useCreateUserGameMutation = () =>
  useMutation({
    mutationFn: (data: ICreateUserGame) => client.post("/v1/user-games", data),
    onError: (err: any) => {
      toast.error("Error: " + err.response?.data?.error_message);
    },
  });

// --------------------- CREATE NEW USER GAME STEAM ---------------------

export const useAddUserSteamGamesMutation = () =>
  useMutation({
    mutationFn: (data: IAddUserSteamGames) =>
      client.post("/v1/user-games/steam", data),
    onError: (err: any) => {
      toast.error("Error: " + err.response?.data?.error_message);
    },
  });
