// --------------------------------------- USERNAME CHECK ---------------------------------------

export const isUsernameCorrect = (username: string, setFormError?: any) => {
  if (!username) return false;

  if (username.length < 4 || username.length > 24) {
    if (setFormError) {
      setFormError((prev: any) => ({
        ...prev,
        usernameError: {
          isError: true,
          text: "Username must contain from 4-24 symbols",
        },
      }));
    }
    return false;
  }

  // ----------------------------------------------------------------------------------------
  // CHECK IF USERNAME CONTAINS CHARACTERS, NUMBERS (OPTIONAL) AND ONLY ONE SPECIAL CHARACTER (OPTIONAL)
  //  ---------------------------------------------------------------------------------------

  if (!/^[a-zA-Z]{3}[a-zA-Z0-9]*[-_!?@$&<>]?[a-zA-Z0-9]*$/.test(username)) {
    if (setFormError) {
      setFormError((prev: any) => ({
        ...prev,
        usernameError: {
          isError: true,
          text: "Username must contain between 4-24 characters. The first 3 characters of your username must be text only. Your username is allowed only one of the following special characters: -_!?@$&<>",
        },
      }));
    }

    return false;
  }

  return true;
};

// --------------------------------------- PASSWORD CHECK ---------------------------------------

export const isPasswordCorrect = (password: string, setFormError?: any) => {
  if (!password) return false;

  if (password.length < 8) {
    if (setFormError) {
      setFormError((prev: any) => ({
        ...prev,
        passwordError: {
          isError: true,
          text: "Password must contain at least 8 symbols",
        },
      }));
    }
    return false;
  }
  // ----------------------------------------------------------------------------------------
  // CHECK IF PASSWORD CONTAINS CHARACTERS, LETTERS AND ONLY ONE SPECIAL CHARACTER
  //  ---------------------------------------------------------------------------------------
  if (
    !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]).+$/.test(
      password,
    )
  ) {
    if (setFormError) {
      setFormError((prev: any) => ({
        ...prev,
        passwordError: {
          isError: true,
          text: "Password must be 8 or more characters including a combination of letters, numbers and at least 1 of the following special characters ~`!@#$%^&*()_-+={[}]|:;”’<,>.?/",
        },
      }));
    }

    return false;
  }

  return true;
};
