import { Stack, Typography } from "@mui/material";

import PlayerCard from "../../../components/Cards/PlayerCard";

interface TeamMemberSearchProps {
  searchResults: any;
  isSearching: boolean;
  customStyles?: any;
  onContainerClick?: (player: any) => void;
  canGoToProfile?: boolean;
}

export default function TeamMemberSearch({
  searchResults,
  isSearching,
  customStyles,
  onContainerClick,
  canGoToProfile,
}: TeamMemberSearchProps) {
  return (
    <Stack
      direction="column"
      position="absolute"
      top={60}
      width="100%"
      gap={2}
      px={2}
      py={2}
      zIndex={1000}
      sx={{
        backgroundColor: "#1d1d27",
        color: "#8C8D94",
        borderRadius: "8px",
        ...customStyles,
      }}
    >
      {(!searchResults || searchResults?.length == 0) && !isSearching && (
        <Typography fontSize="14px" fontWeight={500} color="#8C8D94">
          No members found
        </Typography>
      )}

      {isSearching && (
        <Typography fontSize="14px" fontWeight={500} color="#8C8D94">
          Searching...
        </Typography>
      )}

      {!isSearching &&
        searchResults?.map((member: any) => {
          return (
            <PlayerCard
              key={member.id}
              player={{ ...member }}
              type="search"
              onContainerClick={onContainerClick}
              canGoToProfile={canGoToProfile}
            />
          );
        })}
    </Stack>
  );
}
