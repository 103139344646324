import { IconButton } from "@mui/material";
import LOLStatistics from "./LOLStatistics";
import CS2Statistics from "./CS2Statistics";
import DotaStatistics from "./DotaStatistics";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import styles from "./style.module.scss";

const Stats = () => {
  const { game } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    if (location.pathname.includes("/app/dashboard")) {
      navigate("/app/dashboard/my-statistics");
    } else {
      navigate("/app/statistics");
    }
  };

  return (
    <div className={styles.stats_wrapper}>
      <header className={styles.stats_header}>
        <IconButton className={styles.back_icon} onClick={handleBack}>
          <KeyboardBackspaceRoundedIcon className={styles.back_icon} />
        </IconButton>
        Back
      </header>

      <div className={styles.stats_content}>
        {game === "dota2" ? (
          <DotaStatistics />
        ) : game === "leagueoflegends" ? (
          <LOLStatistics />
        ) : (
          <CS2Statistics />
        )}
      </div>
    </div>
  );
};

export default Stats;
