import { useEffect, useState } from "react";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import styles from "./style.module.scss";
import { useImage } from "../../../../../../services/getImages";

interface IStatsCell {
  player: any;
  isYou: boolean;
}

const LOLStatsCell: React.FC<IStatsCell> = ({ player, isYou }) => {
  const [items, setItems] = useState<any>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { data: playerChampionName } = useImage(player?.championName);

  useEffect(() => {
    if (player) {
      setItems([
        player.item0,
        player.item1,
        player.item2,
        player.item3,
        player.item4,
        player.item5,
        player.item6,
      ]);
    }
  }, [player]);

  return (
    <div
      className={`${styles.stats_cell_container} ${isYou && styles.you}`}
      onClick={() => setIsCollapsed((prev) => !prev)}
    >
      <div className={styles.stats_cell_top}>
        <div className={styles.hero_info_wrapper}>
          <img
            loading="lazy"
            className={styles.hero_image}
            src={playerChampionName}
            alt="Hero Image"
          />
          <div className={styles.text_info_wrapper}>
            <p className={styles.hero_level}>{player?.champLevel}</p>
            <div className={styles.hero_info_text_wrapper}>
              <h3 className={styles.username}>{player?.championName || "-"}</h3>
              <p className={styles.secondary_text}>
                XP: {player?.champExperience}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.info_right_wrapper}>
          <div className={styles.kda_info_wrapper}>
            <div className={styles.kda_text_wrapper}>
              <span className={styles.secondary_text}>K</span>
              <span className={styles.secondary_text}>D</span>
              <span className={styles.secondary_text}>A</span>
            </div>

            <div className={styles.kda_text_wrapper}>
              <span className={styles.secondary_text}>{player?.kills}</span>/
              <span className={styles.secondary_text}>{player?.deaths}</span>/
              <span className={styles.secondary_text}>{player?.assists}</span>
            </div>
          </div>

          {isCollapsed ? (
            <KeyboardArrowUpRoundedIcon className={styles.icon} />
          ) : (
            <KeyboardArrowDownRoundedIcon className={styles.icon} />
          )}
        </div>
      </div>

      {isCollapsed && (
        <div className={styles.stats_cell_bottom}>
          <div className={styles.summoner_info_wrapper}>
            <p className={styles.stats_text}>Summoner</p>
            <span className={styles.summoner_name}>{player?.summonerName}</span>
          </div>

          <div className={styles.details_wrapper}>
            <p className={styles.stats_text}>Gold</p>
            <div className={styles.gold_stats_wrapper}>
              <div className={styles.details_item}>
                <p className={styles.secondary_text}>Earned</p>
                <p className={styles.stats_text}>{player?.goldEarned}</p>
              </div>

              <hr className={styles.vertical_line} />

              <div className={styles.details_item}>
                <p className={styles.secondary_text}>Spent</p>
                <p className={styles.stats_text}>{player?.goldSpent}</p>
              </div>
            </div>
          </div>

          <div className={styles.items_wrapper}>
            <p className={styles.stats_text}>Items</p>

            <ul className={styles.items_list}>
              {items && items?.every((e: number) => e === 0) ? (
                <p className={styles.secondary_text}>No items</p>
              ) : (
                items?.map((item: any, i: number) => {
                  const { data: itemST } = useImage(item);
                  return (
                    <li key={i} className={styles.item}>
                      {item ? (
                        <img
                          loading="lazy"
                          alt="Item img"
                          className={styles.item_img}
                          src={itemST}
                        />
                      ) : null}
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default LOLStatsCell;
