import { useContext, useState } from "react";

import Button from "../../../components/Button";
import DeletePopup from "./DeletePopup";
import JoinStatusLabel from "./JoinStatusLabel";
import { TeamContext } from "../../../contexts/TeamContext";
import usePermission from "../../../hooks/usePermission";

interface JoinStatusProps {
  joinStatus: string;
  setIsJoinOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function JoinStatus({
  joinStatus,
  setIsJoinOpen,
}: JoinStatusProps) {
  const [isLeaveOpen, setIsLeaveOpen] = useState<boolean>(false);
  const { checkPermission } = usePermission();
  const { teamData } = useContext(TeamContext);

  if (joinStatus == "Joined") {
    return (
      <>
        <JoinStatusLabel
          status={joinStatus}
          onClick={() => {
            setIsLeaveOpen(true);
          }}
        />
        {checkPermission("team/member/leave") && (
          <DeletePopup
            isOpen={isLeaveOpen}
            setIsOpen={setIsLeaveOpen}
            id={teamData?.data?.team_member_info?.id}
          />
        )}
      </>
    );
  } else if (joinStatus == "Requested") {
    return <JoinStatusLabel status={joinStatus} />;
  } else if (!teamData?.data?.is_hiring) return null;

  return (
    <Button
      text="Join Team"
      customStyles={{
        width: "150px",
      }}
      onClick={() => setIsJoinOpen(true)}
    />
  );
}
