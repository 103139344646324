import { useQuery } from "@tanstack/react-query";
import { client } from "./config";

const fetchImageById = async (
  imageId: string,
  defaultData: boolean = false,
) => {
  const response = await client.get(
    !defaultData
      ? `/v1/file/presigned-url?filename=${imageId}`
      : `/v1/file/presigned-url?filename=${imageId}.jpg`,
  );

  if (!response.data) {
    return "404";
  }

  return response.data?.filename;
};

export const useImage = (imageId: string, defaultData?: boolean) => {
  return useQuery({
    queryKey: ["images", imageId],
    queryFn: () => fetchImageById(imageId, defaultData),
    enabled: !!imageId,
    staleTime: 1 * 60 * 1000, // 1 минут данные считаются актуальными
    cacheTime: 30 * 60 * 1000, // 30 минут данные остаются в кеше
  });
};
