import React, { useCallback, useContext } from "react";

import { TeamContext } from "../contexts/TeamContext";

const usePermission = () => {
  const { permissions } = useContext(TeamContext);

  const checkPermission = useCallback(
    (permissionName: string) => {
      return permissions?.has(permissionName);
    },
    [permissions],
  );

  const checkComponentPermission = useCallback(
    (
      permissionName: string,
      component: React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >,
    ) => {
      return permissions?.has(permissionName) ? component : null;
    },
    [permissions],
  );

  return { checkComponentPermission, checkPermission, permissions };
};

export default usePermission;
