export const Steam = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2134_8640)">
      <path
        d="M4.44422 13.7651C5.41751 17.0104 8.42773 19.3769 11.9898 19.3769C16.3401 19.3769 19.8667 15.8502 19.8667 11.5C19.8667 7.14969 16.3401 3.62305 11.9898 3.62305C7.81554 3.62305 4.39967 6.87055 4.12988 10.9766C4.64041 11.8325 4.83856 12.362 4.44373 13.7651H4.44422Z"
        fill="url(#paint0_linear_2134_8640)"
      />
      <path
        d="M11.5839 9.52695L11.5847 9.56584L9.65729 12.3658C9.34517 12.3516 9.03206 12.4065 8.73471 12.5288C8.60527 12.5814 8.48168 12.6474 8.36597 12.7257L4.13335 10.9812C4.13335 10.9812 4.03539 12.5926 4.44351 13.7933L7.43575 15.028C7.58345 15.6995 8.04622 16.2883 8.72609 16.5714C9.26097 16.7936 9.86219 16.7945 10.3977 16.5738C10.9332 16.3531 11.3593 15.9289 11.5822 15.3943C11.7012 15.1095 11.7585 14.8028 11.7506 14.4943L14.5058 12.5239L14.5735 12.5251C16.2239 12.5251 17.5655 11.1796 17.5655 9.52695C17.5655 7.87427 16.2264 6.53372 14.5735 6.53027C12.9238 6.53027 11.5815 7.87427 11.5815 9.52695H11.5839ZM11.1212 15.2008C10.7633 16.0623 9.77569 16.4678 8.91735 16.1101C8.53646 15.95 8.22772 15.6555 8.04991 15.2825L9.02394 15.6862C9.17441 15.7488 9.33574 15.7812 9.49873 15.7815C9.66171 15.7817 9.82315 15.7499 9.97381 15.6877C10.1245 15.6256 10.2614 15.5343 10.3768 15.4192C10.4922 15.3041 10.5838 15.1674 10.6463 15.0169C10.773 14.7128 10.7737 14.3709 10.6484 14.0662C10.5231 13.7615 10.282 13.5191 9.97803 13.3921L8.9688 12.9748C9.35723 12.8271 9.79883 12.8222 10.2119 12.9938C10.6303 13.1668 10.9503 13.4927 11.1217 13.9095C11.293 14.3262 11.292 14.7858 11.1192 15.2008M14.5762 11.5245C14.0473 11.5231 13.5406 11.3121 13.1669 10.9378C12.7933 10.5634 12.5833 10.0563 12.5828 9.52744C12.5834 8.99866 12.7935 8.49165 13.1671 8.11744C13.5407 7.74323 14.0474 7.53232 14.5762 7.53089C15.1051 7.53219 15.6119 7.74305 15.9856 8.11727C16.3593 8.49149 16.5694 8.99857 16.57 9.52744C16.5696 10.0564 16.3595 10.5636 15.9857 10.9379C15.612 11.3123 15.1051 11.5232 14.5762 11.5245ZM13.0825 9.52424C13.0822 9.12688 13.2397 8.74564 13.5203 8.46431C13.8009 8.18298 14.1818 8.02459 14.5791 8.02393C15.405 8.02393 16.0767 8.69594 16.0767 9.52424C16.0769 9.92169 15.9193 10.303 15.6385 10.5842C15.3576 10.8655 14.9766 11.0237 14.5791 11.0241C14.1818 11.0235 13.801 10.8652 13.5203 10.5839C13.2396 10.3027 13.0823 9.92157 13.0825 9.52424Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2134_8640"
        x1="790.973"
        y1="3.62305"
        x2="790.973"
        y2="1579.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#111D2E" />
        <stop offset="0.212" stopColor="#051839" />
        <stop offset="0.407" stopColor="#0A1B48" />
        <stop offset="0.581" stopColor="#132E62" />
        <stop offset="0.738" stopColor="#144B7E" />
        <stop offset="0.873" stopColor="#136497" />
        <stop offset="1" stopColor="#1387B8" />
      </linearGradient>
      <clipPath id="clip0_2134_8640">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(4 3.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
