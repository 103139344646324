import usePermission from "../hooks/usePermission";

export const canRemoveMember = (role: string) => {
  const { checkPermission } = usePermission();

  if (role == "Member") {
    return checkPermission("team/member/kick");
  } else if (role == "Captain") {
    return checkPermission("team/member/captain/kick");
  } else if (role == "Coach") {
    return checkPermission("team/member/coach/kick");
  }
};
