import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import LogoImg from "../Logo/LogoImg";
import { setBinaryImage } from "../../helpers/setBinaryImage";
import { useDropzone } from "react-dropzone";

type LogoUploadProps = {
  setLogoId: (bannerId: string) => void;
};

export default function LogoUpload({ setLogoId }: LogoUploadProps) {
  const [files, setFiles] = useState([{ name: "default", preview: "" }]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      setFiles([
        {
          ...acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        },
      ]);

      const id = await setBinaryImage(acceptedFiles[0]);
      if (id) {
        setLogoId(id);
      }
    },
  });

  const thumbs = (
    <div key={files?.[0].name}>
      <div>
        <LogoImg
          imgFile={files?.[0].preview}
          onLoad={() => {
            URL.revokeObjectURL(files?.[0].preview);
          }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Box position="relative">
      <Stack
        direction="row"
        alignItems="flex-end"
        gap={1}
        position="absolute"
        top={-80}
        left={16}
        sx={{
          cursor: "pointer",
        }}
      >
        <aside>{thumbs}</aside>

        <Stack
          direction="row"
          alignItems="center"
          bgcolor="#4957E9"
          width="max-content"
          borderRadius="8px"
          px={1}
          py={0.5}
          gap={1}
          mb={0.5}
          color="white"
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <CameraAltRoundedIcon
            sx={{
              width: "17px",
              height: "17px",
            }}
          />
          <Typography fontSize="14px">Add</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
