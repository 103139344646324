import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../Button";
import ProfilePhoto from "../../ProfilePhoto";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../../store/userReducer";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  useDeleteFollow,
  useCreateFollowRequest,
  useDeclineFollowRequest,
  useAcceptFollowReq,
} from "../../../services/follow";
import styles from "../style.module.scss";
import CancelFollowModal from "../../CancelFollowModal";
import { useImage } from "../../../services/getImages";

interface IUser {
  follower: {
    id: string;
    avatar: string;
    banner?: string;
    user_id: string;
    username: string;
    user_avatar: string;
    follow_status?: number;
  };
  isRequests?: boolean;
  isDisabled?: boolean;
  isFollowing?: boolean;
  isOtherUser?: boolean;
  closeModal: () => void;
  onFollow?: (id: string) => void;
  cancelReq?: (id: string) => void;
  onUnfollow?: (id: string) => void;
  setIsDisabled: (val: boolean) => void;
  acceptDeclineReq?: (id: string) => void;
  onRemoveFollower?: (id: string) => void;
  addFollower?: (follower: {
    id: string;
    user_id: string;
    username: string;
    user_avatar: string;
    follow_status?: number;
  }) => void;
}

const User: React.FC<IUser> = ({
  follower,
  onFollow,
  cancelReq,
  onUnfollow,
  isRequests,
  isDisabled,
  closeModal,
  isFollowing,
  isOtherUser,
  addFollower,
  setIsDisabled,
  acceptDeclineReq,
  onRemoveFollower,
}) => {
  const dispatch = useDispatch();
  const unfollow = useDeleteFollow();
  const acceptReq = useAcceptFollowReq();
  const follow = useCreateFollowRequest();
  const [open, setOpen] = useState(false);
  const declineReq = useDeclineFollowRequest();
  const [modalType, setModalType] = useState("");
  const [followStatus, setFollowStatus] = useState(2);
  const user = useSelector((state: any) => state.user);

  const { data: userAvatar } = useImage(
    follower?.user_avatar || follower?.avatar,
  );

  useEffect(() => {
    if (follower?.follow_status || follower?.follow_status === 0) {
      setFollowStatus(follower?.follow_status);
    }
  }, [follower]);

  const handleFollow = () => {
    setIsDisabled(true);
    follow.mutate(
      { following_id: follower?.user_id || follower?.id },
      {
        onSuccess: (res: any) => {
          setIsDisabled(false);
          setFollowStatus(res?.data?.follow_status);

          if (res?.data?.follow_status === 2) {
            dispatch(
              updateUserAction({
                ...user,
                following: user.following + 1,
              }),
            );
            if (onFollow) {
              onFollow(follower?.id);
            }
          }
        },
        onError: () => {
          setIsDisabled(false);
        },
      },
    );
  };

  const handleAcceptReq = () => {
    setIsDisabled(true);
    acceptReq.mutate(
      { id: follower?.id },
      {
        onSuccess: (res: any) => {
          setIsDisabled(false);
          if (addFollower) {
            addFollower(res.data);
            if (acceptDeclineReq) {
              acceptDeclineReq(follower?.id);
            }
            dispatch(
              updateUserAction({
                ...user,
                followers: user.followers + 1,
                follow_requests:
                  user?.follow_requests > 0 ? user?.follow_requests - 1 : 0,
              }),
            );
          }
        },
        onError: () => {
          setIsDisabled(false);
        },
      },
    );
  };

  const handleDeclineReq = () => {
    setIsDisabled(true);
    declineReq.mutate(
      { id: follower?.id },
      {
        onSuccess: () => {
          setIsDisabled(false);
          if (acceptDeclineReq) {
            acceptDeclineReq(follower?.id);
          }
          dispatch(
            updateUserAction({
              ...user,
              follow_requests:
                user?.follow_requests > 0 ? user?.follow_requests - 1 : 0,
            }),
          );
        },
        onError: () => {
          setIsDisabled(false);
        },
      },
    );
  };

  const handleCancelRequest = () => {
    setIsDisabled(true);
    declineReq.mutate(
      { current_user_id: user?.id, user_id: follower?.user_id || follower?.id },
      {
        onSuccess: () => {
          if (cancelReq) {
            cancelReq(follower?.id);
          }
          setIsDisabled(false);
          setOpen(false);
        },
        onError: () => {
          setIsDisabled(false);
        },
      },
    );
  };

  const handleRemoveFollower = () => {
    setIsDisabled(true);
    if (follower?.banner) {
      unfollow.mutate(
        {
          user_id: follower?.id || follower?.user_id,
          is_remove_follower: true,
        },
        {
          onSuccess: () => {
            if (onRemoveFollower) {
              onRemoveFollower(follower?.id);
              dispatch(
                updateUserAction({
                  ...user,
                  followers: user.followers > 0 ? user.followers - 1 : 0,
                }),
              );
            }
            setIsDisabled(false);
            setOpen(false);
          },
          onError: () => {
            setIsDisabled(false);
          },
        },
      );
    } else {
      unfollow.mutate(
        { id: follower?.id, is_remove_follower: true },
        {
          onSuccess: () => {
            if (onRemoveFollower) {
              onRemoveFollower(follower?.id);
              dispatch(
                updateUserAction({
                  ...user,
                  followers: user.followers > 0 ? user.followers - 1 : 0,
                }),
              );
            }
            setIsDisabled(false);
            setOpen(false);
          },
          onError: () => {
            setIsDisabled(false);
          },
        },
      );
    }
  };

  const handleUnfollow = () => {
    setIsDisabled(true);
    unfollow.mutate(
      { user_id: follower?.user_id || follower?.id },
      {
        onSuccess: () => {
          if (onUnfollow) {
            onUnfollow(follower?.id);
            dispatch(
              updateUserAction({
                ...user,
                following: user.following > 0 ? user.following - 1 : 0,
              }),
            );
            setFollowStatus(0);
          }
          setIsDisabled(false);
          setOpen(false);
        },
        onError: () => {
          setIsDisabled(false);
        },
      },
    );
  };

  return (
    <li key={follower?.id} className={styles.follow_wrapper}>
      <CancelFollowModal
        open={open}
        close={() => setOpen(false)}
        modalType={modalType}
        handleConfirm={
          modalType === "cancel_req"
            ? handleCancelRequest
            : modalType === "unfollow"
              ? handleUnfollow
              : handleRemoveFollower
        }
        user={{
          id: follower?.user_id,
          username: follower?.username,
          avatar: follower?.user_avatar || follower?.avatar,
        }}
      />

      <div className={styles.follow_info_wrapper}>
        <Link to={`/app/user/${follower?.user_id}/posts`} onClick={closeModal}>
          <ProfilePhoto
            rounded={false}
            size="40px"
            img={follower?.user_avatar || follower?.avatar ? userAvatar : ""}
          />
        </Link>

        <Link to={`/app/user/${follower?.user_id}/posts`} onClick={closeModal}>
          <p className={styles.profile_name}>{follower?.username}</p>
        </Link>
      </div>

      <div className={styles.request_btns_wrapper}>
        {!isOtherUser ? (
          <>
            {isRequests ? (
              <>
                <Button
                  text="Accept"
                  disabled={isDisabled}
                  onClick={handleAcceptReq}
                  icon={<DoneRoundedIcon />}
                  className={`${styles.follow_btn} ${styles.accept_btn}`}
                />
                <Button
                  text="Decline"
                  disabled={isDisabled}
                  onClick={handleDeclineReq}
                  icon={<CloseRoundedIcon />}
                  className={`${styles.follow_btn} ${styles.decline_btn}`}
                />
              </>
            ) : !isRequests && followStatus === 0 && !isFollowing ? (
              <Button
                text="Follow"
                disabled={isDisabled}
                onClick={handleFollow}
                className={`${styles.follow_btn} ${styles.follower_btn}`}
              />
            ) : !isRequests && followStatus === 1 && !isFollowing ? (
              <Button
                text="Requested"
                disabled={isDisabled}
                onClick={() => {
                  setModalType("cancel_req");
                  setOpen(true);
                }}
                className={`${styles.follow_btn} ${styles.request_btn}`}
              />
            ) : (
              <Button
                text="Following"
                disabled={isDisabled}
                onClick={() => {
                  setModalType("unfollow");
                  setOpen(true);
                }}
                className={`${styles.follow_btn} ${styles.following_btn}`}
              />
            )}
            {!isRequests && !isFollowing && (
              <Button
                text="Remove"
                disabled={isDisabled}
                onClick={() => {
                  setModalType("remove_follower");
                  setOpen(true);
                }}
                className={`${styles.follow_btn} ${styles.remove_btn}`}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </li>
  );
};

export default User;
