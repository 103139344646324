import { Box, Tooltip, Typography } from "@mui/material";

interface CustomTooltipProps {
  content: string;
  icon: React.ReactNode;
}

export default function CustomTooltip({ content, icon }: CustomTooltipProps) {
  if (!content) return null;
  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          {content}
        </Typography>
      }
      sx={{
        "& .MuiTooltip-popper": {
          backgroundColor: "#000 !important",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        {icon}
      </Box>
    </Tooltip>
  );
}
