import { useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import styles from "./style.module.scss";
import { PieChart } from "react-minimal-pie-chart";
import { useParams } from "react-router-dom";
import { useGetUserStatsMutation } from "../../../../../services/userStats";
import { useSelector } from "react-redux";
import { useImage } from "../../../../../services/getImages";

interface IWeapons {
  weaponsAll: (string | number)[][];
}

const Weapons: React.FC<IWeapons> = ({ weaponsAll }) => {
  const { id } = useParams();
  const getStats = useGetUserStatsMutation();
  const [isError, setIsError] = useState(false);
  const [isWeekly, setIsWeekly] = useState(false);
  const user = useSelector((state: any) => state.user);
  const [weapons, setWeapons] = useState<any>(null);

  useEffect(() => {
    if (id && user.id && isWeekly) {
      getStats.mutate(
        {
          game_id: id,
          user_id: user.id,
          type: "weekly-stats",
        },
        {
          onSuccess: (res: any) => {
            setWeapons(
              res.data.data.top_weapons.map((e: any) => Object.values(e)),
            );
          },
          onError: () => {
            setIsError(true);
          },
        },
      );
    }
  }, [id, user.id, isWeekly]);

  useEffect(() => {
    if (!isWeekly) {
      setIsError(false);
      setWeapons(weaponsAll);
    }
  }, [weaponsAll, isWeekly]);

  return (
    <div className={styles.weapons_wrapper}>
      <div className={styles.weapons_header_wrapper}>
        <Button
          text="Lifetime"
          onClick={() => setIsWeekly(false)}
          className={`${styles.header_button} ${!isWeekly && styles.active}`}
        />
        <Button
          text="Weekly"
          onClick={() => setIsWeekly(true)}
          className={`${styles.header_button} ${isWeekly && styles.active}`}
        />
      </div>

      <div className={styles.weapons_body_wrapper}>
        {isError ? (
          <p className={styles.error_text}>
            Your weekly statistics are currently unavailable. Weekly statistics
            are updated every Sunday & begin tracking on the first Monday
            following your sign-up date.
          </p>
        ) : (
          <div className={styles.cs2_table_wrapper}>
            <div className={styles.table_headings_wrapper}>
              {["Weapon", "Kills", "Shots Fired", "Shots Hit", "Accuracy"].map(
                (heading: string) => (
                  <p key={heading} className={styles.table_heading}>
                    {heading}
                  </p>
                ),
              )}
            </div>

            <div className={styles.table_body_wrapper}>
              {weapons &&
                weapons?.map((row: any) => {
                  return <WeaponItem row={row} key={row[0]} />;
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const WeaponItem = ({ row }: any) => {
  const percentage = typeof row[4] === "number" ? row[4] : 0;
  const { data: gunPhoto } = useImage(
    row[0].toString().toLowerCase().replaceAll(/\s+/g, "_").concat(".png"),
  );
  return (
    <div className={styles.table_row}>
      <div className={styles.table_cell}>
        <div className={styles.gun_image_wrapper}>
          <img
            loading="lazy"
            alt="Gun Photo"
            className={styles.gun_image}
            src={gunPhoto}
          />
        </div>
        {row[0]}
      </div>

      <div className={styles.table_cell}>{row[1] || 0}</div>

      <div className={styles.table_cell}>{row[2] || 0}</div>

      <div className={styles.table_cell}>{row[3] || 0}</div>

      <div className={styles.table_cell}>
        <div className={styles.pie_chart_wrapper}>
          <PieChart
            data={[
              {
                title: "One",
                value: percentage,
                color: "#4655f4",
              },
              {
                title: "Two",
                value: 100 - percentage,
                color: "#1d1d23",
              },
            ]}
            startAngle={270}
          />
        </div>
        {Math.round(percentage)}%
      </div>
    </div>
  );
};

export default Weapons;
