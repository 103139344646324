import React, { useEffect, useState } from "react";
import defaultImage from "../../assets/images/default-banner.png";
import styles from "./style.module.scss";
import {
  extractAndRemoveImgTag,
  extractAndCleanImageURL,
  replaceCustomTags,
} from "../../helpers/newsTextCorrecter";
import { time2TimeAgo } from "../../helpers/formatDate";
import { newsGames } from "../../helpers/demo";
import { useImage } from "../../services/getImages";

interface NewsProps {
  news: {
    url: string;
    date: number;
    title: string;
    author: string;
    app_id: number;
    contents: string;
    feedname: string;
    feedlabel: string;
    is_external_url: boolean;
  };
  isTopNews?: boolean;
}

const News: React.FC<NewsProps> = ({ news, isTopNews }) => {
  const [text, setText] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const { data: gameImage } = useImage(String(news?.app_id), true);

  useEffect(() => {
    const parseNewsContent = () => {
      const contents = news?.contents || "";

      if (/\{STEAM_CLAN_(LOC_IMAGE|IMAGE)\}/.test(contents)) {
        const { imageUrl, cleanedText } = extractAndCleanImageURL(contents);

        setImage(imageUrl || (gameImage ?? defaultImage));
        setText(replaceCustomTags(cleanedText));
        return;
      }

      if (/\[url=.*?\]\[img\].+?\[\/img\]\[\/url\]/.test(contents)) {
        const matchedImg = contents.match(
          /\[url=(.+?)\]\[img\](.+?)\[\/img\]\[\/url\]/,
        );
        if (matchedImg) {
          const [, url, imgSrc] = matchedImg;

          setImage(imgSrc);
          setText(
            replaceCustomTags(
              contents.replaceAll(
                matchedImg[0],
                `<a href="${url}" target="_blank"><img src="${imgSrc}" alt="News image" /></a>`,
              ),
            ),
          );
          return;
        }
      }

      if (contents.includes("<img")) {
        const { srcValue, pContent } = extractAndRemoveImgTag(contents);
        setImage(srcValue || (gameImage ?? defaultImage));
        setText(replaceCustomTags(pContent));
        return;
      }

      setImage(gameImage ?? defaultImage);
      const truncatedText = contents.split(" ").slice(0, 30).join(" ");
      setText(replaceCustomTags(truncatedText));
    };

    parseNewsContent();
  }, [news, gameImage]);

  const gameTitle =
    newsGames.find((game) => game.id === news.app_id)?.title || "";

  return (
    <div className={`${styles.news} ${isTopNews ? styles.top_news : ""}`}>
      <div
        className={`${styles.news_image_wrapper} ${
          isTopNews ? styles.top_news_image_wrapper : ""
        }`}
      >
        <a href={news.url} target="_blank" rel="noopener noreferrer">
          <img
            loading="lazy"
            alt="News123"
            className={styles.news_image}
            src={
              image === undefined && gameImage === undefined
                ? defaultImage
                : image
            }
          />
        </a>
        {gameTitle && <p className={styles.game_title}>{gameTitle}</p>}
      </div>

      <div className={styles.news_content_wrapper}>
        <p className={styles.secondary_text}>{time2TimeAgo(news.date)}</p>
        <a
          href={news.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.primary_text}
        >
          {news.title}
        </a>
        <p
          className={styles.news_content_text}
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
        <a
          href={news.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.news_link}
        >
          Read more
        </a>
      </div>
    </div>
  );
};

export default News;
