const GET_USER = "GET_USER";
const UPDATE_USER = "UPDATE_USER";
// const CREATE_USER = "CREATE_USER";
// const DELETE_USER = "DELETE_USER";

export interface IUser {
  active: 0;
  id: string;
  name: string;
  email: string;
  avatar: string;
  banner: string;
  password: string;
  steam_id: string;
  username: string;
  followers: number;
  following: number;
  created_at: string;
  updated_at: string;
  is_private: boolean;
  follow_status: number;
  follow_requests: number;
  connected_platforms: any;
}

const defaultState = {
  active: 0,
  id: "",
  name: "",
  email: "",
  avatar: "",
  banner: "",
  password: "",
  steam_id: "",
  followers: 0,
  following: 0,
  username: "",
  created_at: "",
  updated_at: "",
  follow_status: 0,
  is_private: false,
  follow_requests: 0,
  connected_platforms: {},
};

export function user(state = defaultState, action: any) {
  const data = action.payload;

  switch (action.type) {
    case GET_USER:
      return data || {};
    case UPDATE_USER:
      return { ...data };
    default:
      return state;
  }
}

export const getUserAction = (payload: IUser) => ({
  type: GET_USER,
  payload,
});

export const updateUserAction = (payload: IUser) => ({
  type: UPDATE_USER,
  payload,
});
