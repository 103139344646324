import { Stack, Typography } from "@mui/material";

import Button from "../../../components/Button";
import CustomModal from "../../../components/Modal/CustomModal";
import CustomTextArea from "../../../components/Textarea/CustomTextArea";
import { TeamContext } from "../../../contexts/TeamContext";
import { useContext } from "react";

type JoinTeamProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleJoinTeam: () => void;
};

export default function JoinTeam({
  isOpen,
  setIsOpen,
  handleJoinTeam,
}: JoinTeamProps) {
  const { joinTeamText, setJoinTeamText } = useContext(TeamContext);

  return (
    <CustomModal
      title="Request to join"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setJoinTeamText && setJoinTeamText("");
      }}
      height="max-content"
    >
      <Stack direction="column" px={3} py={2} gap={1.5} width="100%">
        <CustomTextArea
          text={joinTeamText}
          placeholder="Tell the team why you want to join (optional)"
          rows={4}
          bgColor="#1D1D23"
          setText={(val) => {
            setJoinTeamText && setJoinTeamText(val);
          }}
        />
        <Typography color="#5B5B5B" fontSize="14px">
          Your request will be reviewed by team moderators!
        </Typography>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          pt={2}
        >
          <Button
            text="Cancel"
            isCancelButton
            onClick={() => {
              setIsOpen(false);
              setJoinTeamText && setJoinTeamText("");
            }}
          />
          <Button text="Request" onClick={handleJoinTeam} />
        </Stack>
      </Stack>
    </CustomModal>
  );
}
