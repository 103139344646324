import React from "react";
import { IAuthBlock } from "../types";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

const AuthBlock: React.FC<IAuthBlock> = ({
  isSignIn,
  isInfo,
  children,
  title,
}) => {
  return (
    <div className={styles.auth_block}>
      <div className={styles.block}>
        <div className={styles.block_title_wrapper}>
          <h1 className={styles.block_title}>{title}</h1>

          {isInfo && (
            <p className={styles.auth_option_text}>
              Create your username and password
            </p>
          )}
        </div>

        {children}
      </div>

      <div className={styles.block}>
        {isSignIn ? (
          <pre className={styles.auth_option_text}>
            Don’t have an account?{"   "}
            <Link to="/auth/sign-up">
              <span className={styles.auth_option_link}>Create account</span>
            </Link>
          </pre>
        ) : (
          <pre className={styles.auth_option_text}>
            Already have an account?{"   "}
            <Link to="/auth/sign-in">
              <span className={styles.auth_option_link}>Log In</span>
            </Link>
          </pre>
        )}
      </div>
    </div>
  );
};

export default AuthBlock;
