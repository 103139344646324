const UPDATE_OTHER_USER = "UPDATE_OTHER_USER";

export interface IUser {
  id: string;
  name: string;
  email: string;
  active: number;
  avatar: string;
  banner: string;
  riot_id: string;
  steam_id: string;
  username: string;
  followers: number;
  following: number;
  updated_at: string;
  created_at: string;
  is_online: boolean;
  is_private: boolean;
  follow_status: number;
  follow_requests: number;
  is_riot_online: boolean;
  is_steam_online: boolean;
  connected_platforms: any;
}

const defaultState = {
  id: "",
  name: "",
  email: "",
  active: 0,
  avatar: "",
  banner: "",
  riot_id: "",
  steam_id: "",
  username: "",
  followers: 0,
  following: 0,
  updated_at: "",
  created_at: "",
  follow_status: 0,
  is_online: false,
  is_private: false,
  follow_requests: 0,
  is_riot_online: false,
  is_steam_online: false,
  connected_platforms: {},
};

export function otherUser(state = defaultState, action: any) {
  const data = action.payload;

  switch (action.type) {
    case UPDATE_OTHER_USER:
      return { ...data };
    default:
      return state;
  }
}

export const updateOtherUserAction = (payload: IUser) => ({
  type: UPDATE_OTHER_USER,
  payload,
});
