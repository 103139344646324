export const News = ({ className }: { className: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="mask0_2130_4031"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2130_4031)">
      <path d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM7 17H17C17.2833 17 17.5208 16.9042 17.7125 16.7125C17.9042 16.5208 18 16.2833 18 16C18 15.7167 17.9042 15.4792 17.7125 15.2875C17.5208 15.0958 17.2833 15 17 15H7C6.71667 15 6.47917 15.0958 6.2875 15.2875C6.09583 15.4792 6 15.7167 6 16C6 16.2833 6.09583 16.5208 6.2875 16.7125C6.47917 16.9042 6.71667 17 7 17ZM7 13H9C9.28333 13 9.52083 12.9042 9.7125 12.7125C9.90417 12.5208 10 12.2833 10 12V8C10 7.71667 9.90417 7.47917 9.7125 7.2875C9.52083 7.09583 9.28333 7 9 7H7C6.71667 7 6.47917 7.09583 6.2875 7.2875C6.09583 7.47917 6 7.71667 6 8V12C6 12.2833 6.09583 12.5208 6.2875 12.7125C6.47917 12.9042 6.71667 13 7 13ZM13 13H17C17.2833 13 17.5208 12.9042 17.7125 12.7125C17.9042 12.5208 18 12.2833 18 12C18 11.7167 17.9042 11.4792 17.7125 11.2875C17.5208 11.0958 17.2833 11 17 11H13C12.7167 11 12.4792 11.0958 12.2875 11.2875C12.0958 11.4792 12 11.7167 12 12C12 12.2833 12.0958 12.5208 12.2875 12.7125C12.4792 12.9042 12.7167 13 13 13ZM13 9H17C17.2833 9 17.5208 8.90417 17.7125 8.7125C17.9042 8.52083 18 8.28333 18 8C18 7.71667 17.9042 7.47917 17.7125 7.2875C17.5208 7.09583 17.2833 7 17 7H13C12.7167 7 12.4792 7.09583 12.2875 7.2875C12.0958 7.47917 12 7.71667 12 8C12 8.28333 12.0958 8.52083 12.2875 8.7125C12.4792 8.90417 12.7167 9 13 9Z" />
    </g>
  </svg>
);
