import { Home } from "../../../assets/icons/Home";
import { News } from "../../../assets/icons/News";
import { Joystick } from "../../../assets/icons/Joystick";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";

export const navItems = [
  {
    id: 1,
    icon: (className: string) => <Home className={className} />,
    text: "Dashboard",
  },
  {
    id: 2,
    icon: (className: string) => <Joystick className={className} />,
    text: "Games",
  },
  {
    id: 3,
    icon: (className: string) => <GroupsRoundedIcon className={className} />,
    text: "Teams",
  },
  {
    id: 4,
    icon: (className: string) => (
      <LeaderboardRoundedIcon className={className} />
    ),
    text: "Statistics",
  },
  {
    id: 5,
    icon: (className: string) => <News className={className} />,
    text: "News",
  },
];
