import { Stack, Typography } from "@mui/material";

type propTypes = {
  rowNumber: number;
  img: string;
  name: string;
  totalTime?: number;
  lastTwoWeeks?: number;
};

export default function GameTableRow({
  rowNumber,
  img,
  name,
  totalTime,
  lastTwoWeeks,
}: propTypes) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      p={1}
      sx={{ "&:hover": { backgroundColor: "#1D1D23" }, cursor: "pointer" }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography color="grey">{rowNumber}</Typography>
        <img loading="lazy" src={img} alt={name} width={46} height={46} />
        <Typography color="white">{name}</Typography>
      </Stack>
      {totalTime && lastTwoWeeks ? (
        <Stack direction="column">
          <Typography color="grey">
            Total: {totalTime > 0 ? totalTime.toString().slice(0, 4) : "-"}{" "}
            {totalTime > 0 ? "Hours" : ""}
          </Typography>
          <Typography color="grey">
            Last 2 Weeks:{" "}
            {lastTwoWeeks ? lastTwoWeeks.toString().slice(0, 4) : "-"}{" "}
            {lastTwoWeeks > 0 ? "Hours" : ""}
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
}
