const UPDATE_AUTH = "UPDATE_AUTH";

const defaultState = {
	access_token: "",
	refresh_token: "",
};

export function auth(state = defaultState, action: any) {
	switch (action.type) {
		case UPDATE_AUTH:
			return action.payload;
		default:
			return state;
	}
}

export const updateAuthAction = (payload: {
	access_token: string;
	refresh_token: string;
}) => ({ type: UPDATE_AUTH, payload });
