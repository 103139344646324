import { useDispatch, useSelector } from "react-redux";

import Post from "../../../../components/Post";
import { getAllPostsAction } from "../../../../store/postReducer";
import { getAllUsersPostsQuery } from "../../../../services/post";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/Loader";

const Home = () => {
  const dispatch = useDispatch();
  const [postsCount, setPostsCount] = useState(0);
  const user = useSelector((state: any) => state.user);
  const posts = useSelector((state: any) => state.post);
  const {
    data: allPosts,
    isLoading,
    refetch,
    fetchNextPage: fetchNextPosts,
  } = getAllUsersPostsQuery({ user_id: user.id, limit: 10 });

  useEffect(() => {
    if (user.id) {
      refetch();
    }
  }, [user.id]);

  useEffect(() => {
    dispatch(
      getAllPostsAction(
        allPosts?.pages?.flatMap((page: any) => {
          setPostsCount(page?.data?.count);
          return page?.data?.posts;
        }) || [],
      ),
    );
  }, [allPosts]);

  return (
    <div className={styles.home_wrapper}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader text="Loading My Posts..." />
        </div>
      ) : (
        <>
          <InfiniteScroll
            loader={<></>}
            endMessage={<></>}
            next={fetchNextPosts}
            dataLength={posts?.length}
            hasMore={postsCount > posts.length}
          >
            {posts?.length && posts[0] ? (
              posts?.map((post: any) => {
                return (
                  <Post post={post} key={post?.id} refetchPostsFn={refetch} />
                );
              })
            ) : (
              <p className={styles.no_posts}>There are no posts yet</p>
            )}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default Home;
