import { Stack, Typography } from "@mui/material";
import { useContext, useMemo } from "react";

import Post from "../../../components/Post";
import { TeamContext } from "../../../contexts/TeamContext";
import usePermission from "../../../hooks/usePermission";

interface teamOverviewTabProps {
  teamPosts: any;
}

export default function TeamOverviewTab({ teamPosts }: teamOverviewTabProps) {
  const { refetchPosts } = useContext(TeamContext);
  const { checkPermission } = usePermission();

  const canDeletePost = useMemo(() => {
    return checkPermission("team/post/delete");
  }, [checkPermission]);

  return (
    <Stack width="100%" direction="column" gap={1} alignItems="center">
      {(teamPosts?.data?.count <= 0 || !teamPosts) && (
        <Stack
          width="100%"
          direction="column"
          gap={1}
          alignItems="center"
          py={10}
        >
          <Typography color="#EFEFEF" fontWeight={700}>
            No Team Posts
          </Typography>
          <Typography color="#8C8D94" fontSize="14px">
            Posts for this team will appear here after they are published.
          </Typography>
        </Stack>
      )}

      {teamPosts?.data?.posts?.map((post: any) => {
        return (
          <Post
            post={post}
            key={post?.id}
            refetchPostsFn={refetchPosts}
            isOwner={canDeletePost}
          />
        );
      })}
    </Stack>
  );
}
