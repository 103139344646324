const UPDATE_VISIBILITY = "UPDATE_VISIBILITY";

export interface IUser {
  is_private: boolean;
}

const defaultState = {
  is_private: true,
};

export function visibility(state = defaultState, action: any) {
  switch (action.type) {
    case UPDATE_VISIBILITY:
      return { is_private: action.payload };
    default:
      return state;
  }
}

export const updateVisibilityAction = (payload: IUser) => ({
  type: UPDATE_VISIBILITY,
  payload,
});
