import React from "react";
import { Link } from "react-router-dom";
import ProfilePhoto from "../../ProfilePhoto";
import styles from "../style.module.scss";
import { useImage } from "../../../services/getImages";

interface ITeam {
  team: {
    id: string;
    name: string;
    logo: string;
    game_id: string;
    game_logo: string;
    members_count: number;
  };
  closeModal: () => void;
}

const Team: React.FC<ITeam> = ({ team, closeModal }) => {
  const { data: teamLogo } = useImage(team?.logo);
  const { data: teamGame_logo } = useImage(team?.game_logo);

  return (
    <li key={team?.id} className={styles.follow_wrapper}>
      <div className={styles.team_wrapper}>
        <Link to={`/app/teams/${team?.id}`} onClick={closeModal}>
          <ProfilePhoto
            rounded={false}
            size="40px"
            img={team?.logo ? teamLogo : ""}
          />
        </Link>

        <div className={styles.team_info_wrapper}>
          <Link to={`/app/teams/${team?.id}`} onClick={closeModal}>
            <p className={styles.profile_name}>{team?.name}</p>
          </Link>
          <p className={styles.members_count}>
            {team?.members_count} Player{team?.members_count !== 1 && "s"}
          </p>
        </div>
      </div>

      <div className={styles.game_logo_wrapper}>
        <img
          loading="lazy"
          alt="Game Logo"
          className={styles.game_logo}
          src={teamGame_logo}
        />
      </div>
    </li>
  );
};

export default Team;
