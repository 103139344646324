import { IOption } from "../types";
import React from "react";
import styles from "./style.module.scss";
import { useImage } from "../../services/getImages";

const Option: React.FC<IOption> = ({ id, handleSelect, img, text }) => {
  const { data: imgST } = useImage(img || "");
  return (
    <li
      accessKey={id}
      className={styles.menu_item}
      onClick={() => handleSelect({ id, img, text })}
    >
      {img && (
        <img
          loading="lazy"
          alt="game"
          src={img ? imgST : ""}
          className={styles.item_img}
        />
      )}
      <p className={styles.item_text}>{text}</p>
    </li>
  );
};

export default Option;
