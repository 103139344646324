import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import BannerImg from "../Banner/BannerImg";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { setBinaryImage } from "../../helpers/setBinaryImage";
import { useDropzone } from "react-dropzone";

type BannerUploadProps = {
  setBannerId: (bannerId: string) => void;
  defaultBanner?: string;
};

export default function BannerUpload({
  defaultBanner,
  setBannerId,
}: BannerUploadProps) {
  const [files, setFiles] = useState([{ name: "default", preview: "" }]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      setFiles([
        {
          ...acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        },
      ]);

      const id = await setBinaryImage(acceptedFiles[0]);
      if (id) {
        setBannerId(id);
      }
    },
  });

  const thumbs = (
    <div key={files?.[0].name}>
      <div>
        <BannerImg
          defaultBanner={defaultBanner}
          imgFile={files?.[0].preview}
          onLoad={() => {
            URL.revokeObjectURL(files?.[0].preview);
          }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Box position="relative">
      <Stack
        position="absolute"
        top={10}
        left={16}
        direction="row"
        alignItems="center"
        bgcolor="#4957E9"
        width="max-content"
        borderRadius="8px"
        px={1}
        py={0.5}
        gap={1}
        color="white"
        sx={{
          cursor: "pointer",
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <CameraAltRoundedIcon
          sx={{
            width: "17px",
            height: "17px",
          }}
        />
        <Typography fontSize="14px">Add</Typography>
      </Stack>
      <aside>{thumbs}</aside>
    </Box>
  );
}
