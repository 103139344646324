import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Header from "./Header";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Sidebar from "./Sidebar";
import { Toaster } from "react-hot-toast";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";

const Layout = () => {
  const navigate = useNavigate();
  const showSidebar = useSelector((state: any) => state.showSidebar);

  useEffect(() => {
    window.scroll(0, 0);
  }, [window.location.pathname]);

  // ----------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------------------------------------------------

  const logout = () => {
    localStorage.setItem("auth", "{}");
    navigate("/auth/sign-in");
  };

  return (
    <div className={styles.layout}>
      <Toaster position="top-right" />
      <Sidebar />

      <div
        className={`${styles.header_wrapper} ${
          !showSidebar && styles.header_full
        }`}
      >
        {!showSidebar && <div className={styles.header_button}></div>}

        <Header />

        {!showSidebar && (
          <>
            <button className={styles.header_button} onClick={handleClick}>
              <SettingsRoundedIcon />
            </button>

            <Menu
              open={open}
              sx={{
                "&": {
                  position: "absolute",
                },
                ".MuiPaper-root": {
                  marginTop: "5px",
                  width: "142px",
                  borderRadius: "12px",
                  backgroundColor: "#1D1D23",
                },
                ".MuiList-root": {
                  height: "fit-content",
                  padding: 0,
                },
                ".MuiButtonBase-root": {
                  padding: 0,
                  color: "#efefef",
                },
              }}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  to="/app/profile-edit"
                  style={{
                    width: "100%",
                    padding: "16px 8px 16px 20px",
                  }}
                >
                  Edit Profile
                </Link>
              </MenuItem>
              <hr className={styles.horizontal_line} />
              <MenuItem onClick={logout}>
                <span
                  style={{
                    width: "100%",
                    padding: "16px 8px 16px 20px",
                  }}
                >
                  Log Out
                </span>
              </MenuItem>
            </Menu>
          </>
        )}
      </div>

      <div className={`${styles.outlet} ${!showSidebar && styles.outlet_full}`}>
        <Outlet />
      </div>

      {showSidebar && <aside className={styles.right_sidebar}></aside>}
    </div>
  );
};

export default Layout;
