import React from "react";
import { Defuse } from "../../../../../../assets/icons/Defuse";
import styles from "./style.module.scss";
import { Bomb } from "../../../../../../assets/icons/Bomb";

interface IColumn {
  topHeading: string;
  withIcon?: boolean;
  bottomHeading: string;
  topStats: string | number;
  bottomStats: string | number;
  topPercentage?: string | number;
  bottomPercentage?: string | number;
}

const Column: React.FC<IColumn> = ({
  topStats,
  withIcon,
  topHeading,
  bottomStats,
  topPercentage,
  bottomHeading,
  bottomPercentage,
}) => {
  return (
    <div className={styles.column}>
      <div className={styles.top_wrapper}>
        <div className={styles.column_inner}>
          <p className={styles.secondary_text}>{topHeading}</p>
          <p className={styles.primary_text}>
            {topStats}{" "}
            {topPercentage ? (
              <span className={styles.percentage_text}>({topPercentage}%)</span>
            ) : (
              <></>
            )}
          </p>
        </div>
        {withIcon && <Bomb />}
      </div>

      <div className={styles.bottom_wrapper}>
        <div className={styles.column_inner}>
          <p className={styles.secondary_text}>{bottomHeading}</p>
          <p className={styles.primary_text}>
            {bottomStats}{" "}
            {bottomPercentage ? (
              <span className={styles.percentage_text}>
                ({bottomPercentage}%)
              </span>
            ) : (
              <></>
            )}
          </p>
        </div>

        {withIcon && <Defuse />}
      </div>
    </div>
  );
};

export default Column;
