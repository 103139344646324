import "./assets/scss/global.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import router from "./routes";
import store from "./store";
import { theme } from "./providers/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 1000 * 60 * 60 * 24,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider
      clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
    >
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  </QueryClientProvider>,
);
