import React, { useEffect, useState } from "react";
import { IModal } from "../types";
import { IconButton, Modal } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import styles from "./style.module.scss";

const AccountConnectModal: React.FC<IModal> = ({
  open,
  close,
  width,
  title,
  children,
  withFooter,
  handleBack,
  withBackBtn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconButtonStyle = {
    padding: 0,
    color: "white",
  };

  const handleClose = (event?: any, reason?: any) => {
    if (reason !== "backdropClick") {
      close();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal open={isOpen} onClose={handleClose} className={styles.modal}>
      <div className={styles.modal_inner_wrapper} style={{ width: width }}>
        <div className={styles.modal_header_wrapper}>
          {withBackBtn && (
            <IconButton style={iconButtonStyle} onClick={handleBack}>
              <ArrowBackRoundedIcon />
            </IconButton>
          )}

          <h2 className={styles.modal_header}>{title}</h2>

          <IconButton style={iconButtonStyle} onClick={handleClose}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        {children}

        {withFooter && (
          <div className={styles.modal_footer}>
            <p className={styles.footer_text}>
              Game Drive currently provides detailed user statistics for
              Valorant, League of Legends, CS:GO, and Dota 2. Future development
              to provide access to more games is on the horizon.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AccountConnectModal;
