import { Stack, Typography } from "@mui/material";

interface JoinStatusLabelProps {
  status: string;
  onClick?: () => void;
}

export default function JoinStatusLabel({
  status,
  onClick,
}: JoinStatusLabelProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      border="1px solid #8C8D94"
      px={2}
      py={1}
      borderRadius="8px"
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
    >
      <Typography fontSize="14px" fontWeight={500} color="#8C8D94">
        {status}
      </Typography>
    </Stack>
  );
}
