import Dropdown from "../Dropdown";
import { client } from "../../services/config";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

type propTypes = {
  selectedGame?: string;
  setSelectedGame: React.Dispatch<React.SetStateAction<string>>;
};

export default function GameCommunitySelect({ setSelectedGame }: propTypes) {
  const [gameOptions, setGameOptions] = useState([{ id: "", title: "" }]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: gameCommunities } = useQuery(
    ["all-games"],
    () => {
      return client.get("/v1/game");
    },
    {
      onSuccess: (res) => {
        const gamesList: { id: string; title: string }[] = [];

        gamesList.push({ id: "all", title: "All Games" });

        res?.data?.games?.forEach((game: any) => {
          gamesList.push({
            id: game.id,
            title: game.title,
          });
        });
        setGameOptions(gamesList);
      },
      refetchOnMount: true,
    },
  );

  return (
    <Dropdown
      width="180px"
      textCustomStyle={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "120px",
      }}
      isDarkColored
      options={gameOptions}
      label="Game Community"
      onGameSelect={(gameId: string) => {
        setSelectedGame(gameId);
      }}
    />
  );
}
