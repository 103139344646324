type CustomTextAreaProps = {
  placeholder?: string;
  rows?: number;
  text: string;
  setText: (text: string) => void;
  bgColor?: string;
  defaultValue?: string;
};

export default function CustomTextArea(props: CustomTextAreaProps) {
  return (
    <textarea
      defaultValue={props.defaultValue}
      value={props.text}
      onChange={(e) => props.setText(e.target.value)}
      placeholder={props.placeholder}
      rows={props.rows}
      style={{
        backgroundColor: props.bgColor ?? "#2C2C34",
        lineHeight: "20px",
        border: "none",
        borderRadius: "8px",
        resize: "none",
        color: "white",
        outline: "none",
        padding: "12px",
        width: "100%",
        fontSize: "14px",
        height: "200px",
      }}
    />
  );
}
