import { Stack, Typography } from "@mui/material";

import Button from "../../../components/Button";
import CustomModal from "../../../components/Modal/CustomModal";
import { TeamContext } from "../../../contexts/TeamContext";
import { useContext } from "react";

interface DeletePopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  title?: string;
  content?: string;
  permissionPath?: string;
  buttonLabel?: string;
  handleDelete?: () => void;
  helperFn?: () => void;
}

export default function DeletePopup({
  isOpen,
  setIsOpen,
  id,
  title,
  content,
  permissionPath,
  buttonLabel,
  handleDelete,
  helperFn,
}: DeletePopupProps) {
  const { deleteMemberMutate, teamData } = useContext(TeamContext);

  function handleTeamLeave() {
    deleteMemberMutate({
      teamId: teamData?.data?.id,
      id,
      path: permissionPath ?? "team/member/leave",
    });
  }

  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        helperFn && helperFn();
      }}
      title={title ?? "Leave team"}
      height="max-content"
      width="440px"
    >
      <Stack direction="column" width="100%" gap={2} px={3} py={2}>
        <Typography color="#8C8D94" textAlign="center">
          {content ??
            `Are you sure you want to leave "${teamData?.data?.name}" ?`}
        </Typography>

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          gap={1}
          pt={2}
        >
          <Button
            isCancelButton
            text="Cancel"
            onClick={() => {
              setIsOpen(false);
              helperFn && helperFn();
            }}
          />
          <Button
            text={buttonLabel ?? "Leave"}
            customStyles={{
              backgroundColor: "#E2322F",
            }}
            onClick={() => {
              setIsOpen(false);
              if (handleDelete) {
                handleDelete();
                return;
              }
              handleTeamLeave();
            }}
          />
        </Stack>
      </Stack>
    </CustomModal>
  );
}
