import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../../components/Button";
import { arePasswordsCorrect } from "../../utils/helper";
import styles from "./style.module.scss";
import { useUpdatePasswordMutation } from "../../../../services/user";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../../../store/userReducer";
import { isPasswordCorrect } from "../../../auth/utils/helper";

interface IPasswordChange {
  close: any;
  open: boolean;
  onSuccess: any;
}

const PasswordChange: React.FC<IPasswordChange> = ({
  open,
  close,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [captcha, setCaptcha] = useState("");
  const updatePassword = useUpdatePasswordMutation();
  const user = useSelector((state: any) => state.user);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState({
    newPassword: {
      text: "",
      isError: false,
    },
    confirmPassword: {
      text: "",
      isError: false,
    },
  });

  useEffect(() => {
    setBtnDisabled(
      !!captcha &&
        isPasswordCorrect(password.newPassword) &&
        isPasswordCorrect(password.confirmPassword),
    );
  }, [captcha, password.newPassword, password.confirmPassword]);

  const handleClose = () => {
    close();
    setPassword({
      newPassword: "",
      confirmPassword: "",
    });
    setFormError({
      newPassword: {
        text: "",
        isError: false,
      },
      confirmPassword: {
        text: "",
        isError: false,
      },
    });
  };

  const handleInputChange = () => {
    setFormError({
      newPassword: {
        text: "",
        isError: false,
      },
      confirmPassword: {
        text: "",
        isError: false,
      },
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (password.newPassword !== password.confirmPassword) {
      setFormError({
        newPassword: {
          text: "Passwords must be the same",
          isError: true,
        },
        confirmPassword: {
          text: "Passwords must be the same",
          isError: true,
        },
      });
      return;
    }
    if (arePasswordsCorrect(setFormError, password)) {
      setBtnDisabled(false);

      updatePassword.mutate(
        {
          id: user.id,
          password: password.newPassword,
          captcha_token: captcha ?? "",
        },
        {
          onSuccess: (res: any) => {
            dispatch(updateUserAction(res.data));
            setBtnDisabled(true);
            onSuccess();
            handleClose();
          },
        },
      );
    }
  };

  return (
    <Modal
      open={open}
      width="388px"
      close={handleClose}
      title="Change Password"
    >
      <form>
        <div className={styles.inputs_wrapper}>
          <Input
            type="password"
            className={styles.input}
            placeholder="New Password"
            value={password.newPassword}
            isError={formError.newPassword.isError}
            errorMessage={formError.newPassword.text}
            onBlur={() => arePasswordsCorrect(setFormError, password)}
            onChange={(e: any) => {
              handleInputChange();
              setPassword((prev) => ({ ...prev, newPassword: e.target.value }));
            }}
          />

          <Input
            type="password"
            className={styles.input}
            placeholder="Confirm Password"
            value={password.confirmPassword}
            isError={formError.confirmPassword.isError}
            errorMessage={formError.confirmPassword.text}
            onBlur={() => arePasswordsCorrect(setFormError, password)}
            onChange={(e: any) => {
              handleInputChange();
              setPassword((prev) => ({
                ...prev,
                confirmPassword: e.target.value,
              }));
            }}
          />

          <ReCAPTCHA
            theme="dark"
            type="image"
            onChange={(e: any) => setCaptcha(e)}
            sitekey={process.env.REACT_APP_RECAPCHA_KEY!}
          />
        </div>

        <div className={styles.button_wrapper}>
          <Button
            type="submit"
            text="Change Password"
            onClick={handleSubmit}
            disabled={!btnDisabled}
          />
        </div>
      </form>
    </Modal>
  );
};

export default PasswordChange;
