import { Undo } from "../../assets/icons/Undo";
import { Redo } from "../../assets/icons/Redo";
import { OrderedList } from "../../assets/icons/OrderedList";
import { UnorderedList } from "../../assets/icons/UnorderedList";
import styles from "./style.module.scss";

function undoChange(this: any) {
  this.quill.history.undo();
}
function redoChange(this: any) {
  this.quill.history.redo();
}

export const modules = {
  toolbar: {
    container: "#toolbar",
    link: "link",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "align",
  "list",
  "bullet",
  "indent",
];

export const QuillToolbar = () => (
  <div id="toolbar" className={styles.toolbar}>
    <span className="ql-formats">
      <button className={`ql-undo ${styles.toolbar_button}`}>
        <Undo />
      </button>
      <button className={`ql-redo ${styles.toolbar_button}`}>
        <Redo />
      </button>
    </span>
    <span className="ql-formats">
      <button className={`ql-bold ${styles.toolbar_button}`} />
      <button className={`ql-italic ${styles.toolbar_button}`} />
      <button className={`ql-underline ${styles.toolbar_button}`} />
      <button className={`ql-strike ${styles.toolbar_button}`} />
      <button className={`ql-link ${styles.toolbar_button}`} />
    </span>
    <span className="ql-formats">
      <button className={`ql-list ${styles.toolbar_button}`} value="ordered">
        <OrderedList />
      </button>
      <button className={`ql-list ${styles.toolbar_button}`} value="bullet">
        <UnorderedList />
      </button>
      <button className={`ql-indent ${styles.toolbar_button}`} value="-1" />
      <button className={`ql-indent ${styles.toolbar_button}`} value="+1" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
    </span>
  </div>
);

export default QuillToolbar;
