import { client } from "./config";
import { useQuery } from "@tanstack/react-query";

const myGamesService = {
  getMyGames: async (params: { id: string; steam_id: string }) => {
    return client.get(
      `/v1/user-games?user_id=${params?.id}&steam_id=${params?.steam_id}`,
    );
  },
};

export const useMyGamesQuery = ({
  params,
  querySettings,
}: {
  params: { id: string; steam_id: string };
  querySettings: object;
}) => {
  return useQuery(
    ["myGames"],
    () => {
      return myGamesService.getMyGames(params);
    },
    querySettings,
  );
};
