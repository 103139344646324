import { Divider, Stack, Typography } from "@mui/material";
import { useContext, useMemo } from "react";

import Button from "../../../components/Button";
import CustomTextArea from "../../../components/Textarea/CustomTextArea";
import HiringStatus from "./HiringStatus";
import Label from "../../../components/Label/Label";
import StarIcon from "../../../assets/icons/StarIcon";
import { TeamContext } from "../../../contexts/TeamContext";
import moment from "moment";
import usePermission from "../../../hooks/usePermission";

type AboutTeamBarProps = {
  name: string;
  createdDate: string;
  isDescriptionSaveDisabled?: boolean;
  setDescriptionSaveDisabled?: (value: boolean) => void;
  gameName: string;
  gameLogo: string;
};

export default function AboutTeamBar({
  name,
  createdDate,
  isDescriptionSaveDisabled,
  setDescriptionSaveDisabled,
  gameName,
  gameLogo,
}: AboutTeamBarProps) {
  const {
    teamDescription,
    setTeamDescription,
    handleDescriptionSave,
    savedTeamData,
  } = useContext(TeamContext);

  const { checkPermission } = usePermission();

  const hasPermissionToEdit = useMemo(() => {
    return checkPermission("team/edit/description");
  }, [checkPermission]);

  const canEditHiringStatus = useMemo(() => {
    return checkPermission("team/edit/hiring");
  }, [checkPermission]);

  return (
    <Stack
      direction="column"
      bgcolor="#1D1D23"
      width="100%"
      gap={2}
      borderRadius={3}
      py={2}
      px={2}
    >
      <Typography fontWeight={600} color="white">
        About {name}
      </Typography>
      {hasPermissionToEdit && (
        <Stack direction="column" gap={1}>
          <CustomTextArea
            text={teamDescription}
            defaultValue={savedTeamData?.description}
            setText={(value) => {
              setDescriptionSaveDisabled && setDescriptionSaveDisabled(false);
              setTeamDescription(value);
            }}
            placeholder="Write a description about your team"
            rows={5}
          />

          <Typography color="#5B5B5B" fontSize="12px">
            Max 400 characters
          </Typography>
          <Button
            text="Save"
            disabled={isDescriptionSaveDisabled}
            onClick={handleDescriptionSave}
            customStyles={{
              width: "100px",
              padding: "8px 20px",
              alignSelf: "flex-end",
            }}
          />
        </Stack>
      )}
      {!hasPermissionToEdit && savedTeamData?.description && (
        <Stack direction="column" gap={1}>
          <Divider
            sx={{
              backgroundColor: "#313137",
            }}
          />
          <Typography fontSize="14px" color="#8C8D94">
            {savedTeamData?.description}
          </Typography>
          <Divider
            sx={{
              backgroundColor: "#313137",
            }}
          />
        </Stack>
      )}

      <Label label={gameName} img={gameLogo} />
      <Label
        label={moment(createdDate).format("[Created on] MMM D, YYYY")}
        icon={<StarIcon />}
      />
      <HiringStatus canEdit={canEditHiringStatus} />
    </Stack>
  );
}
